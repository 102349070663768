<template>
    <div class="row">
        <template v-if="step == 0">
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <div class="basic-form" style="width:100%;">
                            <h4><strong>OS interna</strong> (o hóspede não receberá notificações) </h4>
                            <div class="form-row">
                                <div class="my-3" style="display:flex;flex-direction:row;">
                                    <div class="mx-3">
                                        <label for="semuh">Sem UH</label>
                                        <input v-model="semoucomuh" id="semuh" name="sem-ou-com-uh"
                                            type="radio" class="form-control ponteiro" value="0"
                                            style="display:inline-block;" checked="checked">
                                    </div>
                                    <div class="mx-3">
                                        <label for="comuh">Com UH</label>
                                        <input v-model="semoucomuh" id="comuh" name="sem-ou-com-uh"
                                            type="radio" class="form-control ponteiro" value="1"
                                            style="display:inline-block;">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-auto">
                                    <a v-on:click="proximo()" class="ponteiro text-white btn btn-info mt-4">Próximo</a>
                                </div>
                                <div class="form-group col-md-auto">
                                    <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </template>
        <template v-if="step == 1">
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <div class="basic-form">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>{{$t('sols.nome')}}</label>
                                    <input v-model="filtro.nome" @keyup="loadCheckins()"
                                        type="text" class="form-control" placeholder="Busque pelo nome">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('sols.uh')}}</label>
                                    <input v-model="filtro.coduh" @keyup="loadCheckins()"
                                        type="text" class="form-control" placeholder="Busque pela UH">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('sols.reserva')}}</label>
                                    <input v-model="filtro.numreserva" @keyup="loadCheckins()"
                                        type="text" class="form-control" placeholder="Busque pela reserva">
                                </div>
                            </div>
                            <div>
                                <div @click="next(null)" class='es-row-select es-title'>
                                    <div class="col-md-12">Chamado para UH sem hóspede</div>
                                </div>
                            </div>
                            <div v-for="(checkin, index) in checkins" v-bind:key="index">
                                <div @click="next(checkin)"
                                :class="{'es-row-select es-even': isEven(index), 'es-row-select es-odd': !isEven(index)}">
                                    <div class="col-md-4">{{checkin.nome}}{{checkin.email?`(${checkin.email})`:``}}</div>
                                    <div class="col-md-2">{{checkin.coduh}}</div>
                                    <div class="col-md-2">{{checkin.numreserva}}</div>
                                    <div class="col-md-2">{{formatarData(checkin.datacheckin)}}</div>
                                    <div class="col-md-2">{{formatarData(checkin.datacheckoutprev)}}</div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-auto">
                                    <a v-on:click="step--" class="ponteiro text-white btn btn-info mt-4">Voltar</a>
                                </div>
                                <div v-if="checkins&&checkins.length==0" 
                                    class="form-group col-md-auto"
                                >
                                    <a v-on:click="proximo()" class="ponteiro text-white btn btn-info mt-4">Próximo</a>
                                </div>                                
                                <div class="form-group col-md-auto">
                                    <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </template>
        <template v-if="step == 2">
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <div class="basic-form" style="width:100%;">
                            <div class="form-row" v-if="semoucomuh=='1'||interna">
                                <div v-if="solicitacaoEditada.nome||interna" class="form-group col-md-4">
                                    <label class="negrito">{{$t('sols.nome')}}</label>
                                    <input v-model="solicitacaoEditada.nome" :readonly="!interna"
                                        type="text" class="form-control" placeholder="Nome do hóspede">
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="negrito">{{$t('sols.uh')}}</label>
                                    <input v-model="solicitacaoEditada.coduh" :readonly="!interna"
                                        type="text" class="form-control" placeholder="UH do hóspede">
                                </div>
                                <div v-if="solicitacaoEditada.numreserva||interna" class="form-group col-md-4">
                                    <label class="negrito">{{$t('sols.reserva')}}</label>
                                    <input v-model="solicitacaoEditada.numreserva" :readonly="!interna"
                                        type="text" class="form-control" placeholder="Reserva do hóspede">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label class="negrito">Setor:</label>
                                    <select class="form-control" v-model="solicitacaoEditada.idhotlinesetores"
                                        @change="loadTiposSolicitacao()">
                                        <template v-if="setores && setores.length > 0">
                                            <option v-for="setor in setores" v-bind:key="setor.idhotlinesetores"
                                                :value="setor.idhotlinesetores"
                                                :selected="solicitacaoEditada.idhotlinesetores==setor.idhotlinesetores">{{setor.deschotlinesetor}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="negrito">Tipo:</label>
                                    <select class="form-control" v-model="solicitacaoEditada.idtiposolicitacao"
                                        @change="loadTipoSolicitacaoItens()">
                                        <template v-if="tipossolicitacao && tipossolicitacao.length > 0">
                                            <option v-for="tiposolicitacao in tipossolicitacao" v-bind:key="tiposolicitacao.idtiposolicitacao"
                                                :value="tiposolicitacao.idtiposolicitacao"
                                                :selected="solicitacaoEditada.idtiposolicitacao==tiposolicitacao.idtiposolicitacao">{{tiposolicitacao.titulo}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="negrito">Ítem:</label>
                                    <select class="form-control" v-model="solicitacaoEditada.idtiposolicitacaoitem">
                                        <template v-if="tiposolicitacaoItens && tiposolicitacaoItens.length > 0">
                                            <option v-for="tiposolicitacaoitem in tiposolicitacaoItens" v-bind:key="tiposolicitacaoitem.idtiposolicitacaoitem"
                                                :value="tiposolicitacaoitem.idtiposolicitacaoitem"
                                                :selected="solicitacaoEditada.idtiposolicitacaoitem==tiposolicitacaoitem.idtiposolicitacaoitem">{{tiposolicitacaoitem.titulo}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label class="negrito">{{$t('sols.solicitacao')}}</label>
                                    <input v-model="solicitacaoEditada.textohotlinesolicitacoes" 
                                        type="text" class="form-control" placeholder="Descreva sua solicitação">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label class="negrito">{{$t('sols.prioridade')}}</label>
                                    <select class="form-control" v-model="solicitacaoEditada.idprioridade">
                                        <template v-if="prioridades && prioridades.length > 0">
                                            <option v-for="prioridade in prioridades" v-bind:key="prioridade.idprioridade"
                                                :value="prioridade.idprioridade"
                                                :selected="solicitacaoEditada.idprioridade==prioridade.idprioridade">{{prioridade.descricao}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="form-group col-md-5">
                                    <label class="negrito">{{$t('sols.responsavel')}}</label>
                                    <!-- <select :disabled="!temAcesso" class="form-control" v-model="solicitacaoEditada.idresponsavel"> -->
                                    <select class="form-control" v-model="solicitacaoEditada.idresponsavel">
                                        <template v-if="responsaveis && responsaveis.length > 0">
                                            <option :value="null"
                                                :selected="solicitacaoEditada.idresponsavel==null">{{$t('global.naoatribuido')}}
                                            </option>
                                            <option v-for="responsavel in responsaveis" v-bind:key="responsavel.idusuario"
                                                :value="responsavel.idusuario"
                                                :selected="solicitacaoEditada.idresponsavel==responsavel.idusuario">{{responsavel.nome}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="negrito">{{$t('sols.localatendimento')}}</label>
                                    <!-- <select :disabled="!temAcesso" class="form-control" v-model="solicitacaoEditada.idlocalatendimento"> -->
                                    <select class="form-control" v-model="solicitacaoEditada.idlocalatendimento">
                                        <template v-if="locaisAtendimento && locaisAtendimento.length > 0">
                                            <option :value="null"
                                                :selected="solicitacaoEditada.idlocalatendimento==null">{{$t('global.naoatribuido')}}
                                            </option>
                                            <option v-for="local in locaisAtendimento" v-bind:key="local.idlocalatendimento"
                                                :value="local.idlocalatendimento"
                                                :selected="solicitacaoEditada.idlocalatendimento==local.idlocalatendimento">{{local.descricao}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-8">
                                    <div class="custom-control custom-switch pl-0">
                                        <label class="negrito pr-2 ponteiro" :for="`item-switch-${solicitacaoEditada.idhotlinesolicitacoes}`" >
                                            {{$t('sols.manutencaoexterna')}}</label>
                                        <input  type="checkbox" name="manutencaoexterna" class="ponteiro" 
                                            :id="`item-switch-${solicitacaoEditada.idhotlinesolicitacoes}`"  
                                            v-model="solicitacaoEditada.manutencaoexterna"
                                        >
                                    </div> 
                                    <select v-if="solicitacaoEditada.manutencaoexterna" class="form-control" v-model="solicitacaoEditada.fornecedor">
                                        <template v-if="fornecedores && fornecedores.length > 0">
                                            <option :value="null"
                                                :selected="solicitacaoEditada.fornecedor==null">{{$t('global.naoatribuido')}}
                                            </option>                                            
                                            <option v-for="fornecedor in fornecedores" v-bind:key="fornecedor.idpessoa"
                                                :value="fornecedor.idpessoa"
                                                :selected="solicitacaoEditada.fornecedor==fornecedor.idfornecedor">{{fornecedor.nome}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div v-if="solicitacaoEditada.manutencaoexterna" class="form-group col-md-4">
                                    <div class="custom-control pl-0">
                                        <label class="negrito" :for="`item-garantia-${solicitacaoEditada.idhotlinesolicitacoes}`" >
                                            {{$t('sols.garantia')}}</label>
                                        <input :id="`item-garantia-${solicitacaoEditada.idhotlinesolicitacoes}`" class="form-control" type="number" min="30" v-model="solicitacaoEditada.garantiaemdias">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-auto">
                                    <a v-on:click="voltar()" class="ponteiro text-white btn btn-info mt-4">Voltar</a>
                                </div>
                                <div class="form-group col-md-auto">
                                    <a v-on:click="$emit('save',solicitacaoEditada)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                                </div>
                                <div class="form-group col-md-auto">
                                    <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import moment from 'moment'
export default {
    watch: {
        // nomeFornecedor : {
        //     deep: true,
        //     handler () {
        //         this.searchPartyByName(this.nomeFornecedor)
        //     }
        // },
        //  solicitacaoEditada : {
        //     deep: true,
        //     handler () {
                
        //         if(this.solicitacaoEditada.manutencaoexterna == false){
        //             this.solicitacaoEditada.fornecedor == null
        //             this.nomeFornecedor = ''
        //             this.fornecedores = []
                    
        //         }

        //         this.loadFornecedores()
        //     }
        // }
    },
    props: {
        solicitacao: {
            type: Object,
            required: true,
        },
        prioridades: {
            type: Array,
            required: false
        },
        responsaveis: {
            type: Array,
            required: true
        },
        locaisAtendimento: {
            type: Array,
            required: true
        },
        interna: {
            type: Boolean,
            required: false,
            default: false
        },
        stepInicial: {
            type: Number,
            required: false,
            default: 0
        },
        fornecedores: {
            type: Array,
            required: true
        }
    },    
    data() {
        return {
            step: this.stepInicial,
            tipossolicitacao: [],
            tiposolicitacaoItens: [],
            setores: [],
            checkins: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            solicitacaoEditada: {},
            filtro: {
                nome: '',
                coduh: '',
                numreserva: ''
            },
            semoucomuh: "0",
            //fornecedores : [ /*{ id : 1 , nome : "Forn1" }, { id : 2 , nome : "Forn2" }, { id : 3 , nome : "Forn3" } */],
            //nomeFornecedor : '',
            //fornecedorIdPessoa : 0
        }
    },
    computed: {
        temAcesso() {
            return (this.$store.getters.GetLoginInfo.idusuariotipo == '1' || this.$store.getters.GetLoginInfo.idusuariotipo == '5')
        }
    },
    methods: {
        formatarData(aDate) {
            return moment(aDate).locale(this.$i18n.locale).format('L');
        },
        async searchPartyByName(nome){
            //let idForn = -1
            let element = await this.fornecedores.find(element => element.nome == nome)
            this.solicitacaoEditada.fornecedor = element.idpessoa
            //console.log(JSON.stringify(element))
        },
        proximo() {
            if (this.semoucomuh == "0") {
                this.step += 2
            } else {
                if (this.checkins&&this.checkins.length==0) {
                    this.solicitacaoEditada.coduh = this.filtro.coduh
                }
                this.step++
            }
        },
        voltar() {
            if (this.semoucomuh == "0") {
                this.step -= 2
            } else {
                this.step--
            }
        },
        next(checkin) {
            this.solicitacaoEditada.nome = checkin?checkin.nome :''
            this.solicitacaoEditada.coduh = checkin?checkin.coduh:''
            this.solicitacaoEditada.numreserva = checkin?checkin.numreserva:''
            this.solicitacaoEditada.idreservasfront = checkin?checkin.idreservasfront:''
            this.step++
        },
        isEven(val) {
            return (val % 2 == 0)
        },
        loadCheckins() {
            var searchParams = {
                idioma: this.$i18n.locale
            }
            if (this.filtro.nome.trim()) {
                searchParams.nome = this.filtro.nome.trim()
            }
            if (this.filtro.coduh.trim()) {
                searchParams.coduh = this.filtro.coduh.trim()
            }
            if (this.filtro.numreserva.trim()) {
                searchParams.numreserva = this.filtro.numreserva.trim()
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/checkins/select', searchParams
                , { headers: this.headers })
            .then(response => {
                try {
                    if (response.status == 200) {
                        if (response.data) {
                            this.checkins = response.data;
                        }
                    } else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
                }
                catch(e) {
                    console.log(e);
                }
            }).catch((err) => {
                console.log({ err_menu: err });
            });
        }, 
        loadSetores() {
			axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesetores/select', {
				idioma: this.$i18n.locale
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.setores = response.data;
                            this.loadTiposSolicitacao();
						}
					} else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        async loadTiposSolicitacao() {
            let params = {
                idioma: this.$i18n.locale,
                interna: this.interna,
                ativo: true
            }
            if (this.solicitacaoEditada.idhotlinesetores && !this.interna) {
                params.idhotlinesetores = this.solicitacaoEditada.idhotlinesetores
            }
            //console.error(JSON.stringify(params))
			await axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select', params
                , { headers: this.headers })
            .then(response => {
					if (response.status == 200) {
						if (response.data) {
                            //console.error(`response.data.length:${response.data.length}`)
							this.tipossolicitacao = response.data;
                            this.loadTipoSolicitacaoItens();
                            this.$forceUpdate();
						}
					} else {
                        console.log('Erro: '+ JSON.stringify(response));
                    }                    
			}).catch((err) => {
				console.log({ erro: err });
			});
        },
        async loadTipoSolicitacaoItens() {
            this.tiposolicitacaoItens = [];
            //console.error(`loadTipoSolicitacaoItens()... this.solicitacaoEditada.idtiposolicitacao: ${this.solicitacaoEditada.idtiposolicitacao}`)
			await axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacaoitem/select', {
                    idioma: this.$i18n.locale,
                    idtiposolicitacao: this.solicitacaoEditada.idtiposolicitacao,
                    interna: this.interna
                }, { headers: this.headers })
            .then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.tiposolicitacaoItens = response.data;
                            this.$forceUpdate();
						}
					} else {
                        console.log('Erro: '+ JSON.stringify(response));
                    }                    
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
    },
    beforeMount() {
        this.loadSetores();
        this.loadCheckins();
    },
    mounted() {
        this.solicitacaoEditada = {... this.solicitacao};
    }
}
</script>

<style>
    .es-row-select {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3px;
        margin-bottom: 3px;
        /* margin-right: -15px;
        margin-left: -15px;      */
        cursor: pointer;
        background-color: white;
        align-items: center;
        border-radius: 5px;
        min-height: 40px;
    }
    .es-odd {
        color: white;
        background-color: #62AFFF;
    }
    .es-odd:hover {
        background-color: #3f6ea1;
    }
    .es-even {
        background-color: rgb(240, 239, 239);
    }
    .es-even:hover {
        color: white;
        background-color: rgb(158, 157, 157);
    }
    .es-title {
        color: white;
        background-color: #0c4a8b;
    }
    .es-title:hover {
        background-color: #1c77d8;
    }
    .negrito {
        font-weight: bold;
    }
</style>