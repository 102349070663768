<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="welcome-card rounded pl-5 pt-5 pb-4 mt-3 position-relative mb-5">
                <h4 class="text-info">{{$t('avaliacao.titulo')}}</h4>
                <p>{{$t('avaliacao.descricao')}}</p>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Avaliações Cadastradas</h4>
                    <a @click="criarAvaliacao()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Cadastrar</a>
                </div>
                <div class="card-body">
                    <div class="row" id="criarAvalDiv" style="display: none;">
                        <AvaliacaoEditar 
                            :avaliacao="novaAvaliacao"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
                        />
                    </div>
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>Avaliação</strong></th>
                                    <th><strong>Programado para</strong></th>
                                    <th><strong>Status</strong></th>
                                    <th><strong>AÇÃO</strong></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in avaliacoes" :key="item.idtipoavaliacao">
                                <tr>
                                    <td><strong>{{item.desctipoavaliacao}}</strong></td>
                                    <td>{{programadoPara(item)}}</td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a v-if="item.ativo" class="dropdown-item" @click="ativar(item, false)">Inativar</a>
                                                <a v-if="!item.ativo" class="dropdown-item" @click="ativar(item, true)">Ativar</a>
                                                <a class="dropdown-item" @click="editar(item)">Editar</a>
                                                <a class="dropdown-item" @click="editarPerguntas(item)">Perguntas</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div :id="'Editar_Aval_'+item.idtipoavaliacao" style="display: none;">
                                            <AvaliacaoEditar 
                                                :avaliacao="item" 
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
                                            />
                                        </div>
                                        <div :id="'Perguntas_'+item.idtipoavaliacao" style="display: none;">
                                            <AvaliacaoPerguntas
                                                :avaliacao="item" 
                                                v-on:close="closePerguntas(item)"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import AvaliacaoEditar from '../components/AvaliacaoEditar.vue'
import AvaliacaoPerguntas from '../components/AvaliacaoPerguntas.vue'

export default {
    components: {AvaliacaoEditar, AvaliacaoPerguntas},
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            avaliacoes: [],
            novaAvaliacao: {
                idtipoavaliacao: 0,
                idioma: this.$i18n.locale,
                desctipoavaliacao: '',
                intervalo: 0,
                datareferida: 'datacheckin'
            }
        }
    },
    methods: {
        ativar(item, ativo) {
            var itemAtivar = {
                idtipoavaliacao: item.idtipoavaliacao,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarAvaliacao() {
            var obj = document.getElementById('criarAvalDiv')
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeNew() {
            var obj = document.getElementById('criarAvalDiv')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveNew(item) {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                desctipoavaliacao: item.desctipoavaliacao,
                ativo: false,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                intervalo: item.intervalo,
                datareferida: item.datareferida,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/tipoavaliacao/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadAvaliacoes()
                    this.closeNew()
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        editar(item) {
            var obj = document.getElementById('Editar_Aval_'+item.idtipoavaliacao)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_Aval_'+item.idtipoavaliacao)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item) {
            var params = {
                idtipoavaliacao: item.idtipoavaliacao,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                desctipoavaliacao: item.desctipoavaliacao,
                ativo: item.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                intervalo: item.intervalo,
                datareferida: item.datareferida,
                alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/tipoavaliacao/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadAvaliacoes()
                    this.closeEdit(item)
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        editarPerguntas(item) {
            var obj = document.getElementById('Perguntas_'+item.idtipoavaliacao)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closePerguntas(item) {
            var obj = document.getElementById('Perguntas_'+item.idtipoavaliacao)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        loadAvaliacoes() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/tipoavaliacao/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.avaliacoes = response.data
                }
            })
        },
        programadoPara(item) {
            var result = ''
            var dias = parseInt(item.intervalo)
            if (dias > 0) {
                if (dias == 1) {
                    // singular
                    result += `${dias.toString()} ${this.$t('avaliacao.diaaposa')} `
                } else {
                    // plural
                    result += `${dias.toString()} ${this.$t('avaliacao.diasaposa')} `
                }
            } else {
                if (dias < 0) {
                    if (Math.abs(dias) == 1) {
                        // singular
                        result += `${Math.abs(dias).toString()} ${this.$t('avaliacao.diaantesda')} `
                    } else {
                        // plural
                        result += `${Math.abs(dias).toString()} ${this.$t('avaliacao.diasantesda')} `
                    }
                }
            }
            result += this.$t('avaliacao.'+item.datareferida)
            return result
        }
    },
    computed: {
    },
    mounted() {
        this.loadAvaliacoes();
    }
}
</script>

<style>

</style>