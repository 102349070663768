import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from './i18n';
import FlagIcon from 'vue-flag-icon';
import CONSTANTS from './constants'
import VueSocketIO from 'vue-socket.io'
import SocketIO from "socket.io-client";
import dayjs from 'dayjs'

const { snack, snackE, snackW } = require('./utils/snack')
Vue.prototype.$snack = snack
Vue.prototype.$snackE = snackE
Vue.prototype.$snackW = snackW


Vue.use(FlagIcon)
Vue.config.productionTip = false
Vue.prototype.$http = axios

const { fade, unfade } = require('./utils/animate')
Vue.prototype.$fade = fade
Vue.prototype.$unfade = unfade

import VueSignaturePad from 'vue-signature-pad'
Vue.use(VueSignaturePad);




// Redirecionar para o login quando houver erro de autenticação ou acesso.
axios.interceptors.response.use(
  function(successRes) {
    null;
    return successRes;
  }, 
  function(error) {
    console.error(`error.message: ${error.message}`);
    if (error.message.includes('401') || error.message.includes('403') && window.location.pathname != '/') {
        console.error('Erro de autenticação! Direcionar para login...');
        //sessionStorage.clear();
        document.location.href="/";
    // } else {
    //   console.error(`Outro erro: ${JSON.stringify(error)}`);
    }
    throw error;
  }
);






Vue.filter('formatarMoeda', function(value) {
  if (value) {
      var v = parseFloat(value).toFixed(2);
      v = v.replace(".", ",");
      v = v.replace(/,(\d)\b/g, ",$10");
      v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
      v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
      return v;
  } else {
      return '0,00'
  }
})

Vue.filter('formatarDataHora', function(value) {
  if (!value) return '';
  return dayjs(value).locale(i18n.locale).format('DD/MM/YYYY HH:mm:ss');
})



//SOCKET INICIO

Vue.use(
  new VueSocketIO({
      debug: true,
      connection: SocketIO(CONSTANTS.API_URL + '/fdg', { query: {}, autoConnect: false }),
      vuex: {
          store,
          actionPrefix: "SOCKET_",
          mutationPrefix: "SOCKET_",
      },
  })
);

//SOCKET FIM


new Vue({
  router,
  i18n,
  store,
  // sockets: {
  //   connect: function () {
  //       console.log('Socket conectado !!!')
  //   }
  // },
  render: h => h(App)
}).$mount('#app')
