<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <div class="basic-form">
                        <div class="form-row">
                            <div class="form-group col-md-5">
                                <label>Idioma:</label>
                                <select class="form-control" v-model="avaliacaoEditada.idioma">
                                    <template v-if="idiomas && idiomas.length > 0">
                                        <option v-for="idioma in idiomas" v-bind:key="idioma.ididioma"
                                            :value="idioma.codidioma"
                                            :selected="avaliacaoEditada.idioma==idioma.codidioma">{{idioma.descidioma}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-md-7">
                                <label>Avaliação:</label>
                                <input v-model="avaliacaoEditada.desctipoavaliacao" 
                                    type="text" class="form-control" placeholder="Descreva sua avaliação">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label>Referência:</label>
                                <select class="form-control" v-model="avaliacaoEditada.datareferida">
                                    <option 
                                        value="datacheckin"
                                        :selected="avaliacaoEditada.datareferida=='datacheckin'">Check-in
                                    </option>
                                    <option 
                                        value="datacheckoutprev" 
                                        :selected="avaliacaoEditada.datareferida=='datacheckoutprev'">Check-out
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <label>Dia de envio:</label>
                                <input v-model="avaliacaoEditada.intervalo" type="number" class="form-control" placeholder="1 ou -1">
                            </div>
                            <div class="form-group col-md-auto">
                                <a v-on:click="$emit('save',avaliacaoEditada)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                            </div>
                            <div class="form-group col-md-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    props: {
        avaliacao: {
            type: Object,
            required: true,
        }
    },    
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            avaliacaoEditada: {}
        }
    },
    methods: {
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
    },
    mounted() {
        this.avaliacaoEditada = {... this.avaliacao}
    }
}
</script>

<style>

</style>