import moment from "moment";

export default [
    {
        idrelatorio: 1,
        titulo: 'Movimetação de Estoque por Categoria',
        codigo: 'CAT',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Categoria', campo: 'desccategoriaprod', align: 'left', tipo: 'string'},
            {nome: 'Entradas', campo: 'entradas', align: 'center'},
            {nome: 'Saídas', campo: 'saidas', align: 'center'},
            {nome: 'Saldo Atual', campo: 'saldo_atual', align: 'center'}
        ],
        dados: []
    },
    {
        idrelatorio: 2,
        titulo: 'Movimetação de Estoque por Produto',
        codigo: 'PRD',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Produto', campo: 'descproduto', align: 'left', tipo: 'string'},
            {nome: 'Categoria', campo: 'desccategoriaprod', align: 'left', tipo: 'string'},
            {nome: 'Entradas', campo: 'entradas', align: 'center'},
            {nome: 'Saídas', campo: 'saidas', align: 'center'},
            {nome: 'Saldo Atual', campo: 'saldo_atual', align: 'center'}
        ],
        dados: []
    },
    {
        idrelatorio: 3,
        titulo: 'Média de Tempo por Categoria',
        codigo: 'MTC',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Categoria', campo: 'categoria', align: 'left', tipo: 'string'},
            {nome: 'Mínimo (h)', campo: 'minimo', align: 'center'},
            {nome: 'Máximo (h)', campo: 'maximo', align: 'center'},
            {nome: 'Média (h)', campo: 'media', align: 'center'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'center', tipo: 'date'},
            {nome: 'Data Atendimento', campo: 'dataatendimento', align: 'center', tipo: 'date'},
            {nome: 'Tempo', campo: 'tempo', align: 'right', tipo: 'number'}
        ],
        opcoes: [
            {id: 1, desc:'Incluir apenas solicitações internas'},
            {id: 2, desc:'Incluir apenas solicitações de hóspedes'},
            {id: 3, desc:'Incluir todas as solicitações'},
        ],
        dados: []
    },
    {
        idrelatorio: 4,
        titulo: 'Média de Tempo por Técnico e Categoria',
        codigo: 'MTT',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Técnico', campo: 'tecnico', align: 'left', tipo: 'string'},
            {nome: 'Categoria', campo: 'categoria', align: 'left', tipo: 'string'},
            {nome: 'Mínimo (h)', campo: 'minimo', align: 'center'},
            {nome: 'Máximo (h)', campo: 'maximo', align: 'center'},
            {nome: 'Média (h)', campo: 'media', align: 'center'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'center', tipo: 'date'},
            {nome: 'Data Atendimento', campo: 'dataatendimento', align: 'center', tipo: 'date'},
            {nome: 'Tempo', campo: 'tempo', align: 'right', tipo: 'number'}
        ],
        opcoes: [
            {id: 1, desc:'Incluir apenas solicitações internas'},
            {id: 2, desc:'Incluir apenas solicitações de hóspedes'},
            {id: 3, desc:'Incluir todas as solicitações'},
        ],
        dados: []
    },
    {
        idrelatorio: 5,
        titulo: 'Chamados totais por Categoria',
        codigo: 'CTC',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Categoria', campo: 'categoria', align: 'left', tipo: 'string'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        opcoes: [
            {id: 1, desc:'Incluir apenas solicitações internas'},
            {id: 2, desc:'Incluir apenas solicitações de hóspedes'},
            {id: 3, desc:'Incluir todas as solicitações'},
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'center', tipo: 'date'},
            {nome: 'Data Atendimento', campo: 'dataatendimento', align: 'center', tipo: 'date'},
        ],
        dados: []
    },
    {
        idrelatorio: 6,
        titulo: 'Chamados totais por Categoria e UH',
        codigo: 'CTU',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Categoria', campo: 'categoria', align: 'left', tipo: 'string'},
            {nome: 'UH', campo: 'coduh', align: 'left', tipo: 'string'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'center', tipo: 'date'},
            {nome: 'Data Atendimento', campo: 'dataatendimento', align: 'center', tipo: 'date'},
        ],
        dados: []
    },
    {
        idrelatorio: 7,
        titulo: 'Chamados totais por Responsável e Status',
        codigo: 'CTR',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Responsável', campo: 'tecnico', align: 'left', tipo: 'string'},
            {nome: 'Status', campo: 'status', align: 'left', tipo: 'string'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'center', tipo: 'date'},
            {nome: 'Data Atendimento', campo: 'dataatendimento', align: 'center', tipo: 'date'},
        ],
        dados: []
    },
    {
        idrelatorio: 8,
        titulo: 'Itens Solicitados por Chamado',
        codigo: 'CTR',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Chamado', campo: 'chamado', align: 'left', tipo: 'string'},
            {nome: 'Descrição', campo: 'descricao', align: 'left', tipo: 'string'},
            {nome: 'Responsável', campo: 'tecnico', align: 'left', tipo: 'string'},
            {nome: 'Status', campo: 'status', align: 'left', tipo: 'string'},
            {nome: 'Item', campo: 'item', align: 'left', tipo: 'string'},
            {nome: 'Qtde. Sol.', campo: 'qtdesolicitada', align: 'center'},
            {nome: 'Qtde. Lib.', campo: 'qtdeliberada', align: 'center'},
            {nome: 'Qtde. Ent.', campo: 'qtdeentregue', align: 'center'}
        ],
        dados: []
    },
    {
        idrelatorio: 9,
        titulo: 'Média de Aceite por Categoria',
        codigo: 'MAC',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Categoria', campo: 'categoria', align: 'left', tipo: 'string'},
            {nome: 'Mínimo (h)', campo: 'minimo', align: 'center'},
            {nome: 'Máximo (h)', campo: 'maximo', align: 'center'},
            {nome: 'Média (h)', campo: 'media', align: 'center'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'center', tipo: 'date'},
            {nome: 'Data Visualização', campo: 'datavisualizacao', align: 'center', tipo: 'date'},
        ],
        dados: []
    },
    {
        idrelatorio: 10,
        titulo: 'Média de Aceite por Técnico e Categoria',
        codigo: 'MAT',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Técnico', campo: 'tecnico', align: 'left', tipo: 'string'},
            {nome: 'Categoria', campo: 'categoria', align: 'left', tipo: 'string'},
            {nome: 'Mínimo (h)', campo: 'minimo', align: 'center'},
            {nome: 'Máximo (h)', campo: 'maximo', align: 'center'},
            {nome: 'Média (h)', campo: 'media', align: 'center'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'center', tipo: 'date'},
            {nome: 'Data Visualização', campo: 'datavisualizacao', align: 'center', tipo: 'date'},
        ],
        dados: []
    },
    {
        idrelatorio: 11,
        titulo: 'Sugestões de Compra por Produto',
        codigo: 'PCP',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Produto', campo: 'descproduto', align: 'left', tipo: 'string'},
            {nome: 'Unidade', campo: 'unidade', align: 'left', tipo: 'string'},
            {nome: 'Saídas', campo: 'saidas', align: 'center'},
            {nome: 'Consumo Diário', campo: 'consumo_diario', align: 'center'},
            {nome: 'Consumo', campo: 'consumo', align: 'center'},
            {nome: 'Estoque Mínimo', campo: 'estoque_minimo', align: 'center'},
            {nome: 'Reposição', campo: 'ponto_reposicao', align: 'center'},
            {nome: 'Saldo Atual', campo: 'saldo_atual', align: 'center'},
            {nome: 'Status Compra', campo: 'status_compra', align: 'center'},
            {nome: 'Requisitar', campo: 'repor', align: 'center'}
        ],
        dados: [],
        opcoes: [
            {id: 1, desc:'Consumo para 30 dias - Todos'},
            {id: 2, desc:'Consumo para 15 dias - Todos'},
            {id: 3, desc:'Consumo para 30 dias - Apenas a comprar'},
            {id: 4, desc:'Consumo para 15 dias - Apenas a comprar'},
            {id: 5, desc:'Consumo para 30 dias - Apenas OK'},
            {id: 6, desc:'Consumo para 15 dias - Apenas OK'},
        ],
        opcao: 1
    },
    {
        idrelatorio: 12,
        titulo: 'Média e Total de Tempo por Técnico',
        codigo: 'TTT',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Técnico', campo: 'tecnico', align: 'left', tipo: 'string'},
            {nome: 'Mínimo (h)', campo: 'minimo', align: 'center'},
            {nome: 'Máximo (h)', campo: 'maximo', align: 'center'},
            {nome: 'Média (h)', campo: 'media', align: 'center'},
            {nome: 'Total (h)', campo: 'total_h', align: 'center'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'center', tipo: 'date'},
            {nome: 'Data Atendimento', campo: 'dataatendimento', align: 'center', tipo: 'date'},
            {nome: 'Tempo', campo: 'tempo', align: 'right', tipo: 'number'}
        ],
        opcoes: [
            {id: 1, desc:'Incluir apenas solicitações internas'},
            {id: 2, desc:'Incluir apenas solicitações de hóspedes'},
            {id: 3, desc:'Incluir todas as solicitações'},
        ],
        dados: []
    },
    {
        idrelatorio: 13,
        titulo: 'Itens Solicitados por Local de Atendimento',
        codigo: 'ILA',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Local', campo: 'localatendimento', align: 'left', tipo: 'string'},
            {nome: 'Item', campo: 'item', align: 'left', tipo: 'string'},
            {nome: 'Qtde. Sol.', campo: 'solicitada', align: 'center'},
            {nome: 'Qtde. Lib.', campo: 'liberada', align: 'center'},
            {nome: 'Qtde. Ent.', campo: 'entregue', align: 'center'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Local', campo: 'localatendimento', align: 'center', tipo: 'string'},
            {nome: 'Responsável', campo: 'responsavel', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'right', tipo: 'date'}
        ],
        dados: []
    },
    {
        idrelatorio: 14,
        titulo: 'Chamados Externos por Fornecedor e Status',
        codigo: 'CEF',
        periodo: {
            dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD'))
        },
        cabecalhos: [
            {nome: 'Fornecedor', campo: 'fornecedor', align: 'left', tipo: 'string'},
            {nome: 'Status', campo: 'status', align: 'left', tipo: 'string'},
            {nome: 'Total', campo: 'total', align: 'center', link: true}
        ],
        cabecalhosDetalhes: [
            {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
            {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
            {nome: 'Data Solicitação', campo: 'datasolicitacao', align: 'center', tipo: 'date'},
            {nome: 'Data Atendimento', campo: 'dataatendimento', align: 'center', tipo: 'date'},
        ],
        dados: []
    }
]