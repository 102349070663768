import { render, staticRenderFns } from "./SolCompras.vue?vue&type=template&id=22954eeb&scoped=true&"
import script from "./SolCompras.vue?vue&type=script&lang=js&"
export * from "./SolCompras.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22954eeb",
  null
  
)

export default component.exports