<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card es-azure">
                <div class="card-header">
                    <div class="basic-form">
                        <div class="form-row">
                            <!-- Categoria -->
                            <div class="form-group col-md-4">
                                <label>{{$t('estoque.categoria')}}</label>
                                <select id="filtro_setor" class="form-control" v-model="estoqueEditado.idcategoriaprod"
                                    @change="categoriaChanged()">
                                    <template v-if="categorias && categorias.length > 0">
                                        <option v-for="categoria in categorias" v-bind:key="categoria.idcategoriaprod"
                                            :value="categoria.idcategoriaprod"
                                            :selected="estoqueEditado.idcategoriaprod==categoria.idcategoriaprod">{{categoria.desccategoriaprod}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <!-- Produto -->
                            <div class="form-group col-md-4">
                                <label>{{$t('estoque.produto')}}</label>
                                <select class="form-control" v-model="estoqueEditado.idproduto">
                                    <template v-if="produtos && produtos.length > 0">
                                        <option v-for="produto in produtos" v-bind:key="produto.idproduto"
                                            :value="produto.idproduto">{{produto.descproduto}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <!-- Quantidade -->
                            <!-- <div class="form-group col-sm-auto">
                                <label>{{$t('estoque.quantidade')}}:</label>
                                <input v-model="estoqueEditado.quantidade" min="0"
                                    type="number" class="form-control" :placeholder="$t('estoque.quantidade')">
                            </div>                             -->

                            <!-- Quantidade -->
                            <div class="form-group col-md-4">
                            <!-- <div class="form-group col-md-4 mb-0" style="margin-bottom:0.5em;"> -->
                                <label class="pb-0 mb-0">{{$t('estoque.quantidade')}}:</label>
                                <div class="input-group cart-count cart-count-lg rounded py-0">
                                    <div class="input-group-prepend">
                                        <button @click="decrementar()" 
                                            class="es-spin es-minus rounded" type="button">-</button>
                                    </div>
                                    <input type="text" class="es-input form-control rounded" placeholder="" min="0" 
                                        v-model="estoqueEditado.quantidade" style="text-align:center;"
                                    >
                                    <div class="input-group-append">
                                        <button @click="incrementar()" 
                                            class="es-spin es-plus rounded" type="button">+</button>
                                    </div>
                                </div>
                            </div>

                            <!-- Unidade -->
                            <div class="form-group col-sm-auto">
                                <label>{{$t('estoque.unidade')}}:</label>
                                <input v-model="estoqueEditado.unidade" min="0"
                                    type="text" class="form-control" :placeholder="$t('estoque.unidade')">
                            </div>                            

                            <!-- Limite Mínimo -->
                            <div class="form-group col-sm-auto">
                                <label>{{$t('estoque.limiteminimo')}}:</label>
                                <input v-model="estoqueEditado.limiteminimo" min="0"
                                    type="number" class="form-control" :placeholder="$t('estoque.limiteminimo')">
                            </div>

                            <!-- Limite Máximo -->
                            <div class="form-group col-sm-auto">
                                <label>{{$t('estoque.limitemaximo')}}:</label>
                                <input v-model="estoqueEditado.limitemaximo" min="0"
                                    type="number" class="form-control" :placeholder="$t('estoque.limitemaximo')">
                            </div>

                            <!-- Status -->
                            <div class="form-group col-md-4">
                                <label>{{$t('sols.status')}}</label>
                                <select class="form-control" v-model="estoqueEditado.ativo">
                                    <template v-if="statusList && statusList.length > 0">
                                        <option v-for="status in statusList" v-bind:key="status.id"
                                            :value="status.id == 1"
                                            :selected="estoqueEditado.ativo==(status.id==1?true:false)">{{status.descricao}}
                                        </option>
                                    </template>
                                </select>
                            </div>

                            <!-- Autocomplete Ícone -->
                            <div class="form-group col-md-4">
                                <label>{{$t('global.icone')}}:</label>
                                <Autocomplete :items="placeIcons" 
                                    v-model="estoqueEditado.icone"
                                    :placeHolder="$t('global.icone')">
                                </Autocomplete>
                            </div>
                            <!-- Ícone -->
                            <div class="form-group col-md-4" style="align-self:center;padding-bottom:0;">
                                <div>
                                    <span class="es-icon material-icons icon">{{estoqueEditado.icone}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('save',estoqueEditado)" class="ponteiro text-white btn btn-info mt-4">{{$t('global.salvar')}}</a>
                            </div>
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">{{$t('global.fechar')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import Autocomplete from './Autocomplete.vue'

export default {
    components: {Autocomplete},
    props: {
        estoque: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            estoqueEditado: {},
            icones: [
                {chave: 'face'},
                {chave: 'map'},
                {chave: 'handyman'},
                {chave: 'pool'},
                {chave: 'hotel'},
                {chave: 'room_service'},
                {chave: 'security'},
                {chave: 'local_atm'},
                {chave: 'wifi'},
                {chave: 'restaurant'},
                {chave: 'shopping_cart'}
            ],
            placeIcons: [
                'airport_shuttle',
                'all_inclusive',
                'bathtub',
                'beach_access',
                'apartment',
                'casino',
                'checkroom',
                'child_care',
                'corporate_fare',
                'business_center',
                'desk',
                'family_restroom',
                'fitness_center',
                'grass',
                'holiday_village',
                'house',
                'meeting_room',
                'other_houses',
                'spa',
                'storefront',
                'roofing',
                'room_service',
                'room_preferences',
                'sports_bar',
                'free_breakfast',
                'foundation',
                'food_bank',
                'gite',
                'villa',
                'night_shelter',
                'golf_course',
                'microwave',
                'stairs',
                'elevator',
                'wash',
                'smoking_rooms',
                'cabin',
                'house_siding',
                'hot_tub',
                'countertops',
                'water_damage',
                'charging_station',
                'soap',
                'rv_hookup',
                'baby_changing_station',
                'umbrella',
                'smoke_free',
                'tapas',
                'rice_bowl',
                'no_food',
                'fence',
                'houseboat',
                'tty',
                'wheelchair_pickup',
                'bento',
                'iron',
                'bungalow',
                'no_meeting_room',
                'do_not_step',
                'escalator',
                'dry',
                'stroller',
                'no_drinks',
                'no_cell',
                'no_flash',
                'vaping_rooms',
                'vape_free',
            ],
            categorias: [],
            produtos: [],
            statusList: [
                {id: 1, descricao: this.$t('global.ativo'), valor: true},
                {id: 2, descricao: this.$t('global.inativo'), valor: false},
            ]
        }
    },
    methods: {
        incrementar() {
            this.estoqueEditado.quantidade++;
            this.$forceUpdate();
        },
        decrementar() {
            if (this.estoqueEditado.quantidade > 0) {
                this.estoqueEditado.quantidade--;
                this.$forceUpdate();
            }
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        categoriaChanged() {
            this.carregarProdutos();
            this.$forceUpdate();
        },
        carregarCategorias() {
            this.categorias = []
            var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/produto/categoria/select',
                    params, { headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.categorias = response.data.categorias
                }
            })
        },
        carregarProdutos(){
           this.produtos = []
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            if (this.estoqueEditado.idcategoriaprod > 0) {
                params.idcategoriaprod = this.estoqueEditado.idcategoriaprod
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/produto/select',
                    params, { headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.produtos = response.data
                    this.$forceUpdate();
                }
            })
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
        this.carregarCategorias();
        this.carregarProdutos();
    },
    mounted() {
        this.estoqueEditado = {... this.estoque}
    }
}
</script>

<style>
.es-azure {
    background-color: azure!important;
}
.es-icon {
	padding: 0.3em;
	font-size: 2.3em;
}
.es-spin {
    font-weight: bold;
    font-size: 1.2em;
    height: 45px;
    width: 45px;
    align-self: center;
}
.es-minus {
    color: red;
    border: 1px solid red;
}
.es-minus:hover {
    color: white;
    background-color: red;
}
.es-plus {
    color: green;
    border: 1px solid green;
}
.es-plus:hover {
    color: white;
    background-color: green;
}
.es-btn {
    font-weight: bold;
    font-size: 1.2em;
}
.es-input {
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 70px!important;
    font-size: 1.6em;
}
</style>