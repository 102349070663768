<template>
    <div class="row">
        <div v-if="loading">
            <div class="card">
                <div class="card-header">
                    Carregando...
                </div>
            </div>
        </div>
        <div v-if="!loading" class="col-xl-12" style="margin: 0px; padding: 3px; padding-top:0px;">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('sols.solicitacao')}} # {{solicitacao.idhotlinesolicitacoes}}</h4>
                    <div style="font-size: 1rem!important;" class="text-center">{{formatarData(solicitacao.datasolicitacao)}}</div>
                    <strong>
                        <span class="os-interna" v-if="solicitacao.interna"> (OS Interna)</span>
                    </strong>
                    <!-- <span :class="{ 'badge-danger' : solicitacao.idstatus == 5 , 'badge-success' : solicitacao.idstatus == 3, 'badge-warning' : solicitacao.idstatus == 1,'badge-primary' : solicitacao.idstatus == 2,'badge-secondary' : solicitacao.idstatus == 6,'badge-info' : solicitacao.idstatus == 4}" class="badge light "
                        style="font-size: 1rem!important;">
                        {{solicitacao.status}}
                    </span> -->
                    <span style="font-size: 1rem!important;" :class="{ 'badge-warning' : solicitacao.idstatus == 5 , 'badge-success' : solicitacao.idstatus == 3, 'badge-secondary' : solicitacao.idstatus == 1,'badge-primary' : solicitacao.idstatus == 2, 'badge-dark' : solicitacao.idstatus == 6, 'badge-danger' : solicitacao.idstatus == 4  }" class="badge light ">{{solicitacao.status}}</span>
                    <a @click="voltar()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">Voltar</a>
                    <button class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4" data-toggle="modal" data-target="#modalImp">{{$t('sols.impressao')}}</button>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label>{{$t('sols.setor')}}</label>
                            <input :value="solicitacao.deschotlinesetor" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                        <div class="form-group col-md-4">
                            <label>{{$t('sols.tipo')}}</label>
                            <input :value="solicitacao.tipo" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                        <!--Novo campo ini-->
                        <div class="form-group col-md-4">
                            <label>{{$t('sols.local')}}</label>
                            <input :value="solicitacao.localizacao" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                        <!--Novo campo fim-->

                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label>{{$t('sols.nome')}}</label>
                            <input :value="solicitacao.nome + ' ' + solicitacao.sobrenome" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                        <div class="form-group col-md-4">
                            <label>{{$t('sols.uh')}}</label>
                            <input v-model="solicitacao.coduh" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                        <div class="form-group col-md-4">
                            <label>{{$t('sols.reserva')}}</label>
                            <input v-model="solicitacao.numreserva" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label>{{$t('sols.descricao')}}</label>
                            <textarea rows="7" readonly class="form-control es-sol-text"
                                v-model="solicitacao.textohotlinesolicitacoes">
                            </textarea>
                        </div>
                    </div>
                    <div class="form-row mx-4">
                        <div class="form-group col-md-3">
                            <label>{{$t('sols.prioridade')}}</label>
                            <input v-model="descPrioridade" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                        <div class="form-group col-md-3">
                            <label>{{$t('sols.responsavel')}}</label>
                            <input v-model="solicitacao.responsavel" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                        <div class="form-group col-md-3">
                            <label>{{$t('sols.localatendimento')}}</label>
                            <input v-model="solicitacao.localatendimento" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                        <div v-if="solicitacao.manutencaoexterna" class="form-group col-md-3">
                            <label>{{$t('sols.fornecedor')}}</label>
                            <input v-model="solicitacao.nomefornecedor" readonly
                                type="text" class="form-control" style="font-weight: bold;">
                        </div>
                    </div>
                    <template v-if="solicitacao.idstatus == 6">
                        <div class="form-row">
                            <label>{{$t('sols.motivo')}}</label>
                            <div class="form-group col-md-12">
                                <input v-model="solicitacao.textocancelamento" readonly
                                    type="text" class="form-control">
                            </div>
                        </div>
                    </template>
                    <template v-if="(solicitacao.produtos&&solicitacao.produtos.length>0)&&(solicitacao.idstatus == 3 || solicitacao.idstatus == 4 || solicitacao.idstatus == 6)">
                        <div class="es-link ponteiro my-2 py-1 rounded-sm" @click="togglePedido()">
                            <span class="material-icons icon icone-filtro ponteiro">{{exibirPedido?'arrow_drop_up':'arrow_drop_down'}}</span>
                            {{togglePedidoText}}
                        </div>
                        <div id="pedido-solicitacao" class="form-row"
                            style="display:none;width:100%;"
                        >
                            <div class="form-group col-sm-12 px-4">
                                <solicitacao-produtos :produtos="solicitacao.produtos"></solicitacao-produtos>
                            </div>
                            <!-- Assinatura -->
                            <div class="form-group col-sm-12 text-center">
                                <img v-if="imagemSrc" :src="'data:' + imagemTipo + ';base64,' + imagemSrc" 
                                    :id="'imgAss_'+solicitacao.idhotlinesolicitacoes"
                                    width="200px" height="auto"
                                />
                            </div>
                        </div>
                    </template>
                    <div class="es-link ponteiro my-2 py-1 rounded-sm" @click="toggle()">
                        <span v-if="!exibirHistorico" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                        <span v-if="exibirHistorico" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                        {{toggleText}}
                    </div>
                    <div id="historico-solicitacao" style="display:none;width:100%;">
                        <chamado-historico v-if="solicitacao.assinaturas" :chamado="solicitacao"></chamado-historico>
                        <solicitacao-historico :interna="solicitacao.interna"
                            :idhotlinesolicitacoes="solicitacao.idhotlinesolicitacoes">
                        </solicitacao-historico>
                    </div>
                    <template v-if="solicitacao.assinaturas">
                        <div class="es-link ponteiro my-2 py-1 rounded-sm" @click="toggleAssinaturas()">
                            <span v-if="!exibirAssinaturas" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                            <span v-if="exibirAssinaturas" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                            {{toggleAssinaturasText}}
                        </div>
                        <div id="historico-chamado" style="display:none;width:100%;padding:20px;">
                            <chamado-historico :chamado="solicitacao"
                                @ass="exibirImagem('ass',$event)"
                                @doc="exibirImagem('doc',$event)"
                            ></chamado-historico>
                        </div>
                    </template>
                    <template v-if="solicitacao.checklist&&solicitacao.checklist.length>0">
                        <div class="es-link ponteiro my-2 py-1 rounded-sm" @click="toggleChecklist()">
                            <span v-if="!exibirChecklist" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                            <span v-if="exibirChecklist" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                            {{toggleChecklistText}}
                        </div>
                        <div id="checklist-solicitacao" style="display:none;width:100%;">
                            <div class="form-row" style="width:100%;">
                                <SolicitacaoCheckList 
                                    :checklist="solicitacao.checklist"
                                    :somenteLeitura="true"
                                >
                                </SolicitacaoCheckList>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="form-row mx-1">
                    <template v-if="solicitacao.idstatus == 1">
                        <div class="form-group col-md-auto">
                            <a @click="showEmAtendimento(solicitacao)" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">{{$t('sols.ematendimento')}}</a>
                        </div>
                        <div class="form-group col-md-auto">
                            <a v-if="solicitacao.interna" @click="showEdit(solicitacao)" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">{{$t('global.editar')}}</a>
                        </div>
                        <div class="form-group col-md-auto">
                            <a @click="showCancel(solicitacao)" class="ponteiro text-white btn btn-warning rounded ml-2 btn-sm px-4">{{$t('sols.cancelar')}}</a>
                        </div>
                    </template>
                    <template v-if="solicitacao.idstatus == 2 || solicitacao.idstatus == 1">
                        <!--novo botao msoa ini-->
                        <div class="form-group col-md-auto">
                            <a @click="showModalProdutos=!showModalProdutos" data-toggle="modal" data-target="#modalLong" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">{{$t('sols.produtos')}}</a>
                        </div>
                        <!--novo botao msoa ifim-->
                    </template>
                    <template v-if="solicitacao.idstatus == 2">
                        <div class="form-group col-md-auto">
                            <a @click="showModal(solicitacao)"  class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">{{$t('sols.atender')}}</a>
                        </div>
                        <div class="form-group col-md-auto">
                            <a v-if="solicitacao.interna" @click="showEdit(solicitacao)" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">{{$t('global.editar')}}</a>
                        </div>
                        <div class="form-group col-md-auto">
                            <a @click="showCancel(solicitacao)" class="ponteiro text-white btn btn-warning rounded ml-2 btn-sm px-4">{{$t('sols.cancelar')}}</a>
                        </div>
                    </template>
                    <template v-if="solicitacao.idstatus == 5">
                        <div class="form-group col-md-auto">
                            <a @click="showModal(solicitacao)"  class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">{{$t('sols.tratar')}}</a>
                        </div>
                        <div class="form-group col-md-auto">
                            <a v-if="solicitacao.interna" @click="showEdit(solicitacao)" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">{{$t('global.editar')}}</a>
                        </div>
                        <div class="form-group col-md-auto">
                            <a @click="showCancel(solicitacao)" class="ponteiro text-white btn btn-warning rounded ml-2 btn-sm px-4">{{$t('sols.cancelar')}}</a>
                        </div>
                    </template>
                </div>
                <!-- Editar Solicitação -->
                <div v-if="solicitacao.interna" 
                    :id="'Editar_'+solicitacao.idhotlinesolicitacoes" 
                    class="form-row mx-4" style="display:none;"
                >
                    <div class="form-group col-md-3">
                        <label>{{$t('sols.prioridade')}}</label>
                        <select class="form-control" v-model="solicitacao.idprioridade">
                            <template v-if="prioridades && prioridades.length > 0">
                                <option v-for="prioridade in prioridades" v-bind:key="prioridade.idprioridade"
                                    :value="prioridade.idprioridade"
                                    :selected="solicitacao.idprioridade==prioridade.idprioridade">{{prioridade.descricao}}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="form-group col-md-3">
                        <label>{{$t('sols.responsavel')}}</label>
                        <select :disabled="!temAcesso" class="form-control" v-model="solicitacao.idresponsavel">
                            <template v-if="responsaveis && responsaveis.length > 0">
                                <option v-for="responsavel in responsaveis" v-bind:key="responsavel.idusuario"
                                    :value="responsavel.idusuario"
                                    :selected="solicitacao.idresponsavel==responsavel.idusuario">{{responsavel.nome}}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="form-group col-md-3">
                        <label>{{$t('sols.localatendimento')}}</label>
                        <select :disabled="!temAcesso" class="form-control" v-model="solicitacao.idlocalatendimento">
                            <template v-if="locaisAtendimento && locaisAtendimento.length > 0">
                                <option v-for="local in locaisAtendimento" v-bind:key="local.idlocalatendimento"
                                    :value="local.idlocalatendimento"
                                    :selected="solicitacao.idlocalatendimento==local.idlocalatendimento">{{local.descricao}}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="form-group col-md-3">
                        <div class="custom-control custom-switch">
                            <label class="px-2 ponteiro" :for="`item-switch-${solicitacao.idhotlinesolicitacoes}`" >
                                {{$t('sols.manutencaoexterna')}}</label>
                            <input  type="checkbox" name="horario" class="ponteiro" 
                                :id="`item-switch-${solicitacao.idhotlinesolicitacoes}`"  
                                v-model="solicitacao.manutencaoexterna"
                            >
                        </div>
                        <select v-if="solicitacao.manutencaoexterna" class="form-control" v-model="solicitacao.fornecedor">
                            <template v-if="fornecedores && fornecedores.length > 0">
                                <option v-for="fornecedor in fornecedores" v-bind:key="fornecedor.idpessoa"
                                    :value="fornecedor.idpessoa"
                                    :selected="solicitacao.fornecedor==fornecedor.idfornecedor">{{fornecedor.nome}}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="form-group col-md-12" style="text-align:center;">
                        <button @click="salvarEdicao(solicitacao)" type="button" class="btn btn-primary mx-2">{{$t('ser.salvar')}}</button>
                        <button @click="hideTexts(solicitacao.idhotlinesolicitacoes)" type="button" class="btn btn-primary mx-2">{{$t('global.fechar')}}</button>
                    </div>
                </div>
                <!-- Produtos -->
                <div v-if="solicitacao.produtos && solicitacao.produtos.length>0" 
                    class="form-row mx-1"
                    :id="'Em_At_Produtos_'+solicitacao.idhotlinesolicitacoes" style="display:none;"
                    >
                    <div class="form-group col-md-9">
                        <div class="form-group col-sm-12">
                            <solicitacao-produtos :produtos="solicitacao.produtos"></solicitacao-produtos>
                        </div>
                        <!-- Assinatura -->
                        <div class="form-group col-sm-12 text-center">
                            <img v-if="imagemSrc" :src="'data:' + imagemTipo + ';base64,' + imagemSrc" 
                                :id="'imgAss_'+solicitacao.idhotlinesolicitacoes"
                                width="200px" height="auto"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-row mx-1">
                    <div class="form-group col-md-9">
                        <div :id="'Em_At_'+solicitacao.idhotlinesolicitacoes" style="display:none;">
                            <input :id="'Em_At_Text_'+solicitacao.idhotlinesolicitacoes" 
                                v-model="textoatendimento" type="text" class="form-control" :placeholder="$t('sols.escrevaconclusaoatendimento')"/> 
                            <button @click="mudarStatusPendente(solicitacao)" type="button" class="btn btn-primary ml-3">{{$t('sols.enviar')}}</button>
                        </div>
                    </div>
                </div>
                <div v-if="solicitacao.tipoitem == 6 && solicitacao.produtos && solicitacao.produtos.length>0" 
                    class="form-row mx-1"
                    :id="'At_Produtos_'+solicitacao.idhotlinesolicitacoes" :style="'display:none;'"
                    >
                    <div class="form-group col-md-9">
                        <div class="form-group col-sm-12">
                            <solicitacao-produtos :produtos="solicitacao.produtos"></solicitacao-produtos>
                        </div>
                        <!-- Assinatura -->
                        <div class="form-group col-sm-12 text-center">
                            <img v-if="imagemSrc" :src="'data:' + imagemTipo + ';base64,' + imagemSrc" 
                                :id="'imgAss_'+solicitacao.idhotlinesolicitacoes"
                                width="200px" height="auto"
                            />
                        </div>
                    </div>
                </div>
                <div :id="'At_'+solicitacao.idhotlinesolicitacoes" style="display:none;flex-direction:column;">
                    <div class="form-row" style="width:100%;">
                        <SolicitacaoCheckList 
                            :checklist="solicitacao.checklist?solicitacao.checklist:[]"
                            @update="$forceUpdate()"
                        >
                        </SolicitacaoCheckList>
                    </div>
                    <div class="row" style="width:100%;">
                        <div class="form-group col-md-auto mx-3" style="display:flex;flex-direction:row;align-items:center;">
                            <label :class="{ 'es-selected': atendido=='1', 'es-not-selected': atendido=='0'}" class="ponteiro badge-success badge light" for="atendido-sim">Atendido</label>
                            <input v-model="atendido" id="atendido-sim" name="atendido-ou-nao"
                                            type="radio" class="form-control ponteiro mx-2" value="1"
                                            style="display:none;" checked="checked">
                            <label :class="{ 'es-selected': atendido=='0', 'es-not-selected': atendido=='1'}" class="ponteiro badge-danger badge light" for="atendido-nao">Não atendido</label>
                            <input v-model="atendido" id="atendido-nao" name="atendido-ou-nao"
                                            type="radio" class="form-control ponteiro mx-2" value="0"
                                            style="display:none;">
                        </div>
                        <div class="form-group col-md-7 ml-2">
                            <input :id="'At_Text_'+solicitacao.idhotlinesolicitacoes" v-model="textoconclusao" type="text" class="form-control" :placeholder="$t('sols.escrevaconclusaoatendimento')"/> 
                        </div>
                        <div class="form-group col-md-auto">
                            <button @click="registrarAtendimento(solicitacao)" 
                                :disabled="!allChecked"
                                type="button" class="btn btn-primary ml-3">{{$t('sols.enviar')}}</button>
                        </div>
                    </div>
                </div>
                <div :id="'Cancel_'+solicitacao.idhotlinesolicitacoes" style="display:none;">
                    <div class="row m-2" style="width:100%;">
                        <div class="col-md-9">
                            <input :id="'CancelText_'+solicitacao.idhotlinesolicitacoes" v-model="textocancelamento" type="text" class="form-control" :placeholder="$t('sols.escrevamotivocancelamento')"/> 
                        </div>
                        <div class="col-md-2">
                            <button :disabled="!textocancelamento.trim()" @click="cancelarSolicitacao(solicitacao)" type="button" class="btn btn-primary ml-3">{{$t('sols.enviar')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Modal produtos-->        

        <SolicitacaoProdutosEditar :produtosSolicitacao="produtosSolicitacao" :show="showModalProdutos"
            :idusuariotipo="idusuariotipo" :produtosTodosStr="produtosTodosStr"
            @close="showModalProdutos=false" :idSol="solicitacao.idhotlinesolicitacoes?solicitacao.idhotlinesolicitacoes:0"
            @item-changed="setProduto($event.p, $event.index)"
            @item-deleted="excluirItem($event.p, $event.index)"
            @save-stock="salvarEstoque($event.item, $event.itemSol)"
            @add-prod="addProdArray()"
            @save="salvarProdutos($event)"
        ></SolicitacaoProdutosEditar>

        <!--Modal Impressão-->
        <div class="modal fade" id="modalImp" tabindex="-1" role="dialog" aria-labelledby="modalLongTitle" aria-hidden="true">
            <div class="modal-dialog" role="document" >
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLongTitle">{{$t('sols.impressao')}} # {{idhotlinesolicitacoes}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <span class="mb-4">Adicione até 5 campos adicionais a sua impressão</span> 
                    <br/>
                    <div v-for="campo in campos" :key="campo.id" style="display:flex; flex-direction:row">
                    <input :disabled="assinando == true ? true : false" class="form-control mt-2 mb-2 mr-2" type="text" placeholder="Campo" v-model="campo.texto">
                    <input :disabled="assinando == true ? true : false" class="form-control mt-2 mb-2" type="text" placeholder="Valor" v-model="campo.valor">
                    <button v-if="assinando == false" @click="delCamposArray(campos.indexOf(campo))" type="button" class="ponteiro text-white btn btn-warning rounded ml-2 mt-2 mb-2 btn-sm px-4">-</button>
                    </div>
                    <div style="min-height: 50px!important;">
                    <button v-if="assinando == false" @click="addCamposArray()" type="button" class="ponteiro text-white btn btn-primary rounded ml-2 mt-2 mb-2 btn-sm px-4">+</button>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <label for="chkAssinatura" class="mr-2">Assinatura Digital</label>
                        <input :disabled="assinando == true ? true : false" type="checkbox" v-model="assinaturaDigital" name="chkAssinatura" id="chkAssinatura" >
                        
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <label for="chkDoc" class="mr-2">Requer Documento</label>
                        <input v-model="requerDocumento" :disabled="assinando == true ? true : false" type="checkbox" name="chkDoc" id="" >
                    </div>        
                    </div>
                    <div style="min-height: 100px!important; display : flex; flex-direction: column;" class="d-flex justify-content-center mb-2">
                        <div v-if="assinando == true" class="d-flex justify-content-center mb-4" >
                            <a :href="urlQrCode" target="_blank">
                            <VueQRCodeComponent :text="urlQrCode" :size="100"></VueQRCodeComponent>
                            </a>
                            
                        </div>
                        <small v-if="assinando == true" class="mr-2 ml-2">Aponte a camera de seu celular para o QR-Code e acesse o ambiente de assinatura digital.</small>
                    </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnCloseModal">{{$t('sols.fechar')}}</button>
                    <button @click="imprimir()" type="button" class="btn btn-primary">{{$t('sols.impressao')}}</button>
                </div>
                </div>
            </div>
        </div>

        <ModalESImagem
            :valor="exibirModalImagem"
            :imgTipo="imgTipo"
            :imgSrc="imgSrc"
            @fechar="exibirModalImagem=false"
        ></ModalESImagem>

    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import moment from 'moment'
import SolicitacaoHistorico from './SolicitacaoHistorico.vue'
import SolicitacaoProdutos from './SolicitacaoProdutos.vue'
//import QrcodeVue from 'qrcode.vue'
import VueQRCodeComponent from 'vue-qrcode-component'
import SolicitacaoCheckList from './SolicitacaoCheckList.vue'
import SolicitacaoProdutosEditar from './SolicitacaoProdutosEditar.vue'
import ChamadoHistorico from './ChamadoHistorico.vue'
import ModalESImagem from './ModalESImagem.vue'

export default {
    props: {
    },
    components: {SolicitacaoHistorico, SolicitacaoProdutos, SolicitacaoProdutosEditar
        , SolicitacaoCheckList , VueQRCodeComponent
        , ChamadoHistorico, ModalESImagem
    },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            assinaturaDigital : true,
            requerDocumento : false,
            assinando : false,
            urlQrCode : 'https://uol.com.br',
            solicitacao: {},
            setores: [],
            produtos : [],
            produtosSolicitacao : [],
            produtosTodosStr: [],
            showModalProdutos: false,
            idusuariotipo: this.$store.getters.GetLoginInfo.idusuariotipo,
            campos : [],
            produtosBackEnd : [{idproduto : 1, descricao : 'Produto Melhor 1'}, {idproduto : 2, descricao : 'Produto Melhor 2'}, {idproduto : 3, descricao : 'Produto Melhor 3'}],
            tipossolicitacao: [],
            statusList: [
                {id: 1, descricao: this.$t('sols.pendente')},
                {id: 2, descricao: this.$t('sols.ematendimento')},
                {id: 3, descricao: this.$t('sols.atendida')},
                {id: 4, descricao: this.$t('sols.naoatendida')},
                {id: 5, descricao: this.$t('sols.contestada')},
                {id: 6, descricao: this.$t('sols.cancelada')},
            ],
            // status: {
            //     "1" : this.$t('sols.pendente'),
            //     "2" : this.$t('sols.ematendimento'),
            //     "3" : this.$t('sols.atendida'),
            //     "4" : this.$t('sols.naoatendida'),
            //     "5" : this.$t('sols.contestada'),
            //     "6" : this.$t('sols.cancelada')
            // },
            itemCorrente: null,
            idhotlinesolicitacoes: 0,
            loading: true,
            textoconclusao: this.$t('sols.suasolicitacaofoiatendida'),
            textocancelamento: '',
            textoatendimento: this.$t('sols.seraatendidamediantedisponibilidade'),
            toggleText: 'Exibir histórico',
            togglePedidoText: 'Exibir pedido',
            exibirHistorico: false,
            exibirChecklist: false,
            toggleChecklistText: 'Exibir Checklist',
            exibirPedido: false,
            atendido: '1',
            atendidoText: this.$t('sols.suasolicitacaofoiatendida'),
            imagemTipo: null,
            imagemSrc: null,
            prioridades: this.$t('sols.prioridades'),
            responsaveis: [],
            locaisAtendimento: [],
            fornecedores: [],
            exibirAssinaturas: false,
            toggleAssinaturasText: 'Exibir Histórico de Assinaturas',
            exibirModalImagem: false,
            imgSrc: null,
            imgTipo: null
        }
    },
    watch: {
        atendido(val) {
            if (val == '1') {
                this.atendidoText = this.$t('sols.suasolicitacaofoiatendida')
            } else {
                this.atendidoText = this.$t('sols.suasolicitacaonaofoiatendida')
            }
            this.textoconclusao = this.atendidoText
        }
    },
    methods: {
        async exibirImagem(img,id) {

      var params = {
        idhotlinesolicitacoes: this.solicitacao.idhotlinesolicitacoes,
        idhotlinesolicitacoesassinatura: id
      };

      await axios.post(
          CONSTANTS.API_URL + "/fdguest/hotlinesolicitacoes/assinatura/listardadosassinar",
          params, { headers: this.headers }
        )
        .then((response) => {
          //alert(response.status)
          if (response.status == 200) {
            let dados = response.data[0];
            if (img === 'ass') {
                this.imgTipo = 'data:image/png;base64,';
                this.imgSrc = dados.imgassinatura;
            } else {
                this.imgTipo = ''
                this.imgSrc = dados.imgdocumento;
            }
            this.exibirModalImagem = true;
          }
        });
    },
        getObjById(id, idProp) {
            var elementPos = this.produtosSolicitacao.map(function(x) {return x[idProp]; }).indexOf(id);
            var objectFound = this.produtosSolicitacao[elementPos];
            return objectFound;
        },
        addProdArray(){
            let lastId = 1
            if (this.produtosSolicitacao.length > 0) {
                lastId =this.produtosSolicitacao[this.produtosSolicitacao.length-1].idhotlinesolicitacoesitens;
            }
            let nextId = 0;
            if (lastId <= 0)  nextId = lastId - 1;
            this.produtosSolicitacao.push({
                idhotlinesolicitacoesitens : nextId,
                idhotlinesolicitacoes : this.solicitacao.idhotlinesolicitacoes,
                idproduto : 0, 
                codprodutopms: '',
                descproduto: '',
                qtdesolicitada : 1, 
                qtdeliberada : 0, 
                qtdeentregue : 0,
                quantidade: 0,
                unidade: 'UN',
                comentario : '', 
                status: 'P'
            });
            this.$forceUpdate();
        },
        excluirItem(p,index) {
            if (p.idhotlinesolicitacoesitens > 0) {
                let params = {idhotlinesolicitacoesitens: p.idhotlinesolicitacoesitens};
                //console.error(JSON.stringify(params));
                axios.post(`${CONSTANTS.API_URL}/fdguest/hotlinesolicitacoes/produtos/delete`
                    , params, {headers: this.headers})
                .then((resp)=>{
                    if (resp&&resp.status == 200) {
                        if (index >= 0) {
                            this.produtosSolicitacao.splice(index, 1); // remover 1 item a partir de index
                        }
                    }
                })
            } else {
                if (index >= 0) {
                    this.produtosSolicitacao.splice(index, 1); // remover 1 item a partir de index
                }
            }
        },
        async salvarProdutos(produto){
            let params = {
                idpessoausuario: this.$store.getters.GetLoginInfo.idpessoa,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idpessoaresponsavel: this.solicitacao.idpessoaresponsavel,
                produtos: []
            }
            //console.error(`produto:${JSON.stringify(produto)}`);
            if(produto){
                params.produtos = [{
                    "idhotlinesolicitacoesitens": produto.idhotlinesolicitacoesitens,
                    "idhotlinesolicitacoes": produto.idhotlinesolicitacoes,
                    "idproduto": produto.idproduto,
                    "qtdesolicitada": produto.qtdesolicitada,
                    "qtdeliberada": produto.qtdeliberada,
                    "qtdeentregue": produto.qtdeentregue,
                    "comentario": produto.comentario,
                    "status": produto.status
                }]
            }else{
                params.produtos = this.produtosSolicitacao.filter((item) => {
                    return item.idproduto > 0;
                });
            }

            //console.error(`params:${JSON.stringify(params)}`);
            axios.post(`${CONSTANTS.API_URL}/fdguest/hotlinesolicitacoes/produtos/upinsert`
                , params, {headers: this.headers})
            .then((resp)=>{
                if(resp&&resp.status == 200){
                    this.$snack(this.$t(`global.salvocomsucesso`));
                    let id_sol = produto?produto.idhotlinesolicitacoes:this.produtosSolicitacao[0].idhotlinesolicitacoes;
                    let id_produto = produto?produto.idproduto:null;
                    if (produto) {
                        this.buscarProdutosSolicitacao(id_sol,id_produto);
                    } else {
                        setTimeout(() => {  
                            this.showModalProdutos=false;
                        }, 2000);
                    }
                } else {
                    if (resp.status == 406) {
                        this.$snackE(`Salve o produto sem quantidades liberada e entregue!`);
                    }
                }
            }).catch(error => {
                //console.log(error)
                this.$snackE(JSON.stringify(error.response));
            })
        },
        setProduto(p,index) {
            let obj = this.produtosTodos[index];
            // Se já existe o produto alerta...
            if (this.produtosSolicitacao.map((x) => x.idproduto).indexOf(obj.idproduto) >= 0) {
                this.$snackW(this.$t('sols.esteprodutojaexiste'))
            } else {
                // Senão, inserir...
                p.idhotlinesolicitacoesitens = 0;
                p.idproduto = obj.idproduto;
                p.codprodutopms = obj.codprodutopms;
                p.descproduto = obj.descproduto;
                p.idhotlinesolicitacoes = this.solicitacao.idhotlinesolicitacoes;
                p.quantidade = obj.quantidade;
                p.idusuario = this.$store.getters.GetLoginInfo.idusuario;
            }
        },
        salvarEstoque(item,itemSol) {
            var params = {
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                idproduto: item.idproduto,
                unidade: item.unidade,
                quantidade: item.quantidade,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/estoque/produto/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.$snack(this.$t(`global.salvocomsucesso`));
                    this.fecharEstoque(item);
                    itemSol.unidade = item.unidade;
                    itemSol.quantidade = item.quantidade;
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        async imprimir(){
            if(this.assinaturaDigital == true){
                let params = {
                    idhotlinesolicitacoes : this.idhotlinesolicitacoes,
                    obrigaimgdocumento : this.requerDocumento == false ? false : true,
                    obrigaassinatura : this.assinaturaDigital == false ? false : true,
                    camposadicionais : {campos : this.campos},
                    criado_por: this.$store.getters.GetLoginInfo.idusuario
                } 
                await axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/assinatura/solicitar',
                    params, { headers:this.headers}
                ).then(response =>{
                 if(response.status == 200){
                    this.assinando = true
                    this.urlQrCode = `${window.location}/assinatura?sol=${this.idhotlinesolicitacoes}&u=${this.$store.getters.GetLoginInfo.idusuario}&k=${response.data[0].idhotlinesolicitacoesassinatura}&t=${response.data[0].token}`
                    this.connectSocket(this.idhotlinesolicitacoes, response.data[0].idhotlinesolicitacoesassinatura, response.data[0].token)
                 }else{
                    console.log(response)
                 }
                })
            }else{

                let params = {
                    idhotlinesolicitacoes : this.idhotlinesolicitacoes,
                    obrigaimgdocumento : false,
                    obrigaassinatura : false,
                    camposadicionais : {campos : this.campos},
                    criado_por: this.$store.getters.GetLoginInfo.idusuario
                } 

                await axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/assinatura/solicitar',
                    params, { headers:this.headers}
                ).then(response =>{
                 if(response.status == 200){
                    this.assinando = false
                    this.urlQrCode = window.location+'/pdf?sol='+this.idhotlinesolicitacoes+'&k='+response.data[0].idhotlinesolicitacoesassinatura+'&t='+response.data[0].token
                    //console.log(response.data)
                    window.open(this.urlQrCode, '_blank');
                    window.location.reload()
                 }else{
                    
                    console.log(response)
                 }
                })


                //window.open(this.urlQrCode, '_blank');
            }

            //alert(this.urlQrCode)
        },
        connectSocket: function(sol, key, token) {
            this.$socket.disconnect();
            
            this.$socket.io.opts.query.key = key+'A'
            
            this.$socket.connect();

            
            var _self = window

            this.sockets.subscribe("SOLASSINADA", () => {
                document.getElementById('btnCloseModal').click()
                this.camposadicionais = []
                this.urlQrCode = ''
                this.assinando = false
                let url = `${_self.location.href}/pdf?sol=${sol}&k=${key}&t=${token}`
                console.error(`Recebeu evento de SOLASSINADA...url:${url}`)
                //_self.location.href = url;
                _self.open(url, '_blank')
                //_self.location.reload()
            });

        },        
        loadFornecedores(){
			axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/select', {
				idioma: this.$i18n.locale,
                manutencao: true
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.fornecedores = response.data;
						}
					} else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        carregarLocaisAtendimento() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/localatendimento/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.locaisAtendimento = response.data.dados
                }
            })
        },        
        loadResponsaveis() {
            var searchParams = {
                idioma: this.$i18n.locale,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
				page: 1,
                pagesize: 1000,
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/usuarios/tecnicos', searchParams
                , { headers: this.headers })
            .then(response => {
                try {
                    if (response.status == 200) {
                        if (response.data.dados) {
                            this.responsaveis = response.data.dados;
                        }
                    } else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
                }
                catch(e) {
                    console.log(e);
                }
            }).catch((err) => {
                console.log({ err_menu: err });
            });
        },
        async salvarComentario(){
            var params = {
                "idhotlinesolicitacoes" : this.idhotlinesolicitacoes,
                "texto" : 'Requisição de Produtos/Material',
                "de" : this.$store.getters.GetLoginInfo.idusuario
            }
            await axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/comment', params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    setTimeout(() => {  document.getElementById('btnCloseModal').click(); }, 2000);
                    
                } else {
                    console.log('Response: ' + JSON.stringify(response));
                }
            })
        },
        async buscarProdutosSolicitacao(idhotlinesolicitacoes,idproduto=null){
            
            let params = {
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                idhotlinesolicitacoes : idhotlinesolicitacoes
            }
            let arrayIndex = -1;
            if (idproduto) {
                params.idproduto = idproduto;
                arrayIndex = this.produtosSolicitacao.findIndex(x => x.idproduto === idproduto);
            } else {
                this.produtosSolicitacao = [];
            }

            await axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/produtos/select', params, {headers: this.headers}).then(resp =>{
                if(resp.status == 200){
                    if (idproduto && arrayIndex>=0) {
                        this.produtosSolicitacao[arrayIndex] = {
                            ...resp.data[0], 
                            validEntregue: true,
                            validLiberada: true
                        }
                    } else {
                        this.produtosSolicitacao = resp.data.map((x)=>{
                            return {
                                ...x,
                                validEntregue: true,
                                validLiberada: true
                            }
                        })
                    }
                    this.$forceUpdate();
                }
            }).catch(error=> {
                console.log(error)
            })
        },
        selecionaIdProduto(posicao, idProduto){
            this.produtos[posicao].id = idProduto
            alert('Posicao :' + posicao + ' e idProduto: ' + idProduto)

        },
        addCamposArray(){
            if(this.campos.length > 4){
                return
            }else{
                this.campos.push({id : this.campos.length + 1, valor : '', texto : ''})
            }
            
        },
        delProdArray(posicao){
            if(this.produtos.length > 0){
                this.produtos.splice(posicao, 1)
            }
        },
        delCamposArray(posicao){
            if(this.campos.length > 0){
                this.campos.splice(posicao, 1)
            }else{
                return
            }
            
        },        
        async loadProdutos() {
            // Get object by 'id' from array of objects:
            // var elementPos = array.map(function(x) {return x.id; }).indexOf(idYourAreLookingFor);
            // var objectFound = array[elementPos];
            await axios.post(CONSTANTS.API_URL + '/fdguest/produto/select',{
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                orderby: 'lower(noaccent(trim(trim(both \'"\' from descproduto))))'
            }, {headers: this.headers}).then(resp =>{
                if(resp.status == 200){
                    this.produtosTodos = resp.data
                    this.produtosTodosStr = this.produtosTodos.map((e)=>{
                        return e.descproduto
                    })
                }else{
                    console.error(`resp.status:${resp.status}`)
                }
            }).catch(error =>{
                console.log(error)
            })
        },
        formatarData(aDate) {
            return moment(aDate).locale(this.$i18n.locale).format('lll');
        },
        togglePedido() {
            var el = document.getElementById('pedido-solicitacao');
            if (el) {
                if (el.style.display == 'none' || el.style.display == '') {
                    el.style.display = 'flex'
                    this.togglePedidoText = 'Ocultar pedido'
                } else {
                    el.style.display = 'none'
                    this.togglePedidoText = 'Exibir pedido'
                }
            }
        },
        toggle() {
            var el = document.getElementById('historico-solicitacao');
            if (el) {
                if (el.style.display == 'none' || el.style.display == '') {
                    el.style.display = 'flex'
                    this.toggleText = 'Ocultar histórico'
                } else {
                    el.style.display = 'none'
                    this.toggleText = 'Exibir histórico'
                }
            }
        },
        toggleChecklist() {
            var el = document.getElementById('checklist-solicitacao');
            if (el) {
                if (el.style.display == 'none' || el.style.display == '') {
                    el.style.display = 'flex'
                    this.toggleChecklistText = 'Ocultar Checklist'
                } else {
                    el.style.display = 'none'
                    this.toggleChecklistText = 'Exibir Checklist'
                }
            }
        },
        toggleAssinaturas() {
            var el = document.getElementById('historico-chamado');
            if (el) {
                if (el.style.display == 'none' || el.style.display == '') {
                    el.style.display = 'flex'
                    this.toggleAssinaturasText = 'Ocultar Histórico de Assinaturas'
                } else {
                    el.style.display = 'none'
                    this.toggleAssinaturasText = 'Exibir Histórico de Assinaturas'
                }
            }
        },
        voltar() {
            window.history.back();
        },
        async salvarEdicao(item) {
            var params = {
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idhotlinesolicitacoes: item.idhotlinesolicitacoes,
                idprioridade: item.idprioridade,
                idresponsavel: item.idresponsavel,
                idlocalatendimento: item.idlocalatendimento,
                manutencaoexterna: item.manutencaoexterna,
                fornecedor: item.fornecedor,
                garantiaemdias: item.garantiaemdias,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            //console.log(JSON.stringify(params))
            await axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update', params, 
                {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.hideTexts(item.idhotlinesolicitacoes);
                    this.loadSolicitacao();
                }
            })
            .catch((e) => {
                console.log(JSON.stringify(e));
            })
        },
        async mudarStatusPendente(item) {
            //console.log('mudarStatusPendente(item)')
            var datahora = moment().format()
            // Por default, se entrega o que foi prometido...
            if (item.produtos) {
                for (var i = 0; i < item.produtos.length; i++) {
                    item.produtos[i]['entregue'] = item.produtos[i]['atender']
                }
            }
            var params = {
                idhotlinesolicitacoes: item.idhotlinesolicitacoes,
                datavisualizacao: datahora,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                textoatendimento: this.textoatendimento,
                idstatus: 2,
                produtos: item.produtos,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            //console.log(JSON.stringify(params))
            await axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update', params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    // Muda o item para "Em atendimento"
                    item.idstatus = 2
                    item.status = this.statusList[1].descricao
                } else {
                    if (response.status == 207) {
                        alert(response.data.message);
                        window.location.reload();
                    }
                    console.log('Response: ' + JSON.stringify(response));
                }
            })
            .catch((e) => {
                console.log(JSON.stringify(e));
            })
            this.hideTexts(item.idhotlinesolicitacoes);
        },
        async cancelarSolicitacao(item) {
            var datahora = moment().format()
            let params = {
                idhotlinesolicitacoes: item.idhotlinesolicitacoes,
                datacancelamento: datahora,
                textocancelamento: this.textocancelamento,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idstatus: 6,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            await axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update', params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    var obj = document.getElementById('Cancel_'+item.idhotlinesolicitacoes);
                    obj.style.display ='none';
                    // Muda o item para "Cancelada"
                    item.idstatus = 6
                    item.status = this.statusList[5].descricao
                    item.textocancelamento = this.textocancelamento
                } else {
                    if (response.status == 207) {
                        alert(response.data.message);
                        window.location.reload();
                    }
                    console.log('Response: ' + JSON.stringify(response));
                }
            })
        },
        async registrarAtendimento(item) {
            var params = {
                idhotlinesolicitacoes: item.idhotlinesolicitacoes,
                dataatendimento: moment().format(),
                textoatendimento: this.textoconclusao,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idhotlinesetores: item.idhotlinesetores,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idstatus:  this.atendido == '1' ? 3 : 4,
                produtos: item.produtos,
                checklist: item.checklist,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            //console.log(JSON.stringify(params))
            await axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update', params
                , {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    //var obj = document.getElementById('At_'+item.idhotlinesolicitacoes);
                    //obj.style.display ='none';
                    item.idstatus = this.atendido == '1' ? 3 : 4
                    item.status = this.statusList[(this.atendido == '1' ? 3 : 4)-1].descricao
                    this.hideTexts(item.idhotlinesolicitacoes)
                } else {
                    if (response.status == 207) {
                        alert(response.data.message);
                        window.location.reload();
                    }
                    console.log('Response: ' + JSON.stringify(response));
                }
            })
        },
        hideTexts(id) {
            var obj = document.getElementById('At_'+id);
            if (obj) obj.style.display='none'
            obj = document.getElementById('At_Produtos_'+id);
            if (obj) obj.style.display='none'
            obj = document.getElementById('Em_At_Produtos_'+id);
            if (obj) obj.style.display='none'
            obj = document.getElementById('Em_At_'+id);
            if (obj) obj.style.display='none'
            obj = document.getElementById('Editar_'+id);
            if (obj) obj.style.display='none'
            obj = document.getElementById('Cancel_'+id);
            if (obj) obj.style.display='none'
        },
        showModal(item) {
            var obj = document.getElementById('At_'+item.idhotlinesolicitacoes);
            this.hideTexts(item.idhotlinesolicitacoes)
            obj.style.display ='flex'
            if (this.solicitacao.tipoitem == 6 && this.solicitacao.produtos.length>0) {
                obj = document.getElementById('At_Produtos_'+item.idhotlinesolicitacoes);
                obj.style.display ='flex'
            }
            obj = document.getElementById('At_Text_'+item.idhotlinesolicitacoes);
            obj.focus()
        },
        showEmAtendimento(item){
            var obj = document.getElementById('Em_At_'+item.idhotlinesolicitacoes);
            this.hideTexts(item.idhotlinesolicitacoes)
            obj.style.display ='flex'
            if (this.solicitacao.tipoitem == 6 && this.solicitacao.produtos.length>0) {
                obj = document.getElementById('Em_At_Produtos_'+item.idhotlinesolicitacoes);
                obj.style.display ='flex'
            }
            obj = document.getElementById('Em_At_Text_'+item.idhotlinesolicitacoes);
            obj.focus()
        },
        showEdit(item) {
            this.hideTexts(item.idhotlinesolicitacoes)
            var obj = document.getElementById('Editar_'+item.idhotlinesolicitacoes)
            if(obj) {
                obj.style.display = 'flex'
                let options = {
                            behavior: 'auto',
                            block: 'center',
                            inline: 'center'
                        }
                obj.scrollIntoView(options);
                this.$forceUpdate();
            }
        },
        showCancel(item) {
            var obj = document.getElementById('Cancel_'+item.idhotlinesolicitacoes);
            this.hideTexts(item.idhotlinesolicitacoes)
            obj.style.display ='flex'
            obj = document.getElementById('CancelText_'+item.idhotlinesolicitacoes);
            obj.focus()
        },
		carregarAssinatura(id) {
            var  params = {
                id: id
            }
            //console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select',
                    params, { headers:this.headers})
            .then(response => {
                if(response.status == 200) {
                    if (response.data.length > 0) {
                        var imgObj = response.data[0]
                        this.imagemSrc = Buffer.from(imgObj.dados).toString()
                        this.imagemTipo = imgObj.tipo
                        this.loading = false
                    } else {
                        //console.log(JSON.stringify(response))
                    }
                } else {
                    console.log(JSON.stringify(response))
                }
            })

		},
        loadSolicitacao() {
            var  params = {
                idioma: this.$i18n.locale,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                usuario: this.$store.getters.GetLoginInfo.idusuario,
                idhotlinesolicitacoes: this.idhotlinesolicitacoes
            }
            // console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/select',
                    params, { headers:this.headers})
            .then(response => {
                if(response.status == 200) {
                    if (response.data.dados.length > 0) {
                        this.solicitacao = response.data.dados[0]
                        this.carregarAssinatura(this.solicitacao.assinatura)
                        this.loading = false
                        this.buscarProdutosSolicitacao(this.idhotlinesolicitacoes)
                    } else {
                        console.log('Solicitação inválida:'+this.idhotlinesolicitacoes)
                    }
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        programadoPara(item) {
            var result = ''
            var dias = parseInt(item.intervalo)
            if (dias > 0) {
                if (dias == 1) {
                    // singular
                    result += `${dias.toString()} ${this.$t('sols.diaaposa')} `
                } else {
                    // plural
                    result += `${dias.toString()} ${this.$t('sols.diasaposa')} `
                }
            } else {
                if (dias < 0) {
                    if (Math.abs(dias) == 1) {
                        // singular
                        result += `${Math.abs(dias).toString()} ${this.$t('sols.diaantesda')} `
                    } else {
                        // plural
                        result += `${Math.abs(dias).toString()} ${this.$t('sols.diasantesda')} `
                    }
                }
            }
            result += this.$t('sols.'+item.datareferida)
            return result
        }
    },
    computed: {
        temAcesso() {
            return (this.$store.getters.GetLoginInfo.idusuariotipo == '1' || this.$store.getters.GetLoginInfo.idusuariotipo == '5')
        },
        allChecked() {
            let result = true;
            if (this.solicitacao.checklist 
                && Array.isArray(this.solicitacao.checklist) && this.solicitacao.checklist.length > 0) {
                for (var i = 0; i < this.solicitacao.checklist.length; i++) {
                    if (!this.solicitacao.checklist[i].checked) {
                        result = false;
                        break;
                    }
                }
            }
            return result;
        },
        descPrioridade() {
            let result = ''
			for (var i = 0; i < this.prioridades.length; i++) {
                if (this.prioridades[i].idprioridade == this.solicitacao.idprioridade) {
                    result = this.prioridades[i].descricao;
                }
            }
            return result;
		}

    },
    beforeMount() {
        this.itemCorrente = this.$store.getters.GetCurrentItem;
        this.idhotlinesolicitacoes = this.$store.getters.GetSolAtual;
        console.error(`beforeMount()`)
    },
    mounted() {
        console.error(`mounted()`)
        this.loadResponsaveis();
        this.carregarLocaisAtendimento();
        this.loadFornecedores();
        this.loadProdutos();
        this.loadSolicitacao();
        window.scrollTo(0, 0);
    },
    beforeDestroy(){
        //this.sockets.unsubscribe("SOLASSINADA");
        
        
        // console.error(`Fim do Tick notificação: ${new Date()}`);
    }
}
</script>

<style scoped>
.icone {
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    color: blue;
    /*font: italic small-caps bold 14px/30px Georgia, serif;*/
}
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1!important;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  opacity: 0.5;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.es-selected {
    box-shadow: 0px 5px 5px, 0px -5px 5px, -5px 0px 5px, 5px 0px 5px;
    margin-left: 10px;
}
.es-not-selected {
    box-shadow: 0px 0px;
    opacity: 0.7;
    margin-left: 10px;
}
.es-link {
    color: white!important;
    background-color: rgb(41, 84, 207);
}
</style>