import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'pt-BR': {
        global: {
            carregando: 'Carregando',
            idioma: 'Idioma',
            icone: 'Ícone',
            salvar: 'Salvar',
            salvocomsucesso: 'Salvo com sucesso!',
            ajustar: 'Ajustar',
            apagar: 'Apagar',
            codigo: 'Código',
            fechar: 'Fechar',
            cadastrar: 'Cadastrar',
            ativar: 'Ativar',
            inativar: 'Inativar',
            ativo: 'Ativo',
            inativo: 'Inativo',
            editar: 'Editar',
            titulo: 'Título',
            subtitulo: 'Subtítulo',
            descricao: 'Descrição',
            texto: 'Texto',
            nome: 'Nome',
            pessoafisica: 'Pessoa Física',
            pessoajuridica: 'Pessoa Jurídica',
            todos: 'Todos',
            todas: 'Todas',
            historico: 'Histórico',
            operacao: 'Operação',
            dataehora: 'Data e Hora',
            por: 'Por',
            antigovalor: 'Valor Antigo',
            novovalor: 'Valor Novo',
            U: 'Atualizado',
            I: 'Inserido',
            D: 'Excluído',
            periodode: 'Período de',
            periodoate: 'até',
            filtrar: 'Filtrar',
            erro: 'Erro',
            categoria: 'Categoria',
            subcategoria: 'Subcategoria',
            item: 'Ítem',
            programacao: 'Programação',
            acao: 'Ação',
            status: 'Status',
            proximaexecucao: 'Próxima execução',
            ultimaexecucao: 'Última execução',
            upload: 'Upload',
            duplicar: 'Duplicar',
            voltar: 'Voltar',
            abrirchamado: 'Abrir Chamado',
            exibirchamados: 'Exibir Chamados',
            frequencia: 'Frequência',
            semchamados: 'Sem chamados.',
            atribuicoes: 'Atribuições',
            escala: 'Escala de Trabalho',
            escaladetrabalhoefolgas: 'Escala de Trabalho e Folgas',
            naoatribuido: 'Não atribuído',
            sim: 'Sim',
            nao: 'Não',
            nomehospede: 'Nome do Hóspede',
            ok: 'Ok',
            data: 'Data',
            intext: 'Int / Ext',
            tipo: 'Tipo',
            uh: 'UH',
            ambos: 'Ambos',
            global: 'Reserva',
            checkIn: 'Checkin',
            checkOut: 'Checkout',
            pax: 'Pax',
            guests: 'Hóspedes'
        },
        login: {
            acessarconta: 'Acessar Conta',
            usuario: 'Usuário',
            senha: 'Senha',
            lembrarsenha: 'Lembrar senha',
            esqueceusuasenha: 'Esqueceu sua senha?',
            acessar: 'Acessar',
            naotemconta: 'Não tem conta?',
            cadastrarconta: 'Cadastrar conta',
            verifique: 'O acesso ao sistema não foi autorizado. Verifique suas credenciais.'
        },
        dashboard: {
            bemvindoaopainelfdguest: 'Bem vindo ao Painel FD Guest',
            tenhacontroletotalsobreassolicitacoes: 'Tenha controle total sobre as solicitações dos seus hóspedes durante toda a sua estadia.',
            dia: 'Dia',
            semana: 'Semana',
            mes: 'Mês',
            quantidadesolicitacoes: 'Quantidade de Solicitações',
            atendimentosolicitacoes: 'Atendimento de Solicitações',
            ultimassolicitacoes: 'Últimas Solicitações',
            quarto: 'Quarto',
            vertodos: 'Ver todos',
            solicitadas: 'Solicitadas',
            atendidas: 'Atendidas'
        },
        perfil: {
            escolhaumidioma: 'Escolha um idioma',
            selecioneoidiomadepreferencia: 'Selecione o idioma de preferência'
        },
        sols: {
            chamados: 'Chamados',
            impressao : 'Imprimir',
            localatendimento: 'Local de Atendimento',
            responsavel: 'Responsável',
            responsavelinterno: 'Responsável Interno',
            prioridade: 'Prioridade',
            manutencaoexterna: 'Manutenção Externa',
            garantia: 'Garantia (em dias)',
            produtos : 'Produtos',
            impedimento : 'Impedimento',
            anexo: 'Anexo',
            motivo: 'Motivo do cancelamento',
            fechar: 'Fechar',
            historico: 'Histórico',
            comentario: 'Comentário',
            pedido: 'Pedido',
            filtro: 'Filtrar',
            setor: 'Setor',
            tipo: 'Tipo',
            local : 'Local',
            uh: 'UH',
            reserva: 'Reserva',
            solicitacao: 'Solicitação',
            solicitacoes: 'Solicitações',
            atendida: 'Atendida',
            naoatendida: 'Não atendida',
            contestada: 'Contestada',
            ematendimento: 'Em atendimento',
            pendente: 'Pendente',
            cancelada: 'Cancelada',
            atender: 'Atender',
            tratar: 'Tratar',
            cancelar: 'Cancelar',
            nome: 'Nome',
            texto: 'TEXTO',
            descricao : 'Descrição',
            data: 'Data',
            horario: 'HORÁRIO',
            quarto: 'QUARTO',
            status: 'Status',
            acoes : 'Ações',
            semsolstoalhabanho: 'Sem solicitações para toalha de banho.',
            semsolstoalharosto: 'Sem solicitações para toalha de rosto.',
            semsolsroupacama: 'Sem solicitações para roupa de cama.',
            semsolsarrumacao: 'Sem solicitações para arrumação de quarto.',
            semsolsamenities: 'Sem solicitações para amenities.',
            semsolscafe: 'Sem solicitações para café.',
            semsolsfrigobar: 'Sem solicitações para frigobar.',
            semsolsacordar: 'Sem solicitações para acordar no horário.',
            semsolslatecheckout: 'Sem solicitações para late checkout.',
            semsolsmensageiro: 'Sem solicitações para late checkout.',
            semsolsmanutencao: 'Sem solicitações para manutenção.',
            semsolsgerencia: 'Sem solicitações para a gerência.',
            semsolscompra: 'Sem solicitações de compras.',
            enviar: 'Enviar',
            escrevatextoacompanhamento: 'Escreva um texto para acompanhamento do hóspede',
            escrevaconclusaoatendimento: 'Escreva a conclusão do atendimento',
            escrevamotivocancelamento: 'Escreva o motivo do cancelamento',
            seraatendidamediantedisponibilidade: 'Estamos cientes de sua solicitação que será atendida mediante disponibilidade!',
            suasolicitacaofoiatendida: 'Sua solicitação foi atendida!',
            suasolicitacaonaofoiatendida: 'No momento não temos os recursos disponíveis para atender sua solicitação.',
            ultimaAlteracao: 'Última Alteração',
            prioridades: [
                { idprioridade: 1, descricao: "Baixa" },
                { idprioridade: 2, descricao: "Média" },
                { idprioridade: 3, descricao: "Alta" },
                { idprioridade: 4, descricao: "Crítica" }
            ],
            fornecedor: 'Fornecedor',
            esteprodutojaexiste: 'O produto escolhido já se encontra na lista.',
            verificarquantidades: 'A quantidade entregue deve ser menor ou igual a liberada que não deve ser maior que a quantidade disponível em estoque.',
            alternarselecaotip: 'Alternar seleção',
            atribuirresponsavel: 'Atribuir responsável',
            alertaresponsavel: 'O usuário selecionado será atribuído a todas os chamados selecionados. Confirme clicando em "Salvar" ou clique em "Fechar" para cancelar.',
            sematribuicao: '<sem atribuição>',
            concluirchamados: 'Concluir Chamados',
            atentimento: 'Entre com o texto de conclusão para o fechamento dos chamados',
            colaboradoressemacesso: 'Solicitações concluídas por colaboradores sem acesso ao sistema.',
            chamadosselecionados: 'Chamado(s) selecionado(s).',
            jaatendidas: 'Chamado(s) já fechado(s) como atendido(s).',
            janegadas: 'Chamado(s) já fechado(s) como não atendido(s).',
            jacanceladas: 'Chamado(s) já fechado(s) como cancelado(s).',
            chamadosseraoconcluidos: 'chamados serão concluídos.',
            apenaspendentes: 'Apenas chamados com status Pendente ou Em Atendimento serão concluídos.',
            selecionados: 'selecionados',
            chamadoematendimento: 'Chamado em atendimento.',
        },
        ser: {
            site: 'Site',
            email: 'Email',
            filtrar: 'Filtrar',
            pesquisa: 'Pesquisa',
            nomedaempresa: 'Nome da Empresa',
            setor: 'Setor',
            telefone: 'Telefone',
            bairro: 'Bairro',
            cidade: 'Cidade',
            estado: 'Estado',
            pesquisar: 'Pesquisar',
            cadastrar: 'Cadastrar',
            empresascadastradas: 'Empresas Cadastradas',
            empresa: 'Empresa',
            tipo: 'Tipo',
            whatsapp: 'Whatsapp',
            endereco: 'Endereço',
            status: 'Status',
            editar: 'Editar',
            desativar: 'Desativar',
            ativar: 'Ativar',
            nomedoservico: 'Nome do Serviço',
            valor: 'Valor',
            descricao: 'Descrição',
            ativo: 'Ativo',
            desativado: 'Desativado',
            servicoscadastrados: 'Serviços Cadastrados',
            servico: 'Serviço',
            desconto: 'Desconto',
            promocao: 'Promoção',
            nomedapromocao: 'Nome da Promoção',
            pais: 'Pais',
            cep: 'CEP',
            numero: 'Numero',
            complemento: 'Complemento',
            salvar: 'Salvar',
            voltar: 'Voltar',
            parceiro: 'Parceiro',
            parceiros: 'Parceiros',
            cadastrarpromocao: 'Cadastrar Promoção',
            cadastrarparceiro: 'Cadastrar Parceiro',
            subtitulo: 'Subtitulo',
            titulo: 'Título',
            datavalidade: 'Data de validade',
            preenchertitulosubtitulo: 'Preencha com um título e um subtítulo!'
        },
        avaliacao: {
            titulo: 'Programe suas Avaliações',
            descricao: 'Controle a programação de avaliações do hotel que serão enviadas automaticamente para os hóspedes.',
            ativo: 'Ativo',
            inativo: 'Inativo',
            datacheckin: 'data do checkin',
            datacheckoutprev: 'data do checkout',
            diaaposa: 'dia após a',
            diasaposa: 'dias após a',
            diaantesda: 'dia antes da',
            diasantesda: 'dias antes da'
        },
        categoriaPergunta: {
            titulo: 'Categorias de Perguntas',
            descricao: 'As categorias de perguntas permitem organizar as avaliações feitas pelo hóspede de forma útil e compreensiva.',
        },
        promocoeshotel: {
            titulo: 'Promoções do Hotel',
            descricao: 'As promoções do hotel podem ser cadastradas aqui para que o hóspede possa vê-las no aplicativo.',
        },
        relatorios: {
            titulo: 'Relatórios de Reviews',
            descricao: 'A visão do hóspede por ele mesmo pode ser analisada por diferentes prismas: índice geral, por avaliação, por categoria e análise de respostas individuais.',
            subtitulo1: 'Índice Geral',
            subtitulo1descricao: 'Nota média geral. Apresentada em níveis por Hotel, Avaliação e Categoria',
            subtitulo2: 'Índice Por Avaliação',
            subtitulo2descricao: 'Aqui é possível ver os resultados das pesquisas conforme as avaliações programadas.',
            subtitulo3: 'Índice Por Categoria',
            subtitulo3descricao: 'A Categoria da pergunta oferece um outro recorte para ver os índices por uma perspectiva geral.'
        },
        reviewsdashboard: {
            titulo: 'Reviews Dashboard',
            descricao: 'Escolha um período (de até um ano) para atualizar os gráficos',
            periodode: 'Período de',
            ate: 'Até',
            historicogeral: 'Histórico da Média Geral',
            divisaogeralnotas: 'Divisão Geral das Notas',
            historicomediaavaliacao: 'Histórico da Média por Avaliação',
            periodo: 'Período',
            periodoanterior: 'Período Anterior',
        },
        reviewsportal: {
            titulo: 'Portais de Reviews',
            descricao: 'Cadastre aqui os portais de reviews para que os hóspedes possam opinar sobre o hotel.',
            portaiscadastrados: 'Portais cadastrados',
        },
        tipoSolicitacao: {
            titulo: 'Programe suas Avaliações',
            descricao: 'Controle a programação de avaliações do hotel que serão enviadas automaticamente para os hóspedes.',
            ativo: 'Ativo',
            inativo: 'Inativo',
            datacheckin: 'data do checkin',
            datacheckoutprev: 'data do checkout',
            diaaposa: 'dia após a',
            diasaposa: 'dias após a',
            diaantesda: 'dia antes da',
            diasantesda: 'dias antes da',
            datadasolicitacao: 'Data da solicitação',
            datadoagendamento: 'Data do agendamento'
        },
        chatAPI: {
            titulo: 'Configuração da API de Chat',
            descricao: 'Configure aqui as informações da conta de API de Chat (Whatsapp) ou outras APIs compatíveis. A conta configurada será utilizada para envio de mensagens automáticas do sistema para o hóspede.',
            ativardesativarapichat: 'Ativar/Desativar API de chat'
        },
        contaEmail: {
            titulo: 'Configuração da conta de Email',
            descricao: 'Configure aqui as informações da conta de Email. A conta configurada será utilizada para envio de mensagens automáticas do sistema para o hóspede.',
            ativardesativarcontaemail: 'Ativar/Desativar Conta de Email',
            nametoexibition: 'Nome',
            host: 'Servidor',
            port: 'Porta',
            replyto: 'Responder a',
            user: 'Usuário',
            pass: 'Senha',
        },
        localAtendimento: {
            titulo: 'Locais de Atendimento',
            tituloLista: 'Locais de Atendimento Cadastrados',
            descricao: 'Os locais de atendimento são utilizados para definir em que local as solicitações internas estão sendo atendidas.',
            local: 'Local',
            status: 'Status',
            acao: 'Ação',
            descreva: 'Descreva o local'
        },
        vincularTecnicos: {
            categoriaseitens: 'Categorias e itens associados',
            nome: 'Nome',
            nomeusuario: 'Nome Usuário',
            email: 'Email',
            perfil: 'Perfil',
        },
        estoque: {
            historico: 'Histórico do produto no estoque',
            titulo: 'Estoque',
            tituloLista: 'Produtos em Estoque',
            descricao: 'Os produtos do estoque da manutenção.',
            unidade: 'Und.',
            quantidade: 'Qtde.',
            categoria: 'Categoria',
            produto: 'Produto',
            status: 'Status',
            acao: 'Ação',
            descreva: 'Descreva o estoque',
            pesquisar: 'Pesquisar',
            limiteminimo: 'Limite Mínimo',
            limitemaximo: 'Limite Máximo',
        },
        estoquerelatorios: {
            relatorio: 'Relatório',
            titulo: 'Relatórios da Manutenção',
            descricao: 'Diversos relatórios da manutenção.',
            opcao: 'Opção',
            chamado: 'Chamado'
        },
        upload: {
            titulo: 'Upload de Arquivos ".csv"',
            subtitulo: 'Upload de Arquivos ".csv" em formato predefinido para cadastro de categorias e produtos no estoque.',
            escolher: 'Escolher Arquivo',
            importando: 'Importando...'
        },
        chamadoProgramado: {
            titulo: 'Chamados Programados ou Periódicos',
            chamado: 'Chamado',
            descricao: 'Esta funcionalidade permite programar a geração automática de chamados da manutenção para chamados recorrentes ou programados para acontecerem em uma determinada data.',
            descricaoupload: 'Para incluir vários registros a partir de uma planilha clique em Upload ao lado. Para baixar o modelo de planilha clique em Baixar Modelo.',
            tituloupload: 'Upload de Arquivos ".csv"',
            subtituloupload: 'Upload de Arquivos ".csv" em formato predefinido para cadastro de chamados programados.',
            tituloLista: 'Chamados Programados Cadastrados',
            programacao: 'Programação',
            em: 'Em',
            todosegundox: 'Todo segundo',
            acadaxsegundos: 'A cada',
            todominutox: 'Todo minuto',
            acadaxminutos: 'A cada',
            todahorax: 'Toda hora',
            acadaxhoras: 'A cada',
            tododiaxdomes: 'Todo dia x do mês',
            acadaxdias: 'A cada',
            todomesx: 'Todo mês',
            acadaxmeses: 'A cada',
            tododiaxdasemana: 'Todo dia x da semana',
            acadaxdomingos: 'A cada',
            dias: ['domingo','segunda-feira','terça-feira','quarta-feira','quinta-feira','sexta-feira','sábado'],
            meses: ['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'],
            horadodiaouacadaxhoras: 'Hora do dia ou a cada x horas',
            segundoxouacadaxsegundos: 'Segundo x ou a cada x segundos',
            minutoxouacadaxminutos: 'Minuto x ou a cada x minutos',
            diaxdecadamesouacadaxdias: 'Dia x de cada mês ou a cada x dias',
            mesxouacadaxmeses: 'Mês x ou a cada x meses',
            diaxdasemana: 'Dia x da semana',
            checklist: 'Check List',
            adicionarchecklist: 'Adicionar Check List',
            equipamentos: 'Equipamentos',
            adicionarequipamento: 'Adicionar Equipamento',
            ideq: 'id',
            desceq: 'Descrição',
            marca: 'Marca',
            modelo: 'Modelo',
            serie: 'Série',
            tombamento: 'Tombamento',
        },
        logs: {
            tabela: 'Tabela',
            nomeusuario: 'Usuário',
            filtro: 'Filtrar',
            tipo_operacao: 'Operação',
            titulo: 'Logs de Tabelas do FDGuest',
            modificacao: 'Modificação',
            usuario: 'Usuário',
            antes: 'Antes',
            depois: 'Depois',
        },
        equipamento: {
            titulo: 'Service Locations',
            tituloLista: 'Equipamentos (ativo imobilizado)',
            descricao: 'Equipamento',
            marca: 'Marca',
            modelo: 'Modelo',
            serie: 'Série',
            tombamento: 'Tombamento',
            localdeuso: 'Local de Uso',
            status: 'Status',
            acao: 'Ação',
            compra: 'Compra',
            dadoscompra: 'Dados da Compra',
            nfcompra: 'Nota Fiscal da Compra',
            datacompra: 'Data da Compra',
            valorcompra: 'Valor da Compra',
            fornecedor: 'Fornecedor',
            garantiaemdias: 'Garantia (em dias)',
            garantidoate: 'Garantido Até',
            controle: 'Controle de Equipamentos',
            protocolo: 'Protocolo para Saída e Entrada de Equipamentos'
        },
        assinatura: {
            titulo: 'Histórico de Assinaturas',
            dataevento: 'Data do Evento',
            assinatura: 'Assinatura',
            documento: 'Documento',
            camposadicionais: 'Campos Adicionais',
            status: 'Status',
            semassinatura: '<sem assinatura>',
            semdocumento: '<sem documento>',
            pdf: 'PDF'
        },
        uh: {
            titulo: 'Camareira - UHs',
            room: 'Quarto',
            roomtype: 'Tipo',
            hkstatus: 'Status Gov.',
            floor: 'Andar',
            occ: 'Ocupado',
            outoforder: 'Interdição',
            acao: 'Ação',
            inspecionar: 'Inspecionar',
            marcarlimpo: 'Marcar como limpo',
            marcarsujo: 'Marcar como sujo',
            limpo: 'Limpo',
            emlimpeza: 'Em limpeza',
            sujo: 'Sujo',
            naoperturbe: 'Definir ocorrência "Não Perturbe"',
            dormiufora: 'Definir ocorrência "Dormiu Fora"',
            dispensouservico: 'Definir ocorrência "Dispensou Serviço"',
            nenhumaocorrencia: 'Remover ocorrências',
            ocorrencia: 'Ocorrência'
        }
    },
    'en': {
        global: {
            carregando: 'Loading',
            idioma: 'Language',
            icone: 'Icon',
            salvar: 'Save',
            salvocomsucesso: 'Saved successfully!',
            ajustar: 'Ajust',
            apagar: 'Delete',
            codigo: 'Code',
            fechar: 'Close',
            cadastrar: 'Register',
            ativar: 'Activate',
            inativar: 'Inativate',
            ativo: 'Active',
            inativo: 'Inactive',
            editar: 'Edit',
            titulo: 'Title',
            subtitulo: 'Subtitle',
            descricao: 'Description',
            texto: 'Text',
            nome: 'Name',
            pessoafisica: 'Fisical Person',
            pessoajuridica: 'Legal Person',
            todos: 'All',
            todas: 'All',
            historico: 'History',
            operacao: 'Operation',
            dataehora: 'Date and Time',
            por: 'By',
            antigovalor: 'Old Value',
            novovalor: 'New Value',
            U: 'Updated',
            I: 'Inserted',
            D: 'Deleted',
            periodode: 'Period from',
            periodoate: 'to',
            filtrar: 'Filter',
            erro: 'Error',
            categoria: 'Category',
            subcategoria: 'Subcategory',
            item: 'Item',
            programacao: 'Schedule',
            acao: 'Action',
            status: 'Status',
            proximaexecucao: 'Next run',
            ultimaexecucao: 'Last run',
            upload: 'Upload',
            duplicar: 'Duplicate',
            voltar: 'Back',
            abrirchamado: 'Open Request',
            exibirchamados: 'Show Requests',
            frequencia: 'Frequency',
            semchamados: 'No Requests.',
            atribuicoes: 'Assignments',
            escala: 'Work Schedule',
            escaladetrabalhoefolgas: 'Work Schedule and Days Off',
            naoatribuido: 'Not defined'
        },
        login: {
            acessarconta: 'Access Account',
            usuario: 'User',
            senha: 'Password',
            lembrarsenha: 'Remember password',
            esqueceusuasenha: 'Forgot your password?',
            acessar: 'Access',
            naotemconta: 'Don\'t have an account?',
            cadastrarconta: 'Register Account',
            verifique: 'Access to the system has not been authorized. Check your credentials.'
        },
        dashboard: {
            bemvindoaopainelfdguest: 'Welcome to the FD Guest Panel',
            tenhacontroletotalsobreassolicitacoes: 'Have full control over your guests\' requests throughout their stay.',
            dia: 'Day',
            semana: 'Week',
            mes: 'Month',
            quantidadesolicitacoes: 'Number of Requests',
            atendimentosolicitacoes: 'Service of Requests',
            ultimassolicitacoes: 'Latest Requests',
            quarto: 'Room',
            vertodos: 'See all',
            solicitadas: 'Requested',
            atendidas: 'Answered'
        },
        perfil: {
            escolhaumidioma: 'Choose one language',
            selecioneoidiomadepreferencia: 'Select the preferred language'
        },
        sols: {
            chamados: 'Internal Calls',
            impressao : 'Print',
            localatendimento: 'Service Location',
            responsavel: 'Responsible',
            prioridade: 'Priority',
            manutencaoexterna: 'External Mantainance',
            garantia: 'Warranty (in days)',
            produtos : 'Products',
            impedimento : 'Impediments',
            anexo: 'Attach',
            motivo: 'Reason for cancellation',
            fechar: 'Close',
            historico: 'History',
            comentario: 'Comment',
            pedido: 'Order',
            filtro: 'Filter',
            setor: 'Department',
            tipo: 'Type',
            local : 'Place',
            uh: 'Room #',
            reserva: 'Reservation #',
            solicitacao: 'Service Request',
            solicitacoes: 'Service Requests',
            atendida: 'Answered',
            naoatendida: 'Not answered',
            contestada: 'Contested',
            ematendimento: 'In progress',
            pendente: 'Pending',
            cancelada: 'Canceled',
            atender: 'Work out',
            tratar: 'Treat',
            cancelar: 'Cancel',
            nome: 'NAME',
            texto: 'TEXT',
            descricao : 'Description',
            data: 'DATE',
            horario: 'TIME',
            quarto: 'ROOM',
            status: 'STATUS',
            acoes : 'Actions',
            semsolstoalhabanho: 'No requests for bath towel.',
            semsolstoalharosto: 'No requests for face towel.',
            semsolsroupacama: 'No requests for bed linen.',
            semsolsarrumacao: 'No requests for housekeeping.',
            semsolsamenities: 'No amenities requests.',
            semsolscafe: 'No requests for coffee.',
            semsolsfrigobar: 'No requests for minibar.',
            semsolsacordar: 'No prompts to wake up on time.',
            semsolslatecheckout: 'No requests for late checkout.',
            semsolsmensageiro: 'No requests for late checkout.',
            semsolsmanutencao: 'No maintenance requests.',
            semsolsgerencia: 'No requests for management.',
            semsolscompra: 'No purchase requests.',
            enviar: 'Send',
            escrevatextoacompanhamento: 'Write a text for the guest',
            escrevaconclusaoatendimento: 'Write the conclusion of the service',
            escrevamotivocancelamento: 'Type the reason for cancellation',
            seraatendidamediantedisponibilidade: 'We are aware of your request that will be attended to upon availability!',
            suasolicitacaofoiatendida: 'Your request has been granted!',
            suasolicitacaonaofoiatendida: 'We currently do not have the resources available to fulfill your request.',
            ultimaAlteracao: 'Last Update',
            prioridades: [
                { idprioridade: 1, descricao: "Low" },
                { idprioridade: 2, descricao: "Medium" },
                { idprioridade: 3, descricao: "High" },
                { idprioridade: 4, descricao: "Critical" }
            ],
            fornecedor: 'Provider',
            esteprodutojaexiste: 'This product in already on the list.',
            verificarquantidades: 'The delivered quantity must be less than or equal to the released quantity, which must not be greater than the available quantity in stock.',
            alternarselecaotip: 'Alternate selection',
            chamadoematendimento: 'Request is being processed.',
        },
        ser: {
            site: 'Site',
            email: 'Email',
            filtrar: 'Filter',
            pesquisa: 'Search',
            nomedaempresa: 'Company Name',
            setor: 'Sector',
            telefone: 'Telphone',
            bairro: 'Section',
            cidade: 'City',
            estado: 'State',
            pesquisar: 'Search',
            cadastrar: 'Sign Up',
            empresascadastradas: 'Registered Companies',
            empresa: 'COMPANY',
            tipo: 'TYPE',
            whatsapp: 'Whatsapp',
            endereco: 'ANDDRESS',
            status: 'STATUS',
            editar: 'EDIT',
            desativar: 'DISABLE',
            ativar: 'ENABLE',
            nomedoservico: 'Service Name',
            valor: 'Value',
            descricao: 'Description',
            ativo: 'Active',
            desativado: 'Desactive',
            servicoscadastrados: 'Registered Services',
            servico: 'SERVICES',
            desconto: 'DESCOUNT',
            promocao: 'PROMOTION,',
            nomedapromocao: 'Promotion Name',
            pais: 'Country',
            cep: 'ZIP Code',
            numero: 'Number',
            complemento: 'Complement',
            salvar: 'Save',
            voltar: 'back',
            parceiro: 'Partner',
            parceiros: 'Partners',
            cadastrarpromocao: 'Register promotions',
            cadastrarparceiro: 'Register Partner',
            subtitulo: 'Subtitle',
            titulo: 'Title',
            datavalidade: 'Valid Date',
            preenchertitulosubtitulo: 'Fill in a title and a subtitle!'
        },
        avaliacao: {
            titulo: 'Schedule your Reviews',
            descricao: 'Control the schedule of hotel reviews that will be automatically sent to guests.',
            ativo: 'Active',
            inativo: 'Inactive',
            datacheckin: 'checkin date',
            datacheckoutprev: 'checkout date',
            diaaposa: 'day after',
            diasaposa: 'days after',
            diaantesda: 'day before',
            diasantesda: 'days before'
        },
        categoriaPergunta: {
            titulo: 'Question Tags',
            descricao: 'The question tags or categories allow you to organize the evaluations made by the guest in a useful and comprehensive way.',
        },
        promocoeshotel: {
            titulo: 'Hotel Promotions',
            descricao: 'Hotel promotions can be registered here so that the guest can see them in the app.',
        },
        relatorios: {
            titulo: 'Evaluation Reports',
            descricao: 'The reports of the reviews answered by the guest give us a general idea of how the guest\'s experience with the hotel is going according to their ratings.',
            subtitulo1: 'General Index',
            subtitulo1descricao: 'Overall average grade. Presented in levels by Hotel, Rating and Category',
            subtitulo2: 'Index By Rating',
            subtitulo2descricao: 'Here it is possible to see the results of the research according to the scheduled evaluations.',
            subtitulo3: 'Index By Category',
            subtitulo3descricao: 'The Question Category offers another snapshot to view the indexes from an overview.'
        },
        reviewsdashboard: {
            titulo: 'Reviews Dashboard',
            descricao: 'Choose a period, up to one year, to update the charts.',
            periodode: 'Period from',
            ate: 'To',
            historicogeral: 'General Average History',
            divisaogeralnotas: 'General Division of Notes',
            historicomediaavaliacao: 'Average Rating by Assessment',
            periodo: 'Period',
            periodoanterior: 'Previous Period',
        },
        reviewsportal: {
            titulo: 'Review Portals',
            descricao: 'Register the review portals here so that guests can give their opinion about the hotel.',
            portaiscadastrados: 'Registered portals',
        },
        tipoSolicitacao: {
            titulo: 'Schedule your Reviews',
            descricao: 'Control the schedule of hotel reviews that will be automatically sent to guests.',
            ativo: 'Active',
            inativo: 'Inactive',
            datacheckin: 'checkin date',
            datacheckoutprev: 'checkout date',
            diaaposa: 'day after',
            diasaposa: 'days after',
            diaantesda: 'day before',
            diasantesda: 'days before',
            datadasolicitacao: 'Request date',
            datadoagendamento: 'Appointment date'
        },
        chatAPI: {
            titulo: 'Chat API Setup',
            descricao: 'Configure your account information for Chat API (Whatsapp) or other supported APIs here. The configured account will be used to send automatic messages from the system to the guest.',
            ativardesativarapichat: 'Activate/Deactivate chat API'
        },
        contaEmail: {
            titulo: 'Email account setup',
            descricao: 'Configure your Email account information here. The configured account will be used to send automatic messages from the system to the guest.',
            ativardesativarcontaemail: 'Activate/Deactivate Email Account',
            nametoexibition: 'Name',
            host: 'Host',
            port: 'Port',
            replyto: 'Reply to',
            user: 'User',
            pass: 'Password',
        },
        localAtendimento: {
            titulo: 'Service Locations',
            tituloLista: 'Registered Service Locations',
            descricao: 'Service locations are used to define where internal requests are being serviced.',
            local: 'Location',
            status: 'Status',
            acao: 'Action',
            descreva: 'Describe the place'
        },
        vincularTecnicos: {
            categoriaseitens: 'Categories and associated items',
            nome: 'Name',
            nomeusuario: 'User Name',
            email: 'Email',
            perfil: 'Role'
        },
        estoque: {
            historico: 'Product history in stock',
            titulo: 'Stocks',
            tituloLista: 'Registered Inventories',
            descricao: 'The maintenance stocks.',
            unidade: 'Un.',
            quantidade: 'Qtty.',
            categoria: 'Category',
            produto: 'Inventory',
            status: 'Status',
            acao: 'Action',
            descreva: 'Describe the stock',
            pesquisar: 'Search'
        },
        estoquerelatorios: {
            relatorio: 'Report',
            titulo: 'Stock Reports',
            descricao: 'The products in the maintainance stock.'
        },
        upload: {
            titulo: 'Upload ".csv" files.',
            subtitulo: 'Upload ".csv" files in predefined format to register categories and products in stock.',
            escolher: 'Choose File',
            importando: 'Importing...'
        },
        chamadoProgramado: {
            titulo: 'Scheduled or Periodic Calls',
            descricao: 'This functionality allows you to schedule the automatic generation of maintenance calls for recurring calls or calls scheduled to take place on a certain date.',
            tituloLista: 'Registered Scheduled Calls',
            programacao: 'Schedule',
            chamado: 'Call',
            em: 'On',
            todosegundox: 'Every second',
            acadaxsegundos: 'Each',
            todominutox: 'Every minute',
            acadaxminutos: 'Each',
            todahorax: 'Toda hora',
            acadaxhoras: 'Each',
            tododiaxdomes: 'Every day x of month',
            acadaxdias: 'Each',
            todomesx: 'Every month',
            acadaxmeses: 'Each',
            tododiaxdasemana: 'Every day x of week',
            acadaxdomingos: 'Each',
            dias: ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'],
            meses: ['January','February','March','April','May','June','July','August','September','October','November','December'],
            horadodiaouacadaxhoras: 'Hour of day or each x hours',
            segundoxouacadaxsegundos: 'Second x or each x seconds',
            minutoxouacadaxminutos: 'Minute x or each x minutes',
            diaxdecadamesouacadaxdias: 'Day x of month or each x days',
            mesxouacadaxmeses: 'Month x or each x months',
            diaxdasemana: 'Day x of week',
            checklist: 'Check List',
            adicionarchecklist: 'Add Check List'
        }
    },
    'es': {
        global: {
            carregando: 'Cargando',
            idioma: 'Idioma',
            icone: 'Icono',
            salvar: 'Guardar',
            salvocomsucesso: 'Guardado exitosamente!',
            ajustar: 'Ajustar',
            apagar: 'Eliminar',
            codigo: 'Código',
            fechar: 'Cerrar',
            cadastrar: 'Registrar',
            ativar: 'Activar',
            inativar: 'Desactivar',
            ativo: 'Activo',
            inativo: 'Inactivo',
            editar: 'Editar',
            titulo: 'Título',
            subtitulo: 'Subtítulo',
            descricao: 'Descripción',
            texto: 'Texto',
            nome: 'Nombre',
            pessoafisica: 'Personas Físicas',
            pessoajuridica: 'Personas Jurídicas',
            todos: 'Todos',
            todas: 'Todas',
            historico: 'Historial',
            operacao: 'Operación',
            dataehora: 'Fecha y hora',
            por: 'Por',
            antigovalor: 'Valor antiguo',
            novovalor: 'Valor nuevo',
            U: 'Actualizado',
            I: 'Insertado',
            D: 'Excluido',
            periodode: 'Período de',
            periodoate: 'a',
            filtrar: 'Filtrar',
            erro: 'Error',
            categoria: 'Categoria',
            subcategoria: 'Subcategoria',
            item: 'Item',
            programacao: 'Calendario',
            acao: 'Acción',
            status: 'Status',
            proximaexecucao: 'Próxima carrera',
            ultimaexecucao: 'Última carrera',
            upload: 'Upload',
            duplicar: 'Duplicar',
            voltar: 'Atrás',
            abrirchamado: 'Abrir ticket',
            exibirchamados: 'Ver tickets',
            frequencia: 'Frecuencia',
            semchamados: 'Sin tickets.',
            atribuicoes: 'Asignaciones',
            escala: 'Horario de Trabajo',
            escaladetrabalhoefolgas: 'Horario de trabajo y días libres',
            naoatribuido: 'No asignado'
        },
        login: {
            acessarconta: 'Acceder a la cuenta',
            usuario: 'Usuario',
            senha: 'Contraseña',
            lembrarsenha: 'Recordar contraseña',
            esqueceusuasenha: 'Olvidaste tu contraseña?',
            acessar: 'Acceder',
            naotemconta: '¿No tienes una cuenta?',
            cadastrarconta: 'Registrar Cuenta',
            verifique: 'No se ha autorizado el acceso al sistema. Verifique sus credenciales.'
        },
        dashboard: {
            bemvindoaopainelfdguest: 'Bienvenido al panel de invitados de FD',
            tenhacontroletotalsobreassolicitacoes: 'Tenga control total sobre las solicitudes de sus huéspedes durante su estadía.',
            dia: 'Día',
            semana: 'Semana',
            mes: 'Mes',
            quantidadesolicitacoes: 'Número de Solicitudes',
            atendimentosolicitacoes: 'Servicio de Solicitudes',
            ultimassolicitacoes: 'Últimas solicitudes',
            quarto: 'Habitación',
            vertodos: 'Ver todo',
            solicitadas: 'Solicitado',
            atendidas: 'Respondido'
        },
        perfil: {
            escolhaumidioma: 'Escolha um idioma',
            selecioneoidiomadepreferencia: 'Seleccione el idioma preferido'
        },
        sols: {
            impressao : 'Impresión',
            localatendimento: 'Ubicación del servicio',
            responsavel: 'Responsable',
            prioridade: 'Prioridad',
            manutencaoexterna: 'Mantenimiento Externo',
            garantia: 'Garantía (en días)',
            produtos : 'Productos',
            impedimento : 'Impedimentos',
            anexo: 'Archivo',
            motivo: 'Razón de la cancelación',
            fechar: 'Cerrar',
            historico: 'Historial',
            comentario: 'Comentario',
            pedido: 'Pedido',
            filtro: 'Filtrar',
            setor: 'Departamento',
            tipo: 'Tipo',
            local : 'Lugar',
            uh: 'Habitación',
            reserva: 'Reserva',
            solicitacao: 'Solicitude',
            solicitacoes: 'Solicitudes',
            atendida: 'Respondido',
            naoatendida: 'No respondido',
            contestada: 'Impugnada',
            ematendimento: 'En servicio',
            pendente: 'Pendiente',
            cancelada: 'Cancelada',
            atender: 'Resolver',
            tratar: 'Tratar',
            cancelar: 'Cancelar',
            nome: 'NOMBRE',
            texto: 'TEXTO',
            descricao : 'Descripción',
            data: 'DATA',
            horario: 'HORA',
            quarto: 'HABITACIÓN',
            status: 'STATUS',
            acoes : 'Acciones',
            semsolstoalhabanho: 'No se solicitan toallas de baño.',
            semsolstoalharosto: 'No se solicitan paños para la cara.',
            semsolsroupacama: 'No hay solicitudes de ropa de cama.',
            semsolsarrumacao: 'No hay solicitudes de limpieza.',
            semsolsamenities: 'No hay solicitudes de servicios.',
            semsolscafe: 'No hay solicitudes de café.',
            semsolsfrigobar: 'No hay solicitudes de minibar.',
            semsolsacordar: 'No hay indicaciones para despertarse a tiempo.',
            semsolslatecheckout: 'No hay solicitudes de salida tardía.',
            semsolsmensageiro: 'No hay solicitudes de salida tardía.',
            semsolsmanutencao: 'Sin solicitudes de mantenimiento.',
            semsolsgerencia: 'Sin solicitudes de gestión.',
            semsolscompra: 'Sin solicitudes de compra.',
            enviar: 'Enviar',
            escrevatextoacompanhamento: 'Escribe un texto para el invitado.',
            escrevaconclusaoatendimento: 'Escribe la conclusión del servicio.',
            escrevamotivocancelamento: 'Escriba el motivo de la cancelación',
            seraatendidamediantedisponibilidade: '¡Estamos al tanto de su solicitud que será atendida según disponibilidad!',
            suasolicitacaofoiatendida: '¡Su solicitud ha sido concedida!',
            suasolicitacaonaofoiatendida: 'Actualmente no tenemos los recursos disponibles para cumplir con su solicitud.',
            ultimaAlteracao: 'Últ. Actualización',
            prioridades: [
                { idprioridade: 1, descricao: "Bajo" },
                { idprioridade: 2, descricao: "Promedio" },
                { idprioridade: 3, descricao: "Alto" },
                { idprioridade: 4, descricao: "Crítica" }
            ],
            fornecedor: 'Fornecedor',
            esteprodutojaexiste: 'Este producto ya está en la lista.',
            verificarquantidades: 'La cantidad entregada debe ser menor o igual a la cantidad liberada, la cual no debe ser mayor a la cantidad disponible en stock.',
            chamadoematendimento: 'La solicitud se está procesando.',
        },
        ser: {
            site: 'Site',
            email: 'Email',
            filtrar: 'Filtrar',
            pesquisa: 'Buscar',
            nomedaempresa: 'Nombre de la empresa',
            setor: 'Sector',
            telefone: 'Telefono',
            bairro: 'Barrio',
            cidade: 'Ciudad',
            estado: 'Estado',
            pesquisar: 'Buscar',
            cadastrar: 'Registrar',
            empresascadastradas: 'Empresas registradas',
            empresa: 'EMPRESAS',
            tipo: 'TIPO',
            whatsapp: 'Whatsapp',
            endereco: 'HABLA A',
            status: 'ESTADO',
            editar: 'EDITAR',
            desativar: 'DESACTIVAR',
            ativar: 'ACTIVAR',
            nomedoservico: 'Nombre del Servicio',
            valor: 'Valor',
            descricao: 'Descripción',
            ativo: 'Activo',
            desativado: 'Discapacitado',
            servicoscadastrados: 'Servicios Registrados',
            servico: 'SERVICIO',
            desconto: 'DESCUENTO',
            promocao: 'PROMOCION,',
            nomedapromocao: 'Nombre del Promocion',
            pais: 'País',
            cep: 'Código postal',
            numero: 'Numero',
            complemento: 'Complemento',
            salvar: 'Ahorrar',
            voltar: 'Vuelve',
            parceiro: 'Pareja',
            parceiros: 'Parejas',
            cadastrarpromocao: 'Registro de Promoción',
            cadastrarparceiro: 'Registro de Pareja',
            subtitulo: 'Subtítulo',
            titulo: 'Título',
            datavalidade: 'Fecha de validez',
            preenchertitulosubtitulo: '¡Escribe un título y un subtítulo!'
        },
        avaliacao: {
            titulo: 'Programe sus revisiones',
            descricao: 'Controle el cronograma de reseñas de hoteles que se enviarán automáticamente a los huéspedes.',
            ativo: 'Activo',
            inativo: 'Inactivo',
            datacheckin: 'fecha de entrada',
            datacheckoutprev: 'fecha de salida',
            diaaposa: 'día despues de la',
            diasaposa: 'días despues de la',
            diaantesda: 'día antes de la',
            diasantesda: 'días antes de la'
        },
        categoriaPergunta: {
            titulo: 'Categorías de preguntas',
            descricao: 'Las categorías de preguntas le permiten organizar las evaluaciones realizadas por el invitado de una manera útil y completa.',
        },
        promocoeshotel: {
            titulo: 'Promociones de hoteles',
            descricao: 'Las promociones de hoteles se pueden registrar aquí para que el huésped pueda verlas en la aplicación.',
        },
        relatorios: {
            titulo: 'Informes de evaluación',
            descricao: 'Los informes de las opiniones respondidas por el huésped nos dan una idea general de cómo va la experiencia del huésped con el hotel según sus valoraciones.',
            subtitulo1: 'Índice General',
            subtitulo1descricao: 'Calificación promedio general. Presentado en niveles por Hotel, Calificación y Categoría',
            subtitulo2: 'Índice por Calificación',
            subtitulo2descricao: 'Aquí es posible ver los resultados de la investigación según las evaluaciones programadas.',
            subtitulo3: 'Índice por Categoría',
            subtitulo3descricao: 'La categoría de preguntas ofrece otra instantánea para ver los índices desde una descripción general.'
        },
        reviewsdashboard: {
            titulo: 'Reviews Dashboard',
            descricao: 'Elija un período, hasta un año, para actualizar los gráficos.',
            periodode: 'Período de',
            ate: 'Hasta',
            historicogeral: 'Historia promedio general',
            divisaogeralnotas: 'División general de notas',
            historicomediaavaliacao: 'Calificación promedio por calificación',
            periodo: 'Periodo',
            periodoanterior: 'Periodo Anterior',
        },
        reviewsportal: {
            titulo: 'Portales de Reviews',
            descricao: 'Registra aquí los portales de opiniones para que los huéspedes puedan dar su opinión sobre el hotel.',
            portaiscadastrados: 'Portales registrados',
        },
        tipoSolicitacao: {
            titulo: 'Programe sus revisiones',
            descricao: 'Controle el cronograma de reseñas de hoteles que se enviarán automáticamente a los huéspedes.',
            ativo: 'Activo',
            inativo: 'Inactivo',
            datacheckin: 'fecha de entrada',
            datacheckoutprev: 'fecha de salida',
            diaaposa: 'día despues de la',
            diasaposa: 'días despues de la',
            diaantesda: 'día antes de la',
            diasantesda: 'días antes de la',
            datadasolicitacao: 'Fecha de solicitud',
            datadoagendamento: 'Fecha de programación'
        },
        chatAPI: {
            titulo: 'Configuración de la API de chat',
            descricao: 'Configure la información de su cuenta para Chat API (Whatsapp) u otras API compatibles aquí. La cuenta configurada se utilizará para enviar mensajes automáticos desde el sistema al huésped.',
            ativardesativarapichat: 'Habilitar/deshabilitar la API de chat'
        },
        contaEmail: {
            titulo: 'Configuración de cuenta de correo electrónico',
            descricao: 'Configure la información de su cuenta de correo electrónico aquí. La cuenta configurada se utilizará para enviar mensajes automáticos desde el sistema al huésped.',
            ativardesativarcontaemail: 'Activar/Desactivar cuenta de correo electrónico',
            nametoexibition: 'Nombre',
            host: 'Servidor',
            port: 'Puerta',
            replyto: 'Responder a',
            user: 'Usuario',
            pass: 'Contraseña',
        },
        localAtendimento: {
            titulo: 'Ubicaciones de servicio',
            tituloLista: 'Ubicaciones de servicio registradas',
            descricao: 'Las ubicaciones de cumplimiento se utilizan para definir dónde se atienden las solicitudes internas.',
            local: 'Ubicación',
            status: 'Estado',
            acao: 'Acción',
            descreva: 'Describe el lugar'
        },
        vincularTecnicos: {
            categoriaseitens: 'Categorias e itens associados',
            nome: 'Nombre',
            nomeusuario: 'Nombre Usuario',
            email: 'Email',
            perfil: 'Perfil'
        },
        estoque: {
            historico: 'Historial de productos en stock',
            titulo: 'Cepo',
            tituloLista: 'Inventarios Registrados',
            descricao: 'Las existencias de mantenimiento.',
            unidade: 'Und.',
            quantidade: 'Cantd.',
            categoria: 'Categoria',
            produto: 'Inventario',
            status: 'Estado',
            acao: 'Acción',
            descreva: 'Describa la acción',
            pesquisar: 'Búsqueda'
        },
        estoquerelatorios: {
            relatorio: 'Reporte',
            titulo: 'Informes de existencias',
            descricao: 'Los productos en el stock de mantenimiento.'
        },
        upload: {
            titulo: 'Carga de archivos ".csv".',
            subtitulo: 'Cargue archivos ".csv" en formato predefinido para registrar categorías y productos en stock.',
            escolher: 'Elija el archivo',
            importando: 'Importando...'
        },
        chamadoProgramado: {
            titulo: 'Chamados Programados ou Periódicos',
            descricao: 'Esta funcionalidade permite programar a geração automática de chamados da manutenção para chamados recorrentes ou programados para acontecerem em uma determinada data.',
            tituloLista: 'Chamados Programados Cadastrados',
            programacao: 'Programación'
        }
    }
};

const i18n = new VueI18n({
    locale: 'pt-BR',
    fallbackLocale: ['es', 'en'],
    messages
});

export default i18n