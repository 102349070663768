<template>
	<div class="row">
		<!-- Aplicativo -->
		<div class="col-xl-12 col-lg-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Configurações de Reviews</h4>
				</div>
				<div class="card-body">
					<div class="basic-form">
						<div class="col-xl-12">
							<div>
								<div class="form-row">
									<!-- Enviar E-mail de Alerta para Notas Baixas -->
									<div class="form-group col-md-5">
										<h4>Enviar E-mail de Alerta para Notas Baixas?</h4>
									</div>
									<div class="form-group col-md-3">
										<div class="custom-control custom-switch">
											<input  type="checkbox" class="custom-control-input ponteiro" 
												id="distribuir-chamados" :checked="alertarNotaBaixa" v-model="alertarNotaBaixa">
											<label class="custom-control-label ponteiro" 
												for="distribuir-chamados"></label>
										</div>
									</div>
								</div>
								<template v-if="alertarNotaBaixa">
									<div class="form-row">
										<div class="form-group col-sm-auto">
											<label>Enviar e-mail de alerta para respostas com nota igual ou menor a (1-5):</label>
											<input style="cursor: pointer;" v-model="notaMinima" type="range" min="1" max="5" class="form-control">
											<input style="font-size: 1.5rem; text-align: center;" v-model="notaMinima" type="number" min="1" max="5" from class="form-control">
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-12">
											<label>Enviar e-mail para os endereços (separados por vírgula):</label>
											<input v-model="listaEmails" type="text" class="form-control" 
												>
										</div>
									</div>
								</template>
								
								<div class="mb-4">
									<button @click="salvarConfig()" type="button" class="btn btn-primary mt-4" id="toastr-danger-top-right">Salvar</button>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'

export default {
	components: { 		
		
	},
	data() {
		return {
			site: '',
			headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
			},
			alertarNotaBaixa: true,
			notaMinima: 3,
			listaEmails: ''
		}
	},
	methods: {
		salvarConfig() {
			var params = {
				alertarNotaBaixa: this.alertarNotaBaixa,
				notaMinima: this.notaMinima,
				listaEmails: this.listaEmails,
                alterado_por: this.$store.getters.GetLoginInfo.idusuario
			}
			axios.post(CONSTANTS.API_URL + '/fdguest/sistema/update', params, {headers: this.headers})
			.then(response=>{
				if (response.status == 200) {
					this.closeAccordionItem('distribuicao-accordion-item', 'distribuicao-chamados')
					this.$snack('Configuração salva com sucesso!')
				}
			})
			.catch((e)=>{
				this.$snackE(`Erro ao salvar a configuração: ${JSON.stringify(e)}`)
			})
		},
		closeAccordionItem(idbar,idcontent) {
			var el1 = document.getElementById(idbar)
			var el2 = document.getElementById(idcontent)
			if (el1&&el2) {
				if (!el1.classList.contains('collapsed')) {
					el1.classList.add('collapsed')
				}
				if (el2.classList.contains('show')) {
					el2.classList.remove('show')
				}
			}
		},
		loadConfig() {
			axios.post(CONSTANTS.API_URL + '/fdguest/sistema/select', {
				idioma:this.$i18n.locale}, {headers: this.headers})
			.then(response => {
				if(response.status == 200) {
					let cfg = response.data
					this.alertarNotaBaixa = cfg.alertarNotaBaixa == 'true';
					this.notaMinima = cfg.notaMinima;
					this.listaEmails = cfg.listaEmails;
				}
			})			
		},
	},
	computed: {
	},
	created() {
	},
	mounted() {
		this.loadConfig()
	}
}
</script>

<style>
.carregando {
	padding: 3rem;
	padding-top: 5.7rem;
	padding-bottom: 5.7rem;
	color: orange;
	font-weight: bold;
}
</style>