import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import RecuperarSenha from '../components/RecuperarSenha.vue'
import Painel from '../components/Painel.vue'
import Dashboard from '../components/Dashboard.vue'
import SolInternas from '../components/SolInternas.vue'
import SolTodas from '../components/SolTodas.vue'
import SolicitacaoDetalhe from '../components/SolicitacaoDetalhe.vue'
import SolRecepcao from '../components/SolRecepcao.vue'
import SolGovernanca from '../components/SolGovernanca.vue'
import SolRestaurante from '../components/SolRestaurante.vue'
import SolManutencao from '../components/SolManutencao.vue'
import SolCompras from '../components/SolCompras.vue'
import SolGerencia from '../components/SolGerencia.vue'
import SerConcierge from '../components/SerConcierge.vue'
import CadConcierge from '../components/CadConcierge.vue'
import SerSalaoSpa from '../components/SerSalaoSpa.vue'
import CadSalaoSpa from '../components/CadSalaoSpa.vue'
import SerPromocoes from '../components/SerPromocoes.vue'
import CadPromocoes from '../components/CadPromocoes.vue'
import GerPerguntas from '../components/GerPerguntas.vue'
import ReviewsDashboard from '../components/ReviewsDashboard.vue'
import Avaliacao from '../components/Avaliacao.vue'
import CategoriaPergunta from '../components/CategoriaPergunta.vue'
import CadPerguntas from '../components/CadPerguntas.vue'
import Respostas from '../components/Respostas.vue'
import RelSolicitacoes from '../components/RelSolicitacoes.vue'
import ConfigHotel from '../components/ConfigHotel.vue'
import Usuario from '../components/Usuario.vue'
import UsuarioEditar from '../components/UsuarioEditar.vue'
import Perfil from '../components/Perfil.vue'
import Icones from '../components/Icones.vue'
import Relatorios from '../components/Relatorios.vue'
import TipoSolicitacao from '../components/TipoSolicitacao.vue'
import Tester from '../components/Tester.vue'
import PromocoesHotel from '../components/PromocoesHotel.vue'
//import SolicitacaoControl from '../components/SolicitacaoHistorico.vue'
import ReviewsPortal from '../components/ReviewsPortal.vue'
import ChatAPI from '../components/ChatAPI.vue'
import ContaEmail from '../components/ContaEmail.vue'
import WizardTeste from '../components/WizardTeste.vue'
import LocalAtendimento from '../components/LocalAtendimento.vue'
import ManutencaoItem from '../components/ManutencaoItem.vue'
import VincularTecnico from '../components/VincularTecnico.vue'
import Estoque from '../components/Estoque.vue'
import RelatoriosManutencao from '../components/RelatoriosManutencao.vue'
import UploadProduto from '../components/UploadProduto.vue'
import SolicitacaoPDF from '../components/SolicitacaoPDF.vue'
import TesteSocketIO from '../components/TesteSocketIO.vue'
import CadFornecedor from '../components/CadFornecedor.vue'
import ChamadoProgramado from '../components/ChamadoProgramado.vue'
import Assinatura from '../components/Assinatura.vue'
import Faq from '../components/Faq.vue'
import Fornecedor from '../components/Fornecedor.vue'
import FornecedorEditar from '../components/FornecedorEditar.vue'
import AppDownload from '../components/AppDownload.vue'
import Setor from '../components/Setor.vue'
import DBLogs from '../components/DBLogs.vue'
import ChamadoProgramadoUpload from '../components/ChamadoProgramadoUpload.vue'
import ConfigApp from '../components/ConfigApp.vue'
import ConfigManutencao from '../components/ConfigManutencao.vue'
import Equipamento from '../components/Equipamento.vue'
import Camareira from '../components/Camareira.vue'
import InfoCliente from '../components/InfoCliente.vue'
import ConfigReviews from '../components/ConfigReviews.vue'
import RespostasAgrupadas from '../components/RespostasAgrupadas.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/painel/app', name: 'DownloadAPP', component: AppDownload},

  {path: '/painel/solicitacaodetalhe/pdf', name: 'SolicitacaoPDF', component: SolicitacaoPDF},

  {path: '/painel/solicitacaodetalhe/assinatura', name: 'SolicitacaoAssinatura', component: Assinatura},
  
  { path: '/testesocketio', name: 'TesteSocketIO', component: TesteSocketIO },
  { path: '/icones', name: 'Icones', component: Icones },
  { path: '/', name: 'Login', component: Login },
  { path: '/recuperarsenha', name: 'RecuperarSenha', component: RecuperarSenha },
  { path: '/painel', name: 'Painel', component: Painel, 
    children:[
      {path: '/painel/dashboard', name: 'Dashboard', component: Dashboard},
      {path: '/painel/solinternas', name: 'SolInternas', component: SolInternas},
      {path: '/painel/solicitacao', name: 'SolTodas', component: SolTodas},
      {path: '/painel/solicitacaodetalhe', name: 'Solicitacao Detalhe', component: SolicitacaoDetalhe},
      {path: '/painel/solrecepcao', name: 'SolRecepcao', component: SolRecepcao},
      {path: '/painel/solrecepcao/:id', name: 'SolRecepcaoId', component: SolRecepcao},
      {path: '/painel/solgovernanca', name: 'SolGovernanca', component: SolGovernanca},
      {path: '/painel/solgovernanca/:id', name: 'SolGovernancaId', component: SolGovernanca},
      {path: '/painel/solrestaurante', name: 'SolRestaurante', component: SolRestaurante},
      {path: '/painel/solrestaurante/:id', name: 'SolRestauranteId', component: SolRestaurante},
      {path: '/painel/solmanutencao', name: 'SolManutencao', component: SolManutencao},
      {path: '/painel/solmanutencao/:id', name: 'SolManutencaoId', component: SolManutencao},
      {path: '/painel/solcompras', name: 'SolCompras', component: SolCompras},
      {path: '/painel/solcompras/:id', name: 'SolComprasId', component: SolCompras},
      {path: '/painel/solgerencia', name: 'SolGerencia', component: SolGerencia},
      {path: '/painel/solgerencia/:id', name: 'SolGerenciaId', component: SolGerencia},
      {path: '/painel/serconcierge', name: 'SerConcierge', component: SerConcierge},
      {path: '/painel/cadconcierge', name: 'CadConcierge', component: CadConcierge},
      {path: '/painel/sersalaospa', name: 'SerSalaoSpa', component: SerSalaoSpa},
      {path: '/painel/cadsalaoSpa', name: 'CadSalaoSpa', component: CadSalaoSpa},
      {path: '/painel/serpromocoes', name: 'SerPromocoes', component: SerPromocoes},
      {path: '/painel/cadpromocoes', name: 'CadPromocoes', component: CadPromocoes},
      {path: '/painel/gerperguntas', name: 'GerPerguntas', component: GerPerguntas},
      {path: '/painel/reviewsdashboard', name: 'ReviewsDashboard', component: ReviewsDashboard},
      {path: '/painel/avaliacao', name: 'Avaliacao', component: Avaliacao},
      {path: '/painel/categoriapergunta', name: 'CategoriaPergunta', component: CategoriaPergunta},
      {path: '/painel/cadperguntas', name: 'CadPerguntas', component: CadPerguntas},
      {path: '/painel/fornecedorparceiro', name : 'CadFornecedor', component : CadFornecedor },
      {path: '/painel/fornecedor', name : 'Fornecedor', component : Fornecedor },
      {path: '/painel/fornecedoreditar', name : 'FornecedorEditar', component : FornecedorEditar },
      {path: '/painel/respostas', name: 'Respostas', component: Respostas},
      {path: '/painel/respostasagrupadas', name: 'Respostas', component: RespostasAgrupadas},
      {path: '/painel/relsolicitacoes', name: 'RelSolicitacoes', component: RelSolicitacoes},
      {path: '/painel/confighotel', name: 'ConfiguracaoHotel', component: ConfigHotel},
      {path: '/painel/configusuario', name: 'UsuariosCadastrados', component: Usuario},
      {path: '/painel/configusuarioedit', name: 'EditarUsuario', component: UsuarioEditar},
      {path: '/painel/perfil', name: 'Perfil', component: Perfil},
      {path: '/painel/relatorios', name: 'Relatorios', component: Relatorios},
      {path: '/painel/tiposolicitacao', name: 'TipoSolicitacao', component: TipoSolicitacao},
      {path: '/painel/promocoeshotel', name: 'PromocoesHotel', component: PromocoesHotel},
      {path: '/painel/tester', name: 'Tester', component: Tester},
      {path: '/painel/portais', name: 'ReviewsPortal', component: ReviewsPortal},
      {path: '/painel/chatapi', name: 'ChatAPI', component: ChatAPI},
      {path: '/painel/contaemail', name: 'ContaEmail', component: ContaEmail},
      {path: '/painel/wizard', name: 'WizardTeste', component: WizardTeste},
      {path: '/painel/localatendimento', name: 'LocalAtendimento', component: LocalAtendimento},
      {path: '/painel/manutencaoitem', name: 'ManutencaoItem', component: ManutencaoItem},
      {path: '/painel/vinculartecnico', name: 'VincularTecnico', component: VincularTecnico},
      {path: '/painel/estoque', name: 'Estoque', component: Estoque},
      {path: '/painel/manutencao/relatorios', name: 'RelatoriosManutencao', component: RelatoriosManutencao},
      {path: '/painel/estoque/upload', name: 'UploadProduto', component: UploadProduto},
      {path: '/painel/chamadoprogramado', name: 'ChamadoProgramado', component: ChamadoProgramado},
      {path: '/painel/faq', name: 'FAQ', component: Faq},
      {path: '/painel/setor', name: 'Setor', component: Setor},
      {path: '/painel/dblogs', name: 'DBLogs', component: DBLogs},
      {path: '/painel/chamadoprogramadoupload', name: 'ChamadoProgramadoUpload', component: ChamadoProgramadoUpload},
      {path: '/painel/configapp', name: 'ConfigApp', component: ConfigApp},
      {path: '/painel/configmanutencao', name: 'ConfigManutencao', component: ConfigManutencao},
      {path: '/painel/equipamento', name: 'Equipamento', component: Equipamento},
      {path: '/painel/camareira', name: 'Camareira', component: Camareira},
      {path: '/painel/infocliente', name: 'InfoCliente', component: InfoCliente},
      {path: '/painel/reviews/config', name: 'ConfigReviews', component: ConfigReviews},
    ],
     
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
