<template>
    <!-- Cadastro de Usuário -->
    <div class="col-xl-12 col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{usuario.nome}}</h4>
            </div>
            <div class="card-body">
                <div :id="'accordion-usr-' + usuario.idusuario" class="accordion accordion-primary">
                    
					<!-- Dados do Usuário -->
                    <div class="accordion__item">
                        <div class="accordion__header collapsed rounded-lg" data-toggle="collapse" data-target="#default_collapseOne">
                            <span class="accordion__header--text">Dados do Usuário</span>
                            <span class="accordion__header--indicator"></span>
                        </div>
                        <div id="default_collapseOne" class="collapse accordion__body" :data-parent="'#accordion-usr-' + usuario.idusuario">
                            <div class="accordion__body--text">
                                <div class="basic-form mt-5">
                                    <!-- <form> -->
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label>Nome de Usuario:</label>
                                                <input v-model="usuario.nomeusuario" type="text" class="form-control" placeholder="" ref="nomeusuario">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Nome:</label>
                                                <input v-model="usuario.nome" type="text" class="form-control" ref="nome">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Senha:</label>
                                                <input v-model="usuario.novasenha" type="password" class="form-control" placeholder="" ref="novasenha">
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label>Confirme sua Senha:</label>
                                                <input v-model="usuario.senhaconfirmada" type="password" class="form-control" placeholder="" ref="senhaconfirmada">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>E-mail:</label>
                                                <input v-model="usuario.emailusuario" type="text" class="form-control" ref="emailusuario">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Telefone:</label>
                                                <input v-model="usuario.telefone" type="text" class="form-control">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Idioma:</label>
                                                <select v-model="usuario.idioma" class="form-control">
                                                    <option v-for="idioma in idiomas" :key="idioma.ididioma" 
                                                        :value="idioma.codidioma"
                                                        :selected="idioma.codidioma == usuario.idioma"
                                                    >{{idioma.descidioma}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Perfil:</label>
                                                <select v-model="usuario.idusuariotipo" class="form-control"
													@change="recarregarAcessos()"
												>
                                                    <option v-for="tipousuario in usuarioTipos" :key="tipousuario.idusuariotipo"
                                                        :value="tipousuario.idusuariotipo">{{tipousuario.descusuariotipo}}</option>																				
                                                </select>
                                            </div>                                            
                                        </div>
                                    <!-- </form> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Acessos do Usuário -->
                    <div class="accordion__item">
                        <div class="accordion__header collapsed rounded-lg" data-toggle="collapse" :data-target="'#default_collapse_'+usuario.idusuario+'_Two'">
                            <span class="accordion__header--text">Acessos do Usuário ao Menu</span>
                            <span class="accordion__header--indicator"></span>
                        </div>
                        <div :id="'default_collapse_'+usuario.idusuario+'_Two'" class="collapse" :data-parent="'#accordion-usr-' + usuario.idusuario">
                            <div class="accordion__body--text">                                
                                <div class="card" v-if="menu && Array.isArray(menu.children) && menu.children.length > 0">
                                    <div class="card-body">
                                        <div id="accordion-l-one" class="accordion accordion-primary">
                                            <!-- Menu do Usuário -->
                                            <div class="accordion__item" v-for="item in menu.children" :key="item.id">
                                                <div class="accordion__header collapsed rounded-lg" data-toggle="collapse" :data-target="'#default_collapse_'+item.id">
                                                    <span class="accordion__header--text">{{item.name}}</span>
                                                    <!-- Cabeçalho do Menu nivel 1 -->
                                                    <span class="accordion__header--indicator"></span>
                                                </div>
                                                <div :id="'default_collapse_'+item.id" class="collapse accordion__body" data-parent="#accordion-l-one">
                                                    <div class="accordion__body--text">
                                                        <!-- Conteúdo Menu nível 1 -->
                                                        <div class="switch">
                                                            <span class="mx-2 my-3">{{item.name}}</span>
                                                            <input :id="'chl1_'+item.id" class="mx-3 my-3" type="checkbox" 
                                                                v-model="item.checked"
                                                            />
                                                            <select :id="'sll1_'+item.id"  class="mx-2 my-3" v-model="item.nivel">
                                                                <option v-for="(nivel,index) in niveis" :key="index" 
                                                                    :value="nivel"
                                                                    :selected="nivel == item.nivel"
                                                                >{{nivel}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <!-- Sub Menu -->
                                                        <div v-if="item.children && item.children.length > 0" id="accordion-l-two" class="accordion accordion-primary">
                                                            <!-- Menu do Usuário Nível 2 -->
                                                            <div class="accordion__item">
                                                                <div class="accordion__header collapsed rounded-lg" data-toggle="collapse" data-target="#default_collapse_submenu">
                                                                    <span class="accordion__header--text">Sub menu</span>
                                                                    <!-- Cabeçalho do Menu nivel 2 -->
                                                                    <span class="accordion__header--indicator"></span>
                                                                </div>
                                                                <div id="default_collapse_submenu" class="collapse accordion__body" data-parent="#accordion-l-two">
                                                                    <div class="accordion__body--text">
                                                                        <!-- Conteúdo Menu nível 2 -->
                                                                        <div class="row" v-for="submenu in item.children" :key="submenu.id">
                                                                            <span class="mx-2 my-3">{{submenu.name}}</span>
                                                                            <input :id="'chl2_'+submenu.id" class="mx-3 my-3" type="checkbox" 
                                                                                v-model="submenu.checked"
                                                                            />
                                                                            <select :id="'sll2_'+submenu.id" class="mx-2 my-3" v-model="submenu.nivel">
                                                                                <option v-for="(nivel,index) in niveis" :key="index"
                                                                                    :value="nivel"
                                                                                    :selected="nivel == submenu.nivel"
                                                                                >{{nivel}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Hotéis do Usuário -->
                    <div class="accordion__item">
                        <div class="accordion__header collapsed rounded-lg" data-toggle="collapse" :data-target="'#default_collapse_'+usuario.idusuario+'_Three'">
                            <span class="accordion__header--text">Hoteis</span>
                            <span class="accordion__header--indicator"></span>
                        </div>
                        <div :id="'default_collapse_'+usuario.idusuario+'_Three'" class="collapse" :data-parent="'#accordion-usr-' + usuario.idusuario">
                            <div class="accordion__body--text">
                                <div v-if="hoteis && Array.isArray(hoteis) && hoteis.length > 0">
                                    <div class="form-group border pl-3" v-for="hotel in hoteis" :key="hotel.idhotel">
                                        <div class="form-check mb-4">
                                            <input type="checkbox" class="form-check-input" 
                                                :id="'check_hotel_'+hotel.idhotel"
                                                :checked="hotel.checked"
                                                 @click="hotel.checked = !hotel.checked" >
                                            <label class="form-check-label" :for="'check_hotel_'+hotel.idhotel">{{hotel.nome}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>Hotel Padrão:</label>
                                        <select v-model="usuario.idhotel" class="form-control">
                                            <option v-for="hotel_i in hoteisSelecionados" :key="'default_'+hotel_i.idhotel" 
                                                :value="hotel_i.idhotel"
                                                :selected="hotel_i.idhotel == usuario.idhotel"
                                            >{{hotel_i.nome}}
                                            </option>
                                        </select>
                                    </div>
                                </div>                    
                            </div>
                        </div>
                    </div>

                    <!-- Acessos por Setor -->
                    <div class="accordion__item">
                        <div class="accordion__header collapsed rounded-lg" data-toggle="collapse" :data-target="'#default_collapse_'+usuario.idusuario+'_Four'">
                            <span class="accordion__header--text">Acessos por Setor</span>
                            <span class="accordion__header--indicator"></span>
                        </div>
                        <div :id="'default_collapse_'+usuario.idusuario+'_Four'" class="collapse" 
                            :data-parent="'#accordion-usr-' + usuario.idusuario">
                            <div class="accordion__body--text">
                                <div v-if="usuario.setores && Array.isArray(usuario.setores) && usuario.setores.length > 0">
                                    <div class="form-group border pl-1">
                                        <div class="p-1" style="display:flex;align-items:center;">
                                            <div class="form-group col-md-3 text-left mb-0" style="align-self:center;">
                                                <h4> Setor </h4>
                                            </div>
                                            <div class="form-group col-md-3 text-right mb-0">
                                                <h4> Notificar </h4>
                                            </div>
                                            <div class="form-group col-md-3 text-right mb-0">
                                                <h4> Acessar </h4>
                                            </div>
                                            <div class="form-group col-md-3 text-right mb-0">
                                                <h4> Criar </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Todos os Setores que o usuário tem acesso -->
                                    <div class="form-group border pl-1" v-for="setor in usuario.setores" :key="setor.idhotlinesetores">
                                        <div class="p-1" style="display:flex;align-items:center;">
                                            <div class="form-group col-md-3 text-left mb-0" style="align-self:center;">
                                                <h5>{{setor.deschotlinesetor}}</h5>
                                            </div>
                                            <div class="form-group col-md-3 text-right">
                                                <input type="checkbox" class="form-check-input"
                                                    :id="'check_setor_notificar' + setor.idhotlinesetores"
                                                    :checked="setor.notificar" 
                                                    @click="setor.notificar=!setor.notificar"
                                                >
                                            </div>
                                            <div class="form-group col-md-3 text-right">
                                                <input type="checkbox" class="form-check-input"
                                                    :id="'check_setor_acessar' + setor.idhotlinesetores"
                                                    :checked="setor.acessar"
                                                    @click="setor.acessar=!setor.acessar"
                                                >
                                            </div>
                                            <div class="form-group col-md-3 text-right">
                                                <input type="checkbox" class="form-check-input"
                                                    :id="'check_setor_criar' + setor.idhotlinesetores"
                                                    :checked="setor.criar"
                                                    @click="setor.criar=!setor.criar"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>		
                                <div class="form-group col-auto">
                                        <label>Adicionar Setor:</label>
                                        <select v-model="setorInserir" class="form-control">
                                            <option v-for="setor in setoresFitrados" :key="setor.idhotlinesetores"
                                                :value="setor.idhotlinesetores">{{setor.deschotlinesetor}}</option>																				
                                        </select>
                                    </div>                                            
                                    <div class="form-group col-auto">
                                        <a v-on:click="inserirNovoSetor(setorInserir)" class="ponteiro text-white btn btn-info mt-4">Adicionar Setor</a>
                                    </div>	
                            </div>
                        </div>
                    </div>

                    <!-- Escala de Trabalho -->
                    <div class="accordion__item">
                        <div class="accordion__header collapsed rounded-lg" data-toggle="collapse" :data-target="'#default_collapse_'+usuario.idusuario+'_escala'">
                            <span class="accordion__header--text">Escala de Trabalho</span>
                            <span class="accordion__header--indicator"></span>
                        </div>
                        <div :id="'default_collapse_'+usuario.idusuario+'_escala'" class="collapse" 
                            :data-parent="'#accordion-usr-' + usuario.idusuario">
                            <div class="accordion__body--text">
                                <UsuarioEscala :escalas="usuario.escalas"
                                    v-on:update="updateHorarios($event)"
                                ></UsuarioEscala>
                            </div>
                            <date-picker
                                :titulo="'Determine as datas de folga do usuário:'"
                                :datas="usuario.folgas"
                                v-on:atualizar="atualizarDatasFolga($event)"
                            >
                            </date-picker>
                        </div>
                    </div>
                    
                    <!-- Mensagem de alerta -->
                    <div class="form-row">
                        <p :id="'alert-msg-' + usuario.idusuario" style="color:orange;display:none;">{{alert}}</p>
                    </div>                                        
					

					<!-- Salvar e Fechar -->
                    <div class="form-row">
                        <div class="form-group col-sm-auto">
                            <!-- <a v-on:click="$emit('save', usuario)" class="ponteiro text-white btn btn-info mt-4">Salvar</a> -->
                            <button v-on:click="salvar()" :disabled="salvando"
                                class="ponteiro text-white btn btn-info mt-4"
                            >{{salvando?'Salvando...':'Salvar'}}</button>
                        </div>
                        <div class="form-group col-sm-auto">
                            <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import UsuarioEscala from './UsuarioEscala.vue'
import DatePicker from './DatePicker.vue'

export default {
  components: { UsuarioEscala, DatePicker },
    props: {
        usuario: {
            type: Object,
            required: true,
        },
        alert: {
            type: String,
            required: true
        },
        usuarioTipos: {
            type: Array,
            required: true
        },
        hoteis: {
            type: Array,
            required: true
        },
        setores: {
            type: Array,
            required: true
        },
        salvando: {
            type: Boolean,
            required: true
        }
    }, 
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            idiomas: [],
            menu: {},
            niveis: [0, 1, 2, 3, 4],
            setorInserir: {}
        }
    },
    methods: {
        definirAcesso(setor) {
            console.error(setor)
        },
        updateHorarios(evt){
            let existe = false;
            //console.error(JSON.stringify(evt))
            if (this.usuario.escalas && this.usuario.escalas.length > 0) {
                let escala = this.usuario.escalas.find((x)=>x.dia === evt.dia)
                if (escala) {
                    escala.horarios = evt.horarios
                    existe = true;
                } else {
                    existe = false;
                }
            }
            if (!existe) {
                if (!this.usuario.escalas) this.usuario.escalas = []
                this.usuario.escalas.push({
                    idusuario: this.usuario.idusuario,
                    dia: evt.dia,
                    horarios: evt.horarios
                })
            }
        },
        atualizarDatasFolga(datas) {
            this.usuario.folgas = datas;
        },
		recarregarAcessos() {
			axios.post(CONSTANTS.API_URL + '/fdguest/usuarios/acessos', {
				idusuariotipo: this.usuario.idusuariotipo,
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.usuario.acessos = response.data
							this.checkUncheckItems(this.menu)
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});			
		},
        loadCheckedItems(node){
            if (node.checked) {
                this.usuario.acessos.push({
                    idmenu: node.id,
                    nivel: node.nivel
                })
            }
            if (node.children && node.children.length>0) {
                for (var i = 0; i < node.children.length; i++) {
                    var aChild = node.children[i]
                    this.loadCheckedItems(aChild)
                }
            }
        },
        checarCampos() {
            if (!this.usuario.nomeusuario) {
                return {mensagem:'Nome de usuário deve ser preenchido!', ref: 'nomeusuario'};
            } 
            if (!this.usuario.nome) {
                return {mensagem:'Nome deve ser preenchido!', ref: 'nome'};
            } 
            if (this.usuario.idusuario == 0) {
                if (!this.usuario.novasenha) {
                    return {mensagem:'Senha deve ser preenchida!', ref: 'novasenha'};
                } 
                if (!this.usuario.senhaconfirmada) {
                    return {mensagem:'Confirmação da senha deve ser preenchida!', ref: 'senhaconfirmada'};
                }
                if (this.usuario.novasenha !== this.usuario.senhaconfirmada) {
                    return {mensagem:'Senha e confirmação devem ser iguais!', ref: 'novasenha'};
                } 
            }
            if (!this.usuario.emailusuario) {
                return {mensagem:'E-mail deve ser preenchido!', ref: 'emailusuario'};
            } 
            return true;
        },
        salvar() {
            const check = this.checarCampos();
            if (typeof check == 'object') {
                this.$snackW(check.mensagem);
                this.$nextTick(() => {
                    this.$refs[check.ref].focus();
                });
                return;
            } 
            this.usuario.acessos = []
            this.loadCheckedItems(this.menu)
            this.$emit('save', this.usuario)
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        checkHotel(item) {
            var result = false;
            if (this.usuario.hoteis && this.usuario.hoteis.length > 0) {
                for (var i=0; i<this.usuario.hoteis.length; i++){
                    var obj = this.usuario.hoteis[i]
                    if (item.idhotel == obj.idhotel) {
                        result = true;
                        break;
                    }
                }
            }
            return result;
        },
        checkSetor(setor,prop) {
            var result = false;
            if (this.usuario.setores && this.usuario.setores.length > 0) {
                for (var i=0; i<this.usuario.setores.length; i++){
                    var obj = this.usuario.setores[i]
                    if (setor.idhotlinesetores == obj.idhotlinesetores) {
                        console.error(`obj[prop]:${JSON.stringify(obj[prop])}`)
                        result = obj[prop];
                        //result = true;
                        break;
                    }
                }
            }
            this.$forceUpdate();
            return result;
        },
		inserirItem(acesso) {
            var pos = -1;
			if (this.usuario.acessos) {
				for (var i = 0; i < this.usuario.acessos.length; i++) {
					var obj = this.usuario.acessos[i];
					if (obj.idmenu == acesso.id) {
						pos = i + 1;
					}
				}
				if (pos > 0)  {
					this.usuario.acessos = this.usuario.acessos.slice(0, pos-1).concat(this.usuario.acessos.slice(pos))
				} else {
					this.usuario.acessos.push({idmenu: acesso.id.toString(), nivel: acesso.nivel.toString()})
				}
			}
		},
		inserirHotel(hotel) {
            var pos = -1;
			if (this.usuario.hoteis) {
				for (var i = 0; i < this.usuario.hoteis.length; i++) {
					var obj = this.usuario.hoteis[i];
					if (obj.idhotel == hotel.idhotel) {
						pos = i + 1;
					}
				}
				if (pos > 0)  {
					this.usuario.hoteis = this.usuario.hoteis.slice(0, pos-1).concat(this.usuario.hoteis.slice(pos))
				} else {
					this.usuario.hoteis.push({...hotel})
				}
				this.$forceUpdate();
			}
		},
		inserirSetor(setor) {
            var pos = -1;
			if (this.usuario.setores) {
				for (var i = 0; i < this.usuario.setores.length; i++) {
					var obj = this.usuario.setores[i];
					if (obj.idhotlinesetores == setor.idhotlinesetores) {
						pos = i + 1;
					}
				}
				if (pos > 0)  {
					this.usuario.setores = this.usuario.setores.slice(0, pos-1).concat(this.usuario.setores.slice(pos))
				} else {
					this.usuario.setores.push({idhotlinesetores: setor.idhotlinesetores.toString()})
				}
			}
		},
        checkUncheckItems(node) {
            var checked = false
            var acesso = null
			if (this.usuario.acessos) {
				for (var k = 0; k < this.usuario.acessos.length; k++) {
					acesso = this.usuario.acessos[k]
					if (node.id == acesso.idmenu) {
						checked = true;
						break;
					}
				}
			}
            node.checked = checked
            if (node.children && node.children.length>0) {
                for (var i = 0; i < node.children.length; i++) {
                    var aChild = node.children[i]
                    this.checkUncheckItems(aChild)
                }
            }
			this.$forceUpdate();
        },
        inserirNovoSetor(setor) {
            let setorObj = this.setores.find((x)=>x.idhotlinesetores===setor)
            // console.error(JSON.stringify(setorObj))
            // console.error(`setorObj.deschotlinesetor:${setorObj.deschotlinesetor}`)
            if (!this.usuario.setores) {
                this.usuario.setores = []
            }
            this.usuario.setores.push({
                idhotlinesetores: setorObj.idhotlinesetores,
                deschotlinesetor: setorObj.deschotlinesetor,
                idusuario: this.usuario.idusuario,
                notificar: false,
                acessar: false,
                criar: true
            })
            this.$forceUpdate()
        }
    },
    computed: {
        setoresFitrados() {
            let setoresSels = []
			if (this.setores && this.usuario.setores) {
				for (var i = 0; i < this.setores.length; i++) {
					let setor_i = this.setores[i]
                    let encontrou = false
					for (var j = 0; j < this.usuario.setores.length; j++) {
						if (setor_i.idhotlinesetores == this.usuario.setores[j].idhotlinesetores) {
                            encontrou = true
						}
					}
                    if (!encontrou) setoresSels.push({...setor_i})
				}
			}
            if (this.setores && !this.usuario.setores) {
                setoresSels = [...this.setores]
            }
            return setoresSels
        },
        hoteisSelecionados(){
            if (this.usuario.hoteis?.length>0) {
                return this.usuario.hoteis.filter((x)=>x.checked)
            } else {
                return []
            }
        }
    },
    beforeMount() {
        // Listas de valores
        this.loadIdiomas();
        // Menu
        var obj = this.$store.getters.GetRootItem
        if (obj && obj.objeto) {
            this.menu = JSON.parse(obj.objeto)            
        }
    },
    mounted() {
        // Menu
        this.checkUncheckItems(this.menu)
        this.$forceUpdate()
    }
}
</script>

<style scoped>
.azure {
    background-color: azure!important;
}
.form-check-input {
  position: absolute;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #89879f; }

</style>