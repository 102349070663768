<template>
    <div class="row">
        <!-- Filtrar -->
        <div class="col-xl-12">
            <div class="es-card">
                <div class="card-header py-2">
                    <div @click="exibirOcultarFiltro()" class="ponteiro btn rounded ml-2 btn-sm btn-primary px-4 btn-filter tooltip" style="z-index: 1;">
                        <span v-if="!showFilter" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                        <span v-if="showFilter" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                        <span class="tooltiptext">Exibir ou ocultar o filtro.</span>
                    </div>
                    <h4>{{$t('sols.filtro')}}</h4>
                    <div v-on:click="limparFiltro()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div id="filter_body" class="card-body" v-show="showFilter">
                    <div class="basic-form">
                        <div class="form-row">
                            <!-- Categoria -->
                            <div class="form-group col-md-4">
                                <label>{{$t('estoque.categoria')}}</label>
                                <select id="filtro_setor" class="form-control" v-model="filtro.idcategoriaprod"
                                    @change="categoriaChanged()">
                                    <template v-if="categorias && categorias.length > 0">
                                        <option v-for="categoria in categorias" v-bind:key="categoria.idcategoriaprod"
                                            :value="categoria.idcategoriaprod"
                                            :selected="filtro.idcategoriaprod==categoria.idcategoriaprod">{{categoria.categoria}}
                                        </option>
                                    </template>
                                    <option 
                                        :value="-1">{{$t('global.todas')}}
                                    </option>
                                </select>
                            </div>
                            <!-- Produto -->
                            <div class="form-group col-md-4">
                                <label>{{$t('estoque.produto')}}</label>
                                <select class="form-control" v-model="filtro.idproduto">
                                    <template v-if="produtos && produtos.length > 0">
                                        <option v-for="produto in produtos" v-bind:key="produto.idproduto"
                                            :value="produto.idproduto">{{produto.descricao}}
                                        </option>
                                    </template>
                                    <option 
                                        :value="-1">{{$t('global.todos')}}
                                    </option>
                                </select>
                            </div>
                            <!-- Status -->
                            <div class="form-group col-md-4">
                                <label>{{$t('sols.status')}}</label>
                                <select class="form-control" v-model="filtro.idstatus">
                                    <template v-if="statusList && statusList.length > 0">
                                        <option v-for="status in statusList" v-bind:key="status.id"
                                            :value="status.id"
                                            :selected="filtro.idstatus==status.id">{{status.descricao}}
                                        </option>
                                    </template>
                                    <option 
                                        :value="-1">{{$t('global.todos')}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row flex-row-reverse">
                            <div v-on:click="carregarEstoques()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                                <span>{{$t('estoque.pesquisar')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Dados -->
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('estoque.tituloLista')}}</h4>
                    <a @click="criarEstoque()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ {{$t('global.cadastrar')}}</a>
                </div>
                <div class="card-body">
                    <div class="row" id="criarEstDiv" style="display: none;">
                        <EstoqueEditar 
                            :estoque="novoEstoqueProduto"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
                        />
                    </div>

                    <!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>{{$t('global.icone')}}</strong></th>
                                    <th><strong>{{$t('estoque.categoria')}}</strong></th>
                                    <th><strong>{{$t('estoque.produto')}}</strong></th>
                                    <th><strong>{{$t('estoque.unidade')}}</strong></th>
                                    <th><strong>{{$t('estoque.quantidade')}}</strong></th>
                                    <th><strong>{{$t('estoque.status')}}</strong></th>
                                    <th><strong>{{$t('estoque.acao')}}</strong></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in produtosEstoque" :key="item.idestoqueproduto">
                                <tr>
                                    <td>
                                        <div>
                                            <span class="material-icons icon font-lg">{{item.icone}}</span>
                                        </div>
                                    </td>
                                    <td><strong>{{item.categoria}}</strong></td>
                                    <td><strong>{{item.descricao}}</strong></td>
                                    <td><strong>{{item.unidade}}</strong></td>
                                    <td><strong>{{item.quantidade}}</strong></td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a v-if="item.ativo" class="dropdown-item" @click="ativar(item, false)">{{$t('global.inativar')}}</a>
                                                <a v-if="!item.ativo" class="dropdown-item" @click="ativar(item, true)">{{$t('global.ativar')}}</a>
                                                <a class="dropdown-item" @click="editar(item)">{{$t('global.editar')}}</a>
                                                <a class="dropdown-item" @click="historico(item)">{{$t('global.historico')}}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div :id="'Editar_'+item.idestoqueproduto" style="display: none;">
                                            <EstoqueEditar
                                                :estoque="item" 
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div :id="'Historico_'+item.idestoqueproduto" style="display: none;">
                                            <EstoqueHistorico
                                                :estoqueproduto="item" 
                                                v-on:close="closeHistorico(item)"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import EstoqueEditar from './EstoqueEditar.vue'
import EstoqueHistorico from './EstoqueHistorico.vue'
import Pagination from './Pagination.vue'

export default {
    components: {EstoqueEditar,EstoqueHistorico,Pagination},
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            categorias: [],
            produtos: [],
            produtosEstoque: [],
            page: 1,
            pageSize: 6,
            pageCount: 1,
            recordCount: 0,
            novoEstoqueProduto: {
                idestoqueproduto: 0,
                idcategoriaprod: null,
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                idproduto: null,
                quantidade: 0,
                ativo: true,
                icone: 'sell'
            },
            filtro: {
                idcategoriaprod: -1,
                idproduto: -1,
                idstatus: -1               
            },
            showFilter: false,
            statusList: [
                {id: 1, descricao: this.$t('global.ativo'), valor: true},
                {id: 2, descricao: this.$t('global.inativo'), valor: false},
            ],
        }
    },
    methods: {
        updatePage(evt){
            this.page = evt.page
            this.pageSize = evt.pageSize
            this.carregarEstoques();
        },
        categoriaChanged() {
            this.carregarProdutos();
            this.$forceUpdate();
        },
        limparFiltro() {
            this.page = 1
            this.pageSize = 6
            this.pageCount = 1
            this.recordCount = 0
            this.produtosEstoque = []
            this.filtro = {
                idcategoriaprod: null,
                idproduto: null,
                idstatus: null
            }
            window.setTimeout(this.carregarEstoques(), 1000);
        },
        exibirOcultarFiltro(){
            var el = document.getElementById('filter_body')
            if (el) {
                if (el.style.display == 'none') {
                    el.style.display = 'block'
                    this.showFilter = true
                } else {
                    el.style.display = 'none'
                    this.showFilter = false
                }
            }
        },        
        ativar(item, ativo) {
            var itemAtivar = {
                idestoqueproduto: item.idestoqueproduto,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarEstoque() {
            var obj = document.getElementById('criarEstDiv')
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeNew() {
            var obj = document.getElementById('criarEstDiv')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveNew(item) {
            var params = {
                idestoque: item.idestoque,
                idproduto: item.idproduto,
                unidade: item.unidade,
                quantidade: item.quantidade,
                icone: item.icone,
                ativo: item.ativo,
                limiteminimo: item.limiteminimo,
                limitemaximo: item.limitemaximo,
                obs: item.obs,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            console.error(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/estoque/produto/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.carregarEstoques()
                    this.closeNew()
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        editar(item) {
            var obj = document.getElementById('Editar_'+item.idestoqueproduto)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idestoqueproduto)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        historico(item) {
            var obj = document.getElementById('Historico_'+item.idestoqueproduto)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeHistorico(item) {
            var obj = document.getElementById('Historico_'+item.idestoqueproduto)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item) {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idestoqueproduto: item.idestoqueproduto,
                idestoque: item.idestoque,
                idproduto: item.idproduto,
                unidade: item.unidade,
                quantidade: item.quantidade,
                icone: item.icone,
                ativo: item.ativo,
                limiteminimo: item.limiteminimo,
                limitemaximo: item.limitemaximo,
                obs: item.obs,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            // console.error(JSON.stringify(params));
            axios.post(CONSTANTS.API_URL + '/fdguest/estoque/produto/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.carregarEstoques()
                    this.closeEdit(item)
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        carregarEstoques() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            if (this.filtro.idcategoriaprod > 0) {
                params.idcategoriaprod = this.filtro.idcategoriaprod
            }
            if (this.filtro.idproduto > 0) {
                params.idproduto = this.filtro.idproduto
            }
            if (this.filtro.idstatus > 0) {
                params.ativo = (this.filtro.idstatus == 1)
            }
            params.page = this.page
            params.pagesize = this.pageSize
            //console.error(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/estoque/produto/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.produtosEstoque = response.data.dados;
                    this.recordCount = response.data.recordCount;
                    this.pageCount =  response.data.pageCount?response.data.pageCount:1;
                }
            })
        },
        carregarCategorias() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/estoque/categoria/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    //console.error(`params: >>> ${JSON.stringify(params)}`)
                    this.categorias = response.data
                }
            })
        },
        carregarProdutos(){
            this.produtos = []
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                "orderby": 'noaccent(upper(descricao))'
            }
            if (this.filtro.idcategoriaprod > 0) {
                params.idcategoriaprod = this.filtro.idcategoriaprod
            }
            //console.error(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/estoque/produto/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    //console.error(`response 200 response.data.length: ${JSON.stringify(response.data)}`)
                    this.produtos = response.data.dados
                    this.$forceUpdate();
                }
            })
        }
    },
    computed: {
    },
    mounted() {
        this.carregarCategorias();
        this.carregarProdutos();
        this.carregarEstoques();
    }
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1!important;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  opacity: 0.5;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.highlight:hover {
    background-color: azure;
}
.btn-filter {
    background-color: azure;
}
.btn-filter:hover {
    background-color: rgb(177, 204, 204);
}
.es-card {
    background-color: white;
    border-radius: 1.5rem;
    margin-bottom: 0.5rem;
}
.es-selected {
    box-shadow: 0px 5px 5px, 0px -5px 5px, -5px 0px 5px, 5px 0px 5px;
    margin-left: 10px;
}
.es-not-selected {
    opacity: 0.7;
    box-shadow: 0px 0px;
    margin-left: 10px;
}
</style>