<template>
    <div class="row">
        <!-- Filtrar -->
        <div class="col-xl-12" style="margin: 0px; padding: 3px; padding-top:0px; padding-bottom:0px;">
            <div class="es-card">
                <div class="card-header py-2">
                    <div @click="exibirOcultarFiltro()" class="ponteiro btn rounded ml-2 btn-sm btn-primary px-4 btn-filter tooltip" style="z-index: 1;">
                        <span v-if="!showFilter" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                        <span v-if="showFilter" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                        <span class="tooltiptext">Exibir ou ocultar o filtro.</span>
                    </div>
                    <h4>{{$t('sols.filtro')}}</h4>
                    <div v-on:click="limparFiltro()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div id="filter_body" class="card-body" v-show="showFilter">
                    <div class="basic-form">
                        <div class="form-row">
                            <div class="form-group col-md-3">
                                <label>Período de:</label>
                                <input v-model="filtro.periodo.dataInicial" type="date" class="form-control" placeholder="" 
                                    @change="loadSolicitacoes()">
                            </div>
                            <div class="form-group col-md-3">
                                <label>Até:</label>
                                <input v-model="filtro.periodo.dataFinal" type="date" class="form-control" placeholder=""
                                    @change="loadSolicitacoes()">
                            </div>
                        </div>
                        <div class="form-row">
                            <!-- Número da Solicitação -->
                            <div class="form-group col-md-2">
                                <label>#</label>
                                <input v-model="filtro.idhotlinesolicitacoes" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Setor -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.setor')}}</label>
                                <select id="filtro_setor" class="form-control" v-model="filtro.idhotlinesetores"
                                    @change="setorChanged()">
                                    <option :value="null"
                                        :selected="!filtro.idhotlinesetores">{{$t('global.todos')}}
                                    </option>
                                    <template v-if="setores && setores.length > 0">
                                        <option v-for="setor in setores" v-bind:key="setor.idhotlinesetores"
                                            :value="setor.idhotlinesetores"
                                            :selected="filtro.idhotlinesetores==setor.idhotlinesetores">{{setor.deschotlinesetor}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <!-- Tipo -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.tipo')}}</label>
                                <select class="form-control" v-model="filtro.idtiposolicitacao"
                                    @change="loadTipoSolicitacaoItens()"
                                >
                                    <option :value="null"
                                        :selected="!filtro.idtiposolicitacao">{{$t('global.todas')}}
                                    </option>
                                    <template v-if="tipossolicitacao && tipossolicitacao.length > 0">
                                        <option v-for="tiposolicitacao in tipossolicitacao" v-bind:key="tiposolicitacao.idtiposolicitacao"
                                            :value="tiposolicitacao.idtiposolicitacao"
                                            :selected="filtro.idtiposolicitacao==tiposolicitacao.idtiposolicitacao">{{tiposolicitacao.titulo}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <!-- Item -->
                            <div class="form-group col-md-3">
                                <label>Ítem:</label>
                                <select class="form-control" v-model="filtro.idtiposolicitacaoitem">
                                    <option :value="null"
                                        :selected="!filtro.idtiposolicitacaoitem">{{$t('global.todos')}}
                                    </option>
                                    <template v-if="tiposolicitacaoItens && tiposolicitacaoItens.length > 0">
                                        <option v-for="tiposolicitacaoitem in tiposolicitacaoItens" v-bind:key="tiposolicitacaoitem.idtiposolicitacaoitem"
                                            :value="tiposolicitacaoitem.idtiposolicitacaoitem"
                                            :selected="filtro.idtiposolicitacaoitem==tiposolicitacaoitem.idtiposolicitacaoitem">{{tiposolicitacaoitem.titulo}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <!-- Nome -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.nome')}}</label>
                                <input v-model="filtro.nome" 
                                    type="text" class="form-control">
                            </div>
                            <!-- UH -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.uh')}}</label>
                                <input v-model="filtro.coduh" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Número da Reserva -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.reserva')}}</label>
                                <input v-model="filtro.numreserva" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Texto da solicitação -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.texto')}}</label>
                                <input v-model="filtro.textohotlinesolicitacoes" 
                                    type="text" class="form-control">
                            </div>
                        </div>

                        <!--INICIO-->
                        <div class="form-row">
                            <!-- Prioridade -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.prioridade')}}</label>
                                <select class="form-control" v-model="filtro.idprioridade">
                                    <option :value="null"
                                        :selected="!filtro.idprioridade">{{$t('global.todas')}}
                                    </option>
                                    <template v-if="prioridades && prioridades.length > 0">
                                        <option v-for="prioridade in prioridades" v-bind:key="prioridade.idprioridade"
                                            :value="prioridade.idprioridade"
                                            >{{prioridade.descricao}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <!-- Responsável -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.responsavel')}}</label>
                                <select class="form-control" v-model="filtro.idresponsavel">
                                    <option :value="null"
                                        :selected="filtro.idresponsavel==-1">{{$t('global.todos')}}
                                    </option>
                                    <template v-if="responsaveis && responsaveis.length > 0">
                                        <option v-for="responsavel in responsaveis" v-bind:key="responsavel.idusuario"
                                            :value="responsavel.idusuario"
                                            >{{responsavel.nome}}
                                        </option>
                                    </template>
                                    <option :value="-1"
                                        :selected="!filtro.idresponsavel">{{$t('sols.sematribuicao')}}
                                    </option>
                                </select>
                            </div>
                            <!-- Local de atendimento -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.localatendimento')}}</label>
                                <select class="form-control" v-model="filtro.idlocalatendimento">
                                    <option :value="null"
                                        :selected="!filtro.idlocalatendimento">{{$t('global.todos')}}
                                    </option>
                                    <template v-if="locaisAtendimento && locaisAtendimento.length > 0">
                                        <option v-for="localAtendimento in locaisAtendimento" v-bind:key="localAtendimento.idlocalatendimento"
                                            :value="localAtendimento.idlocalatendimento"
                                            >{{localAtendimento.descricao}}
                                        </option>
                                    </template>
                                    <option :value="-1"
                                        :selected="!filtro.idlocalatendimento">{{$t('sols.sematribuicao')}}
                                    </option>
                                </select>
                            </div>
                            <!-- Ultima alteração -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.ultimaAlteracao')}}</label>
                                <input v-model="filtro.dataalteracao" 
                                    type="date" class="form-control">
                            </div>                                                        
                        </div> 
                        <div class="form-row">
                            <!-- Status -->
                            <div class="form-group col-md-3">
                                <label>{{$t('sols.status')}}</label>
                                <select class="form-control" v-model="filtro.idstatus">
                                    <option :value="null"
                                        :selected="!filtro.idstatus">{{$t('global.todos')}}
                                    </option>
                                    <template v-if="statusList && statusList.length > 0">
                                        <option v-for="status in statusList" v-bind:key="status.id"
                                            :value="status.id"
                                            :selected="filtro.idstatus==status.id">{{status.descricao}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-md-4">
                                <div class="custom-control custom-switch pl-0">
                                    <label class="pr-2 ponteiro" :for="`item-switch-filtro`" >
                                        {{$t('sols.manutencaoexterna')}}</label>
                                    <input  type="checkbox" name="manutencaoexterna" class="ponteiro" 
                                        :id="`item-switch-filtro`"  
                                        v-model="filtro.manutencaoexterna"
                                    >
                                </div> 
                                <select v-if="filtro.manutencaoexterna" class="form-control" v-model="filtro.fornecedor">
                                    <option :value="null"
                                        :selected="!filtro.fornecedor">{{$t('global.todos')}}
                                    </option>
                                    <template v-if="fornecedores && fornecedores.length > 0">
                                        <option v-for="fornecedor in fornecedores" v-bind:key="fornecedor.idpessoa"
                                            :value="fornecedor.idpessoa"
                                            :selected="filtro.fornecedor==fornecedor.idfornecedor">{{fornecedor.nome}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div v-if="filtro.manutencaoexterna" class="form-group col-md-2">
                                <div class="custom-control pl-0">
                                    <label :for="`item-garantia-filtro`" >
                                        {{$t('sols.garantia')}}</label>
                                    <input :id="`item-garantia-filtro`" class="form-control" type="number" min="30" v-model="filtro.garantiaemdias">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div v-on:click="loadSolicitacoes()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                                <span>{{$t('sols.filtro')}}</span>
                            </div>
                        </div>                       
                        <!-- FIM-->
                    </div>
                </div>
            </div>
        </div>
        
        <!-- <div class="btn btn-success" @click="$snack('Mensagem')">Snack</div>
        <div class="btn btn-danger" @click="$snackE('Erro')">SnackE</div>
        <div class="btn btn-warning" @click="$snackW('Aviso')">SnackW</div> -->

        <!-- Dados -->
        <div class="col-xl-12" style="margin: 0px; padding: 3px; padding-top:0px;">
            <div class="card">
                <div v-if="(nova || itemCorrente.criar == '' || itemCorrente.criar === true) && itemCorrente.criar != 'false'" 
                    id="div_sols" class="card-header"
                >
                    <h4 class="card-title">{{$t(interna?'sols.chamados':'sols.solicitacoes')}}</h4>
                    <div v-if="selecionadasTotal">
                        <span style="font-size:1.1rem;">{{selecionadasTotal}} {{$t('sols.chamadosselecionados')}}</span>
                        <a @click="abrirAtribuirResponsavel()" class="ponteiro text-white btn btn-primary rounded m-2 btn-sm">Atribuir Responsável</a>
                        <a @click="abrirConcluirChamados()" class="ponteiro btn text-white btn-info rounded m-2 btn-sm ">Concluir Chamados</a>
                    </div>
                    <div>
                        <a @click="criarSolicitacao()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Cadastrar</a>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row" id="criarAvalDiv" style="display: none;">
                        <SolicitacaoEditar 
                            :solicitacao="novaSolicitacao"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
                            :prioridades="prioridades"
                            :responsaveis="responsaveis"
                            :locaisAtendimento="locaisAtendimento"
                            :fornecedores="fornecedores"
                            :interna="interna"
                        />
                    </div>
                    <div class="table-responsive">
                        <!-- Paginação -->
                        <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                            @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                            @update="evt => {updatePage(evt)}"></pagination>

                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="tooltip" 
                                            style="z-index: 1;text-align:center;display:flex;flex-direction:column;align-content:center;">
                                            <div class="alternar-btn" @click="alternarSelecao()">&check;&check;</div>
                                            <span class="tooltiptext" style="top:-30px;left:-10px;">{{$t('sols.alternarselecaotip')}}</span>
                                        </div>
                                        <!-- <span class="d-inline-block" tabindex="0" data-toggle="tooltip" :title="$t('sols.alternarselecao')">
                                            <div class="alternar-btn" @click="alternarSelecao()">&check;&check;</div>
                                        </span> -->
                                        <!-- <span href="#" data-toggle="tooltip" data-placement="bottom"
                                            :title="$t('sols.alternarselecao')" data-original-title="Tooltip on bottom"
                                            class="black-tooltip"
                                        >
                                            <div class="alternar-btn" @click="alternarSelecao()">&check;&check;</div>
                                        </span> -->
                                    </th>
                                    <th style="text-align:center;"><strong>#{{interna?' - Prioridade':''}}</strong><br><strong>{{$t('sols.data')}}</strong></th>
                                    <th style="text-align:center;"><strong>{{$t('sols.setor')}}</strong></th>
                                    <th><strong>{{interna?$t('sols.responsavel'):$t('global.nome')}}</strong></th>
                                    <th v-if="!interna"><strong>{{$t('sols.uh')}}</strong></th>                                    
                                    <th><strong>{{interna?$t('sols.localatendimento'):$t('sols.reserva')}}</strong></th>
                                    <th><strong>{{interna?$t('global.descricao'):$t('global.texto')}}</strong></th>
                                    <th><strong>{{$t('sols.status')}}</strong></th>
                                    <th><strong>{{$t('sols.acoes')}}</strong></th>                                    
                                </tr>
                            </thead>
                            <tbody v-for="item in solicitacoes" :key="item.idhotlinesolicitacoes">
                                <tr class="highlight">
                                    <td>
                                        <input :id="`cb_${item.idhotlinesolicitacoes}`" 
                                            class="ponteiro" type="checkbox"
                                            v-model="item.selecionada"
                                        >
                                    </td>
                                    <td @click="abrirSolicitacao(item)" class="ponteiro" style="text-align:center;">
                                        <strong>{{item.idhotlinesolicitacoes}}
                                            <span class="os-interna es-lista" v-if="item.interna&&!interna"> (OS Interna)</span>
                                            <span v-if="interna">{{prioridadeDesc(item)}}</span>
                                        </strong><br><div class="text-center">{{formatarData(item.datasolicitacao)}}</div>
                                    </td>
                                    <td style="text-align:center;">
                                        <div class="tooltip" 
                                            style="z-index: 1;text-align:center;display:flex;flex-direction:column;align-content:center;">
                                            <div>
                                                <span class="material-icons icone">
                                                    {{item.iconesetor}}
                                                </span>
                                            </div>
                                            <div>
                                                {{item.tipo}}                                                
                                            </div>
                                            <span class="tooltiptext">{{item.deschotlinesetor}}</span>
                                        </div>
                                    </td>
                                    <td>{{interna?item.responsavel:item.nome+' '+item.sobrenome}}</td>
                                    <td  v-if="!interna">{{item.coduh}}</td>
                                    <td>{{interna?item.localatendimento:item.numreserva}}</td>
                                    <td class="es-sol-text" :title="item.textohotlinesolicitacoes">{{item.textohotlinesolicitacoes}}</td>
                                    <td>
                                        <div style="display:flex;flex-direction:row!important;">
                                            <span v-if="item.impedimento" class="material-icons icon icone-filtro ponteiro" style="color:red;font-weight:bold;">outlined_flag</span>
                                            <span :class="{ 'badge-warning' : item.idstatus == 5 , 'badge-success' : item.idstatus == 3, 'badge-secondary' : item.idstatus == 1,'badge-primary' : item.idstatus == 2, 'badge-dark' : item.idstatus == 6, 'badge-danger' : item.idstatus == 4  }" class="badge light " :title="item.impedimento == true ? $t('sols.impedimento') : item.status" >{{item.status}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" :class="{ 'btn-warning' : item.idstatus == 5 , 'btn-success' : item.idstatus == 3, 'btn-secondary' : item.idstatus == 1,'btn-primary' : item.idstatus == 2, 'btn-dark' : item.idstatus == 6, 'btn-danger' : item.idstatus == 4  }" class="btn light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <!-- idstatus == 1 - Pendente -->
                                            <div class="dropdown-menu" v-if="item.idstatus == 1">
                                                <a @click="showTextoInicial(item)" class="dropdown-item ponteiro">{{$t('sols.ematendimento')}}</a>
                                                <a v-if="itemCorrente.nivel >= 2" @click="showCancel(item)" class="dropdown-item ponteiro">{{$t('sols.cancelar')}}</a>
                                                <a @click="showHistorico(item)" class="dropdown-item ponteiro">{{$t('sols.historico')}}</a>
                                                <a @click="idToComment = item.idhotlinesolicitacoes"  data-toggle="modal" data-target="#modalLong" class="dropdown-item ponteiro">{{$t('sols.comentario')}}</a>
                                                <a @click="[idToComment = item.idhotlinesolicitacoes, idPessoaResponsavel = item.idpessoaresponsavel, showModalProdutos = !showModalProdutos, buscarProdutosSolicitacao(item.idhotlinesolicitacoes)]" data-toggle="modal" data-target="#modalLongProd" class="dropdown-item ponteiro">{{$t('sols.produtos')}}</a>
                                                <a @click="[idToComment = item.idhotlinesolicitacoes, impedimento = item.impedimento, textoImpedimento = item.impedimento == true ? item.textoatendimento : '', textoImpedimento = item.textoimpedimento]"  data-toggle="modal" data-target="#modalLongImpedimento" class="dropdown-item ponteiro">{{$t('sols.impedimento')}}</a>
                                                <a v-if="item.interna" @click="showEdit(item)" class="dropdown-item ponteiro">{{$t('global.editar')}}</a>
                                            </div>
                                            <!-- idstatus == 2 - Em atendimento -->
                                            <div class="dropdown-menu" v-else-if="item.idstatus == 2">
                                                <a v-if="item.impedimento == false" @click="showModal(item)"  class="dropdown-item ponteiro">{{$t('sols.atender')}}</a>
                                                <a v-if="itemCorrente.nivel >= 2" @click="showCancel(item)" class="dropdown-item ponteiro">{{$t('sols.cancelar')}}</a>
                                                <a @click="showHistorico(item)" class="dropdown-item ponteiro">{{$t('sols.historico')}}</a>
                                                <a @click="idToComment = item.idhotlinesolicitacoes"  data-toggle="modal" data-target="#modalLong" class="dropdown-item ponteiro">{{$t('sols.comentario')}}</a>
                                                <a :id="`ativadorProdutos_${item.idhotlinesolicitacoes}`" @click="[idToComment = item.idhotlinesolicitacoes, idPessoaResponsavel = item.idpessoaresponsavel, showModalProdutos = !showModalProdutos, buscarProdutosSolicitacao(item.idhotlinesolicitacoes)]" data-toggle="modal" data-target="#modalLongProd" class="dropdown-item ponteiro">{{$t('sols.produtos')}}</a>
                                                <a @click="[idToComment = item.idhotlinesolicitacoes, impedimento = item.impedimento, textoImpedimento = item.impedimento == true ? item.textoatendimento : '', textoImpedimento = item.textoimpedimento]"  data-toggle="modal" data-target="#modalLongImpedimento" class="dropdown-item ponteiro">{{$t('sols.impedimento')}}</a>
                                                <a v-if="item.interna" @click="showEdit(item)" class="dropdown-item ponteiro">{{$t('global.editar')}}</a>
                                            </div>
                                            <!-- idstatus == 5 - Contestado -->
                                            <div class="dropdown-menu" v-else-if="item.idstatus == 5">
                                                <a @click="showModal(item)"  class="dropdown-item ponteiro">{{$t('sols.tratar')}}</a>
                                                <a v-if="itemCorrente.nivel >= 2" @click="showCancel(item)" class="dropdown-item ponteiro">{{$t('sols.cancelar')}}</a>
                                                <a @click="showHistorico(item)" class="dropdown-item ponteiro">{{$t('sols.historico')}}</a>
                                                <a v-if="item.interna" @click="showEdit(item)" class="dropdown-item ponteiro">{{$t('global.editar')}}</a>
                                            </div>
                                            <!-- idstatus == 6 - Cancelada -->
                                            <div class="dropdown-menu" v-else-if="item.idstatus == 6">
                                                <a @click="showHistorico(item)" class="dropdown-item ponteiro">{{$t('sols.historico')}}</a>
                                                <template v-if="item.produtos&&item.produtos.length>0">
                                                    <a @click="showPedido(item)" class="dropdown-item ponteiro">{{$t('sols.pedido')}}</a>
                                                </template>
                                                <a v-if="item.interna" @click="showEdit(item)" class="dropdown-item ponteiro">{{$t('global.editar')}}</a>
                                            </div>
                                            <!-- idstatus == 3 ou 4 - Atendida / Não Atendida -->
                                            <div class="dropdown-menu" v-else-if="item.idstatus == 3 || item.idstatus == 4">
                                                <template v-if="(item.produtos&&item.produtos.length>0)&&(item.idstatus == 3 || item.idstatus == 4)">
                                                    <a @click="showPedido(item)" class="dropdown-item ponteiro">{{$t('sols.pedido')}}</a>
                                                </template>
                                                <a @click="showHistorico(item)" class="dropdown-item ponteiro">{{$t('sols.historico')}}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr :id="'tr_ed_'+item.idhotlinesolicitacoes" style="display:none;">
                                    <td colspan="8">
                                        <div :id="'Editar_'+item.idhotlinesolicitacoes" style="display: none;">
                                            <SolicitacaoEditar 
                                                :solicitacao="item" 
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
                                                :prioridades="prioridades"
                                                :responsaveis="responsaveis"
                                                :locaisAtendimento="locaisAtendimento"
                                                :interna="interna"
                                                :stepInicial="2"
                                                :fornecedores="fornecedores"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr :id="'tr_em_at_pedido_'+item.idhotlinesolicitacoes" style="display:none;">
                                    <td colspan="7">
                                        <div :id="'Em_At_Pedido_'+item.idhotlinesolicitacoes" style="display:none;">
                                            <div class="form-group col-sm-12 px-4">
                                                <solicitacao-produtos :produtos="item.produtos"></solicitacao-produtos>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr :id="'tr_em_at_'+item.idhotlinesolicitacoes" style="display:none;">
                                    <td colspan="7">
                                        <div :id="'Em_At_'+item.idhotlinesolicitacoes" style="display:none;">
                                            <input :id="'textoatendimento_'+item.idhotlinesolicitacoes" v-model="textoatendimento" type="text" class="form-control" 
                                                :placeholder="$t('sols.escrevaconclusaoatendimento')"/> 
                                            <button @click="mudarStatusPendente(item)" type="button" class="btn btn-primary ml-3">{{$t('sols.enviar')}}</button>
                                        </div>
                                    </td>
                                    <td colspan="7" class="borda">
                                        <button @click="hideTextoInicial(item)" type="button" class="btn btn-primary ml-3">{{$t('sols.fechar')}}</button>
                                    </td>
                                </tr>
                                <tr :id="'tr_at_pedido_'+item.idhotlinesolicitacoes" style="display:none;">
                                    <td colspan="7">
                                        <div :id="'At_Pedido_'+item.idhotlinesolicitacoes" style="display:none;">
                                            <div class="form-group col-sm-12 px-4">
                                                <solicitacao-produtos :produtos="item.produtos"></solicitacao-produtos>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr :id="'tr_at_'+item.idhotlinesolicitacoes" style="display:none;">
                                    <td colspan="7">                        
                                        <div :id="'At_'+item.idhotlinesolicitacoes" style="display:none;flex-direction:column;">
                                            <div v-if="item.checklist" class="form-row" style="width:100%;">
                                                <SolicitacaoCheckList 
                                                    :checklist="item.checklist?item.checklist:[]"
                                                    @update="$forceUpdate()"
                                                >
                                                </SolicitacaoCheckList>
                                            </div>
                                            <div class="form-row" style="width:100%;text-align:center;">
                                                <div class="form-group col-md-3" style="display:flex;flex-direction:row;align-items:center;">
                                                    <label :class="{ 'es-selected': atendido=='1', 'es-not-selected': atendido=='0'}" class="ponteiro badge-success badge light" for="atendido-sim">Atendido</label>
                                                    <input v-model="atendido" id="atendido-sim" name="atendido-ou-nao"
                                                        type="radio" class="form-control ponteiro mx-2" value="1"
                                                        style="display:none;" checked="checked">
                                                    <label :class="{ 'es-selected': atendido=='0', 'es-not-selected': atendido=='1'}" class="ponteiro badge-danger badge light" for="atendido-nao">Não atendido</label>
                                                    <input v-model="atendido" id="atendido-nao" name="atendido-ou-nao"
                                                        type="radio" class="form-control ponteiro mx-2" value="0"
                                                        style="display:none;">
                                                </div>
                                                <div class="form-group col-md-8">
                                                    <input :id="'textoconclusao_'+item.idhotlinesolicitacoes" v-model="textoconclusao" type="text" class="form-control" :placeholder="$t('sols.escrevaconclusaoatendimento')"/> 
                                                </div>
                                            </div>
                                            <div class="form-row" style="width:100%;justify-content:center;">
                                                <div class="form-group col-md-2">
                                                    <button @click="registrarAtendimento(item)" 
                                                        :disabled="!allChecked(item)"
                                                        type="button" class="btn btn-primary ml-3"
                                                    >{{$t('sols.enviar')}}</button>
                                                </div>
                                                <div class="form-group col-md-2">
                                                    <button @click="hideAtendimento(item)" type="button" class="btn btn-primary ml-3">{{$t('sols.fechar')}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr :id="'tr_cancel_'+item.idhotlinesolicitacoes" style="display:none;">
                                    <td colspan="7" class="borda">
                                        <div :id="'Cancel_'+item.idhotlinesolicitacoes" style="display:none;">
                                            <input :id="'CancelText_'+item.idhotlinesolicitacoes" v-model="textocancelamento" type="text" class="form-control" :placeholder="$t('sols.escrevamotivocancelamento')"/> 
                                            <button :disabled="!textocancelamento.trim()" @click="cancelarSolicitacao(item)" type="button" class="btn btn-primary ml-3">{{$t('sols.enviar')}}</button>
                                        </div>                                        
                                    </td>
                                    <td colspan="7" class="borda">
                                        <button @click="hideCancel(item)" type="button" class="btn btn-primary ml-3">{{$t('sols.fechar')}}</button>
                                    </td>
                                </tr>
                                <tr :id="'tr_hist_'+item.idhotlinesolicitacoes" style="display:none;">
                                    <td colspan="7" class="borda">
                                        <div :id="'Hist_'+item.idhotlinesolicitacoes" style="display:none;">
                                            <solicitacao-historico v-if="reloadHist"
                                                :interna="item.interna"
                                                :idhotlinesolicitacoes="item.idhotlinesolicitacoes">
                                            </solicitacao-historico>
                                        </div>                                        
                                    </td>  
                                </tr>
                                <tr :id="'tr_hist_close_'+item.idhotlinesolicitacoes" style="display:none;text-align:right;">
                                    <td colspan="7" class="borda">
                                        <button @click="hideHistory(item)" type="button" class="btn btn-primary ml-3">{{$t('sols.fechar')}}</button>
                                    </td>  
                                </tr>
                                <tr :id="'tr_pedido_'+item.idhotlinesolicitacoes" style="display:none;">
                                    <td colspan="7" class="borda">
                                        <div :id="'Pedido_'+item.idhotlinesolicitacoes" style="display:none;">
                                            <div class="form-group col-sm-12 px-4">
                                                <solicitacao-produtos :produtos="item.produtos"></solicitacao-produtos>
                                            </div>
                                        </div>                                        
                                    </td>  
                                </tr>
                                <tr :id="'tr_pedido_close_'+item.idhotlinesolicitacoes" style="display:none;text-align:right;">
                                    <td colspan="7" class="borda">
                                        <button @click="hideOrder(item)" type="button" class="btn btn-primary ml-3">{{$t('sols.fechar')}}</button>
                                    </td>  
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="solicitacoes.length == 0" 
                            style="text-align:center;font-size:1.3rem;color:orange;">
                            {{loadingText}}
                        </div>
                        <!-- Paginação -->
                        <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                            @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                            @update="evt => {updatePage(evt)}"></pagination>

                    </div>
                </div>
            </div>
        </div>

        <!--MODAL COMENTARIO-->
        <div class="modal fade" id="modalLong" tabindex="-1" role="dialog" aria-labelledby="modalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLongTitle">{{$t('sols.comentario')}} #{{this.idToComment}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea v-model="comentario" name="" id=""  rows="10" style="resize: none; border-radius: 10px; margin: 5px; padding: 5px; width: 100%;"></textarea>
                <span>{{msgRetornoComentario}}</span><br>
                <!-- <button type="button" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4" data-dismiss="modal">{{$t('sols.anexo')}}</button> -->
                <input 
                @change="setFile"
                type="file" 
                name="fileUploaded" 
                value="ttt"
                id="inputFile"
                />
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnCloseModalComment">{{$t('sols.fechar')}}</button>
                <button @click="salvarComentario()" type="button" class="btn btn-primary">{{$t('ser.salvar')}}</button>
            </div>
            </div>
        </div>
        </div>
        <!--MODAL COMENTARIO-->

        <!--MODAL PRODUTOS-->
        
        <SolicitacaoProdutosEditar :produtosSolicitacao="produtosSolicitacao" :show="showModalProdutos"
            :idusuariotipo="idusuariotipo" :produtosTodosStr="produtosTodosStr"
            @close="showModalProdutos=false" :idSol="idToComment"
            @item-changed="setProduto($event.p, $event.index)"
            @item-deleted="excluirItem($event.p, $event.index)"
            @save-stock="salvarEstoque($event.item, $event.itemSol)"
            @add-prod="addProdArray()"
            @save="salvarProdutos($event)"
        ></SolicitacaoProdutosEditar>

        <!--MODAL PRODUTOS-->

        <!-- MODAL IMPEDIMENTOS  modalLongImpedimento -->
        <div class="modal fade" id="modalLongImpedimento" tabindex="-1" role="dialog" aria-labelledby="modalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLongTitle">{{$t('sols.impedimento')}} #{{this.idToComment}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <small>Descreva o motivo do impedimento</small>
                <textarea :readonly="impedimento" v-model="textoImpedimento" name="" id=""  rows="5" style="resize: none; border-radius: 10px; margin: 5px; padding: 5px; width: 100%;"></textarea>
                <span>{{msgRetornoImpedimento}}</span><br>
                <!-- <button type="button" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4" data-dismiss="modal">{{$t('sols.anexo')}}</button> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnCloseModalImp">{{$t('sols.fechar')}}</button>
                <button v-if="impedimento == false" @click="[salvarImpedimento(!impedimento)]" type="button" class="btn btn-primary">OK</button>
                <button v-if="impedimento == true" @click="[salvarImpedimento(!impedimento)]" type="button" class="btn btn-primary">Remover Impedimento</button>
            </div>
            </div>
        </div>
        </div>
        <!--MODAL IMPEDIMENTOS-->

        <!--MODAL ATRIBUIR RESPONSÁVEL-->
        
        <SolicitacaoAtribuirResponsavel
            :show="showModalAtribuirResponsavel"
            :totalSelecionado="selecionadasTotal"
            :responsaveis="responsaveis"
            @save="atribuirResponsavel($event)"
            @close="showModalAtribuirResponsavel=false"
        ></SolicitacaoAtribuirResponsavel>

        <!--MODAL ATRIBUIR RESPONSÁVEL-->

        <!--MODAL CONCLUIR CHAMADOS-->
        
        <SolicitacaoConcluirChamados
            :show="showModalConcluirChamados"
            :totalSelecionado="selecionadasTotal"
            :atendidas="selecionadasAtendidas"
            :naoAtendidas="selecionadasNaoAtendidas"
            :canceladas="selecionadasCanceladas"
            @save="concluirChamados($event)"
            @close="showModalConcluirChamados=false"
        ></SolicitacaoConcluirChamados>

        <!--MODAL ATRIBUIR RESPONSÁVEL-->


    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import SolicitacaoEditar from './SolicitacaoEditar.vue'
import moment from 'moment'
import Pagination from './Pagination.vue'
import SolicitacaoHistorico from './SolicitacaoHistorico.vue'
import SolicitacaoProdutos from './SolicitacaoProdutos.vue'
import SolicitacaoCheckList from './SolicitacaoCheckList.vue'
import SolicitacaoProdutosEditar from './SolicitacaoProdutosEditar.vue'
import SolicitacaoAtribuirResponsavel from './SolicitacaoAtribuirResponsavel.vue'
import SolicitacaoConcluirChamados from './SolicitacaoConcluirChamados.vue'

export default {
    props: {
        filtro: {
            type: Object,
            required: true,
        },
        nova: {
            type: Boolean,
            required: false,
            default: true
        },
        interna: {
            type: Boolean,
            required: false,
            default: false
        }
    },    
    components: {SolicitacaoEditar, Pagination, SolicitacaoHistorico
        , SolicitacaoProdutos, SolicitacaoProdutosEditar, SolicitacaoCheckList
        , SolicitacaoAtribuirResponsavel, SolicitacaoConcluirChamados
    },
    data() {
		return {
            reloadHist : true,
            statusPropduto : 'E',
            produtosSolicitacao : [],
            showProd: false,
            loadingText: 'Carregando...',
            comentario : '',
            textoImpedimento : '',
            File : '',
            fileSrc : '',
            nomeDocumento : '',
            msgRetornoComentario : '',
            msgRetornoImpedimento : '',
            impedimento : false,
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            idToComment: 0,
            idPessoaResponsavel: 0,
            solicitacoes: [],
            page: 1,
            pageSize: 6,
            pageCount: 1,
            recordCount: 0,
            setores: [],
            tipossolicitacao: [],
            tiposolicitacaoItens: [],
            novaSolicitacao: {
                idhotlinesolicitacoes: 0,
                nome : '',
                sobrenome : '',
                coduh : '',
                idhotlinesetores : '',
                idhotel :  this.$store.getters.GetLoginInfo.idhotel,
                numreserva : '',
                idusuario : this.$store.getters.GetLoginInfo.idusuario,
                textohotlinesolicitacoes : '',
                idtiposolicitacao : null,
                idtiposolicitacaoitem : null
            },
            statusList: [
                {id: 1, descricao: this.$t('sols.pendente')},
                {id: 2, descricao: this.$t('sols.ematendimento')},
                {id: 3, descricao: this.$t('sols.atendida')},
                {id: 4, descricao: this.$t('sols.naoatendida')},
                {id: 5, descricao: this.$t('sols.contestada')},
                {id: 6, descricao: this.$t('sols.cancelada')},
            ],
            status: {
                "1" : this.$t('sols.pendente'),
                "2" : this.$t('sols.ematendimento'),
                "3" : this.$t('sols.atendida'),
                "4" : this.$t('sols.naoatendida'),
                "5" : this.$t('sols.contestada'),
                "6" : this.$t('sols.cancelada')
            },
            itemCorrente: null,
            textoatendimento: this.interna ? this.$t('sols.chamadoematendimento') : this.$t('sols.seraatendidamediantedisponibilidade'),
            textoconclusao: this.$t('sols.suasolicitacaofoiatendida'),
            textocancelamento: '',
            showFilter: false,
            atendido: '1',
            atendidoText: this.$t('sols.suasolicitacaofoiatendida'),
            responsaveis: [],
            prioridades: this.$t('sols.prioridades'),
            locaisAtendimento: [],
            fornecedores: [],
            produtosTodos: [],
            produtosTodosStr: [],
            validEntregue: true,
            validLiberada: true,
            idusuariotipo: this.$store.getters.GetLoginInfo.idusuariotipo,
            showModalTest: false,
            showModalProdutos: false,
            showModalAtribuirResponsavel: false,
            tudoSelecionado: false,
            showModalConcluirChamados: false
        }
    },
    watch: {
        atendido(val) {
            if (val == '1') {
                this.atendidoText = this.$t('sols.suasolicitacaofoiatendida')
            } else {
                this.atendidoText = this.$t('sols.suasolicitacaonaofoiatendida')
            }
            this.textoconclusao = this.atendidoText
        }
    },
    methods: {
        abrirConcluirChamados() {
            this.showModalConcluirChamados = true;
        },
        abrirAtribuirResponsavel() {
            this.showModalAtribuirResponsavel = true;
        },
        atribuirResponsavel(usuarioResponsavel) {
            var params = {
                usuario_responsavel: usuarioResponsavel,
                chamados: this.selecionadas,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/responsavel/atribuir',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.showModalAtribuirResponsavel = false;
                    this.$snack(`${this.$t('global.salvocomsucesso')}. ${response.data.message}`);
                    this.loadSolicitacoes();
                } else {
                    this.$snackW(response.status);
                }
            }).catch((e)=>{
                this.$snackE(e);
            })
        },
        concluirChamados(textoConclusao) {
            var params = {
                textoconclusao: textoConclusao,
                chamados: this.selecionadas,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/chamados/concluir',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.showModalConcluirChamados = false;
                    this.$snack(`${this.$t('global.salvocomsucesso')}. ${response.data.message}`);
                    this.loadSolicitacoes();
                } else {
                    this.$snackW(response.status);
                }
            }).catch((e)=>{
                this.$snackE(e);
            })
        },
        alternarSelecao() {
            this.solicitacoes.forEach(s=>s.selecionada=!this.tudoSelecionado);
            this.tudoSelecionado=!this.tudoSelecionado;
        },
        showModalTeste() {
            this.showModalTest = !this.showModalTest;
        },
        fecharEstoque(item) {
            this.$fade(document.getElementById(`save_edit_${item.idproduto}`));
        },
        salvarEstoque(item,itemSol) {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                idproduto: item.idproduto,
                unidade: item.unidade,
                quantidade: item.quantidade,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/estoque/produto/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.$snack(this.$t(`global.salvocomsucesso`));
                    this.fecharEstoque(item);
                    itemSol.unidade = item.unidade;
                    itemSol.quantidade = item.quantidade;
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        setProduto(p,index) {
            let obj = this.produtosTodos[index];
            // Se já existe o produto alerta...
            if (this.produtosSolicitacao.map((x) => x.idproduto).indexOf(obj.idproduto) >= 0) {
                this.$snackW(this.$t('sols.esteprodutojaexiste'))
            } else {
                // Senão, inserir...
                p.idhotlinesolicitacoesitens = 0;
                p.idproduto = obj.idproduto;
                p.codprodutopms = obj.codprodutopms;
                p.descproduto = obj.descproduto;
                p.idhotlinesolicitacoes = this.idToComment;
                p.quantidade = obj.quantidade;
                p.idusuario = this.$store.getters.GetLoginInfo.idusuario;
            }
        },
        checkValid(p){
            let qtde_liberada = 0;
            let qtde_entregue = 0;
            let qtde_estoque = 0;
            let result = true;
            try {
                qtde_liberada = parseFloat(p.qtdeliberada);
                qtde_entregue = parseFloat(p.qtdeentregue);
                qtde_estoque = parseFloat(p.quantidade);
            } catch (e) {
                result = false;
                console.error(JSON.stringify(e));
                return result;
            }
            
            if (qtde_liberada > qtde_estoque) {
                this.validLiberada = false;
                result = false;
            } else {
                this.validLiberada = true;
            }
            if (qtde_entregue > qtde_liberada) {
                this.validEntregue = false;
                result = false;
            } else {
                this.validEntregue = true;
            }

            return result;
        },
        getObjById(id, idProp) {
            var elementPos = this.produtosSolicitacao.map(function(x) {return x[idProp]; }).indexOf(id);
            var objectFound = this.produtosSolicitacao[elementPos];
            return objectFound;
        },
        async loadProdutos() {
            // Get object by 'id' from array of objects:
            // var elementPos = array.map(function(x) {return x.id; }).indexOf(idYourAreLookingFor);
            // var objectFound = array[elementPos];
            await axios.post(CONSTANTS.API_URL + '/fdguest/produto/select',{
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                orderby: 'lower(noaccent(trim(trim(both \'"\' from descproduto))))'
            }, {headers: this.headers}).then(resp =>{
                if(resp.status == 200){
                    this.produtosTodos = resp.data
                    this.produtosTodosStr = this.produtosTodos.map((e)=>{
                        return e.descproduto
                    })
                }else{
                    console.error(`resp.status:${resp.status}`)
                }
            }).catch(error =>{
                console.log(error)
            })
        },
        addProdArray(){
            let lastId = 1
            if (this.produtosSolicitacao.length > 0) {
                lastId =this.produtosSolicitacao[this.produtosSolicitacao.length-1].idhotlinesolicitacoesitens;
            }
            let nextId = 0;
            if (lastId <= 0)  nextId = lastId - 1;
            this.produtosSolicitacao.push({
                idhotlinesolicitacoesitens : nextId,
                idhotlinesolicitacoes : this.idToComment,
                idproduto : 0, 
                codprodutopms: '',
                descproduto: '',
                qtdesolicitada : 1, 
                qtdeliberada : 0, 
                qtdeentregue : 0,
                quantidade: 0,
                unidade: 'UN',
                comentario : '', 
                status: 'P'
            });
            this.$forceUpdate();
        },
        loadFornecedores(){
			axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/select', {
				idioma: this.$i18n.locale,
                manutencao: true
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.fornecedores = response.data;
						}
					} else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ erro: err });
			});
        },
        prioridadeDesc(item) {
            let result = ''
            if (item.idprioridade && this.prioridades[item.idprioridade-1]) {
                result = ` - ${this.prioridades[item.idprioridade-1]['descricao']}`
            }
            return result             
        },
        // getClass(item){
        //     return 'badge-'+item.classe
        // },
        async setFile(e) {
        const file = e.target.files[0];
        this.nomeDocumento = e.target.files[0].name
        //alert(this.nomeDocumento)
        //console.log(file);

    /*       if (!file.type.includes("image/")) {
            alert("Please select an image file");
            return;
        } */

        if (typeof FileReader === "function") {
            const reader = new FileReader();

            reader.onload = (event) => {
            this.isImg = true;

            this.fileSrc = event.target.result;
            //let index = event.target.result.indexOf(",") + 1;
            //console.log(index);
            //console.log(this.fileSrc);
    /*           this.fileSrc = event.target.result.substring(
                index,
                event.target.result.length
            ); */
            //console.log(this.fileSrc);
            };
            reader.readAsDataURL(file);
        } else {
            alert("Sorry, FileReader API not supported");
        }
        },
        excluirItem(p,index) {
            if (p.idhotlinesolicitacoesitens > 0) {
                let params = {idhotlinesolicitacoesitens: p.idhotlinesolicitacoesitens};
                //console.error(JSON.stringify(params));
                axios.post(`${CONSTANTS.API_URL}/fdguest/hotlinesolicitacoes/produtos/delete`
                    , params, {headers: this.headers})
                .then((resp)=>{
                    if (resp&&resp.status == 200) {
                        if (index >= 0) {
                            this.produtosSolicitacao.splice(index, 1); // remover 1 item a partir de index
                        }
                    }
                })
            } else {
                if (index >= 0) {
                    this.produtosSolicitacao.splice(index, 1); // remover 1 item a partir de index
                }
            }
        },
        abrirMovimentacaoEstoque(p) {
            //console.error(`abrirMovimentacaoEstoque(${JSON.stringify(p)})`);
            let el = document.getElementById(`save_edit_${p.idproduto}`);
            this.$unfade(el);
        },
        async salvarProdutos(produto){
            let params = {
                idpessoausuario: this.$store.getters.GetLoginInfo.idpessoa,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idpessoaresponsavel: this.idPessoaResponsavel,
                produtos: []
            }
            //console.error(`produto:${JSON.stringify(produto)}`);
            //console.error(`params:${JSON.stringify(params)}`);
            if(produto){
                params.produtos = [{
                    "idhotlinesolicitacoesitens": produto.idhotlinesolicitacoesitens,
                    "idhotlinesolicitacoes": produto.idhotlinesolicitacoes,
                    "idproduto": produto.idproduto,
                    "qtdesolicitada": produto.qtdesolicitada,
                    "qtdeliberada": produto.qtdeliberada,
                    "qtdeentregue": produto.qtdeentregue,
                    "comentario": produto.comentario,
                    "status": produto.status
                }]
            }else{
                params.produtos = this.produtosSolicitacao.filter((item) => {
                    return item.idproduto > 0;
                });
            }

            //console.error(`params:${JSON.stringify(params)}`);
            axios.post(`${CONSTANTS.API_URL}/fdguest/hotlinesolicitacoes/produtos/upinsert`
                , params, {headers: this.headers})
            .then((resp)=>{
                if(resp&&resp.status == 200){
                    this.$snack(this.$t(`global.salvocomsucesso`));
                    let id_sol = produto?produto.idhotlinesolicitacoes:this.produtosSolicitacao[0].idhotlinesolicitacoes;
                    let id_produto = produto?produto.idproduto:null;
                    if (produto) {
                        this.buscarProdutosSolicitacao(id_sol,id_produto);
                    } else {
                        setTimeout(() => {  
                            this.showModalProdutos=false;
                        }, 2000);
                    }
                } else {
                    if (resp.status == 406) {
                        this.$snackE(`Salve o produto sem quantidades liberada e entregue!`);
                    }
                }
            }).catch(error => {
                //console.log(error)
                this.$snackE(JSON.stringify(error.response));
            })
        },   
        async buscarProdutosSolicitacao(idhotlinesolicitacoes,idproduto=null){
            
            let params = {
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                idhotlinesolicitacoes : idhotlinesolicitacoes
            }
            let arrayIndex = -1;
            if (idproduto) {
                params.idproduto = idproduto;
                arrayIndex = this.produtosSolicitacao.findIndex(x => x.idproduto === idproduto);
            } else {
                this.produtosSolicitacao = [];
            }

            await axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/produtos/select', params, {headers: this.headers}).then(resp =>{
                if(resp.status == 200){
                    if (idproduto && arrayIndex>=0) {
                        this.produtosSolicitacao[arrayIndex] = {
                            ...resp.data[0], 
                            validEntregue: true,
                            validLiberada: true
                        }
                    } else {
                        this.produtosSolicitacao = resp.data.map((x)=>{
                            return {
                                ...x,
                                validEntregue: true,
                                validLiberada: true
                            }
                        })
                    }
                    this.$forceUpdate();
                }
            }).catch(error=> {
                console.log(error)
            })
        },
        formatarData(aDate) {
            return moment(aDate).locale(this.$i18n.locale).format('lll');
        },
        updatePage(evt){
            this.page = evt.page
            this.pageSize = evt.pageSize
            this.loadSolicitacoes();
        },
        abrirSolicitacao(item) {
            this.$store.dispatch('set_Sol_Atual', item.idhotlinesolicitacoes);
            this.$router.push('solicitacaodetalhe')
        },
        async cancelarSolicitacao(item) {
            var datahora = moment().format()
            let params = {
                idhotlinesolicitacoes: item.idhotlinesolicitacoes,
                datacancelamento: datahora,
                textocancelamento: this.textocancelamento,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idstatus: 6,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            //console.error(JSON.stringify(params));
            await axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update', params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.hideCancel(item);
                    // Muda o item para "Cancelada"
                    item.idstatus = 6
                    item.status = this.status["6"]
                    item.textocancelamento = this.textocancelamento
                } else {
                    if (response.status == 207) {
                        alert(response.data.message);
                        this.hideCancel(item);
                        this.loadSolicitacoes();
                    }
                    console.log('Response: ' + JSON.stringify(response));
                }
            })
        },
        async salvarComentario(){
            var params = {
                "idhotlinesolicitacoes" : this.idToComment,
                "texto" : this.comentario,
                "documento" : this.fileSrc,
                "nomedocumento" : this.nomeDocumento,
                "de" : this.$store.getters.GetLoginInfo.idusuario
            }
            //console.error(params);
            await axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/comment', params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.msgRetornoComentario = response.data.message
                    this.comentario = ''
                    this.fileSrc = ''
                    this.nomeDocumento = ''
                    document.getElementById('inputFile').value = null
                    setTimeout(() => {  document.getElementById('btnCloseModalComment').click(); }, 2000);
                    
                } else {
                    this.msgRetornoComentario = response.data.message

                    console.log('Response: ' + JSON.stringify(response));
                }
            })
        },
        async salvarImpedimento(bool){
            if(this.textoImpedimento.length < 5 && bool == true){
                alert('Informe o motivo do impedimento corretamente!')
                return
            }else{

            
                var params = {
                    "idhotlinesolicitacoes" : this.idToComment,
                    "impedimento" : bool,
                    "texto" : this.textoImpedimento
                }
                //console.error(JSON.stringify(params));
                await axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/impedimento', params, {headers: this.headers})
                .then(response => {
                    if(response.status == 200) {
                        this.msgRetornoImpedimento = response.data.message
                        this.loadSolicitacoes()
                        this.reloadHist = false
                        setTimeout(() => {  
                            document.getElementById('btnCloseModalImp').click(); 
                            this.msgRetornoImpedimento = ''
                            this.textoImpedimento = ''
                            this.reloadHist = true
                        }, 1500);
                        
                    } else {
                        this.msgRetornoImpedimento = response.data.message

                        console.log('Response: ' + JSON.stringify(response));
                    }
                })
            }
        },        
        allChecked(item) {
            let result = true;
            if (item.checklist 
                && Array.isArray(item.checklist) && item.checklist.length > 0) {
                for (var i = 0; i < item.checklist.length; i++) {
                    if (!item.checklist[i].checked) {
                        result = false;
                        break;
                    }
                }
            }
            return result;
        },
        async registrarAtendimento(item) {
            var params = {
                idhotlinesolicitacoes: item.idhotlinesolicitacoes,
                dataatendimento: moment().format(),
                textoatendimento: this.textoconclusao,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idhotlinesetores: item.idhotlinesetores,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idstatus: this.atendido == '1' ? 3 : 4,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            //console.log(JSON.stringify(params))
            await axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update', params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.hideAtendimento(item)
                    item.idstatus = this.atendido == '1' ? 3 : 4
                    item.status = this.atendido == '1' ? this.status["3"] : this.status["4"]
                } else {
                    if (response.status == 207) {
                        alert(response.data.message);
                        this.hideAtendimento(item)
                        this.loadSolicitacoes();
                    }
                    console.log('Response: ' + JSON.stringify(response));
                }
            })
        },
        carregarLocaisAtendimento() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/localatendimento/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.locaisAtendimento = response.data.dados
                }
            })
        }, 
        
        showModal(item) {
            var obj = document.getElementById('At_'+item.idhotlinesolicitacoes);
            obj.style.display ='flex'
            obj = document.getElementById('tr_at_'+item.idhotlinesolicitacoes);
            obj.style.display ='table-row'
            obj = document.getElementById('At_Pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='flex'
            obj = document.getElementById('tr_at_pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='table-row'
        },
        hideAtendimento(item) {
            var obj = document.getElementById('At_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('tr_at_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('At_Pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('tr_at_pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
        },
        hideHistory(item) {
            var obj = document.getElementById('Hist_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('tr_hist_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('tr_hist_close_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
        },
        showHistorico(item) {
            var obj = document.getElementById('Hist_'+item.idhotlinesolicitacoes);
            obj.style.display ='flex'
            obj = document.getElementById('tr_hist_'+item.idhotlinesolicitacoes);
            obj.style.display ='table-row'
            obj = document.getElementById('tr_hist_close_'+item.idhotlinesolicitacoes);
            obj.style.display ='flex'
        },
        hideOrder(item) {
            var obj = document.getElementById('Pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('tr_pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('tr_pedido_close_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'            
        },
        showPedido(item) {
            var obj = document.getElementById('Pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='flex'
            obj = document.getElementById('tr_pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='table-row'
            obj = document.getElementById('tr_pedido_close_'+item.idhotlinesolicitacoes);
            obj.style.display ='flex'
        },
        showTextoInicial(item) {
            var obj = document.getElementById('Em_At_'+item.idhotlinesolicitacoes);
            obj.style.display ='flex'
            obj = document.getElementById('tr_em_at_'+item.idhotlinesolicitacoes);
            obj.style.display ='table-row'
            obj = document.getElementById('Em_At_Pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='flex'
            obj = document.getElementById('tr_em_at_pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='table-row'
        },
        hideTextoInicial(item) {
            var obj = document.getElementById('Em_At_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('tr_em_at_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('Em_At_Pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('tr_em_at_pedido_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
        },
        showCancel(item) {
            var obj = document.getElementById('Cancel_'+item.idhotlinesolicitacoes);
            obj.style.display ='flex'
            obj = document.getElementById('tr_cancel_'+item.idhotlinesolicitacoes);
            obj.style.display ='table-row'
            obj = document.getElementById('CancelText_'+item.idhotlinesolicitacoes);
            obj.focus()
        },
        hideCancel(item) {
            var obj = document.getElementById('Cancel_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'
            obj = document.getElementById('tr_cancel_'+item.idhotlinesolicitacoes);
            obj.style.display ='none'            
        },
        mudarStatusPendente(item) {
            var datahora = moment().format()
            var params = {
                idhotlinesolicitacoes: item.idhotlinesolicitacoes,
                datavisualizacao: datahora,
                textoatendimento: this.textoatendimento,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idstatus: 2,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            // console.log(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update', params
                , {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    // Muda o item para "Em atendimento"
                    item.idstatus = 2
                    item.status = this.status["2"]
                } else {
                    if (response.status == 207) {
                        alert(response.data.message);
                        this.hideTextoInicial(item)
                        this.loadSolicitacoes();
                    }
                    console.log('Response: ' + JSON.stringify(response));
                }
            })
            this.hideTextoInicial(item)
        },
        exibirOcultarFiltro(){
            var el = document.getElementById('filter_body')
            if (el) {
                if (el.style.display == 'none') {
                    el.style.display = 'block'
                    this.showFilter = true
                } else {
                    el.style.display = 'none'
                    this.showFilter = false
                }
            }
        },
        limparFiltro() {
            this.page = 1
            this.pageSize = 6
            this.pageCount = 1
            this.recordCount = 0
            this.solicitacoes = []
            this.$emit('clear')
            window.setTimeout(this.loadSolicitacoes(), 1000);
        },
        loadSetores() {
			axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesetores/select', {
				idioma: this.$i18n.locale,
                usuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.setores = response.data;
						}
					} else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ erro: err });
			});
        },
        setorChanged() {
            this.loadTiposSolicitacao()
            //this.loadSolicitacoes()
        },
        loadTiposSolicitacao() {
            var params = {
                idioma: this.$i18n.locale,
                interna: this.interna
            }
            if (this.filtro.idhotlinesetores > 0) {
                params.idhotlinesetores = this.filtro.idhotlinesetores
            }
            console.log(JSON.stringify(params));
			axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select', params, { headers: this.headers })
            .then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.tipossolicitacao = response.data;
                            this.loadTipoSolicitacaoItens();
						}
					} else {
                        console.log('Erro: '+ JSON.stringify(response));
                    }                    
			}).catch((err) => {
				console.log({ erro: err });
			});
        },
        async loadTipoSolicitacaoItens() {
            this.tiposolicitacaoItens = []
            //console.error(`loadTipoSolicitacaoItens()... this.solicitacaoEditada.idtiposolicitacao: ${this.solicitacaoEditada.idtiposolicitacao}`)
            let params = {
                idioma: this.$i18n.locale,
                interna: this.interna
            }
            if (this.filtro.idtiposolicitacao) {
                params.idtiposolicitacao = this.filtro.idtiposolicitacao;
            }
            //console.error(JSON.stringify(params))
			await axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacaoitem/select', params, { headers: this.headers })
            .then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.tiposolicitacaoItens = response.data;
                            this.$forceUpdate();
						}
					} else {
                        console.log('Erro: '+ JSON.stringify(response));
                    }                    
			}).catch((err) => {
				console.log({ erro: err });
			});
        },
        ativar(item, ativo) {
            var itemAtivar = {
                idhotlinesolicitacoes: item.idhotlinesolicitacoes,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarSolicitacao() {
            var obj = document.getElementById('criarAvalDiv')
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeNew() {
            var obj = document.getElementById('criarAvalDiv')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveNew(item) {
            if (!item.idhotlinesetores || !item.textohotlinesolicitacoes || !item.idtiposolicitacao) {
                this.$snackW('Texto da solicitação, setor e tipo são obrigatórios.');
                return
            }
            var sol = {
                nome : item.nome,
                sobrenome : item.sobrenome,
                coduh : item.coduh,
                idhotlinesetores : item.idhotlinesetores,
                idhotel :  item.idhotel,
                numreserva : item.idreservasfront,
                idusuario : this.$store.getters.GetLoginInfo.idusuario,
                textohotlinesolicitacoes : item.textohotlinesolicitacoes,
                idtiposolicitacao : item.idtiposolicitacao,
                idtiposolicitacaoitem : item.idtiposolicitacaoitem,
                interna: true,
                idresponsavel: item.idresponsavel,
                idprioridade: item.idprioridade,
                idlocalatendimento: item.idlocalatendimento,
                manutencaoexterna: item.manutencaoexterna,
                fornecedor : item.fornecedor,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            };
            // console.error(JSON.stringify(sol));
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/insert',
                    sol, { headers: this.headers })
                .then(response => {
                if(response.status == 200) {
                    this.$snack(`Solicitação criada!`)
                    this.loadSolicitacoes()
                    this.closeNew()                    
                } else {
                    //this.$snackW(`Erro ao criar solicitação: ${response.message}`)
                }
            })
            .catch((e)=>{
                this.$snackE(`Erro ao criar solicitação: ${e.message}`)
            })
        },
        showEdit(item) {
            var obj = document.getElementById('Editar_'+item.idhotlinesolicitacoes)
            if(obj) {
                obj.style.display = 'block'
            }
            obj = document.getElementById('tr_ed_'+item.idhotlinesolicitacoes)
            if(obj) {
                obj.style.display = 'table-row'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idhotlinesolicitacoes)
            if(obj) {
                obj.style.display = 'none'
            }
            obj = document.getElementById('tr_ed_'+item.idhotlinesolicitacoes)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item) {
            if (item.manutencaoexterna) {
                if (!item.garantiaemdias || item.garantiaemdias < 30) {
                    this.$snackW("Garantia do serviço deve ser ao menos 30 dias!")
                    return
                }
                if (!item.fornecedor) {
                    this.$snackW("Informe um fornecedor!")
                    return
                }
            }
            
            let params = {
                idhotlinesolicitacoes: item.idhotlinesolicitacoes,
                nome : item.nome,
                sobrenome : item.sobrenome,
                coduh : item.coduh,
                idhotlinesetores : item.idhotlinesetores,
                idhotel :  item.idhotel,
                numreserva : item.idreservasfront,
                textohotlinesolicitacoes : item.textohotlinesolicitacoes,
                idtiposolicitacao : item.idtiposolicitacao,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idresponsavel: item.idresponsavel,
                idprioridade: item.idprioridade,
                idlocalatendimento: item.idlocalatendimento,
                manutencaoexterna: item.manutencaoexterna,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            if (item.manutencaoexterna) {
                params.fornecedor = item.fornecedor;
                params.garantiaemdias = item.garantiaemdias;
            }
            //console.error(JSON.stringify(params));
            axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update', params, { headers: this.headers })
            .then(response => {
                if(response.status == 200) {
                    this.loadSolicitacoes()
                    this.closeEdit(item)
                    this.$snack(`Solicitação atualizada!`)
                    return true;
                } else {
                    if (response.status == 207) {
                        this.$snackW(response.data.message);
                        this.closeEdit(item)
                        this.loadSolicitacoes();
                    }
                    console.log('Response: ' + JSON.stringify(response));
                    return false;
                }
            })
            .catch((e)=>{
                //console.error(JSON.stringify(e))
                this.$snackE(e.message?e.message:`Erro ao atualizar solicitação!`);
            })
        },
        loadResponsaveis() {
            var searchParams = {
                idioma: this.$i18n.locale,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
				page: 1,
                pagesize: 1000,
                orderby: 'noaccent(upper(nome))'
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/usuarios/tecnicos', searchParams
                , { headers: this.headers })
            .then(response => {
                try {
                    if (response.status == 200) {
                        if (response.data) {
                            this.responsaveis = response.data.dados;
                        }
                    } else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
                }
                catch(e) {
                    console.log(e);
                }
            }).catch((err) => {
                console.log({ erro: err });
            });
        },
        loadSolicitacoes() {
            this.loadingText = 'Carregando...'
            var  params = {
                idioma: this.$i18n.locale,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                usuario: this.$store.getters.GetLoginInfo.idusuario,
                periodo: {de: '', ate: ''}
            }            
            if (this.filtro.periodo.dataInicial) {
                params.periodo.de = this.filtro.periodo.dataInicial
            }
            if (this.filtro.periodo.dataFinal) {
                params.periodo.ate = this.filtro.periodo.dataFinal
            }
            if (!this.filtro.periodo.dataInicial&&!this.filtro.periodo.dataFinal) {
                delete params.periodo
            }
            if (this.filtro.idhotlinesolicitacoes) {
                params.idhotlinesolicitacoes = this.filtro.idhotlinesolicitacoes
            }
            if (this.filtro.idhotlinesetores) {
                params.idhotlinesetores = this.filtro.idhotlinesetores
            }
            if (this.filtro.idtiposolicitacao) {
                params.idtiposolicitacao = this.filtro.idtiposolicitacao
            }
            if (this.filtro.idtiposolicitacaoitem) {
                params.idtiposolicitacaoitem = this.filtro.idtiposolicitacaoitem
            }
            if (this.filtro.idstatus != null) {
                params.idstatus = this.filtro.idstatus
            }
            if (this.filtro.nome.trim()) {
                params.nome = this.filtro.nome.trim()
            }
            if (this.filtro.coduh.trim()) {
                params.coduh = this.filtro.coduh.trim()
            }
            if (this.filtro.numreserva.trim()) {
                params.numreserva = this.filtro.numreserva.trim()
            }
            if (this.filtro.textohotlinesolicitacoes.trim()) {
                params.textohotlinesolicitacoes = this.filtro.textohotlinesolicitacoes.trim()
            }
            if (Object.prototype.hasOwnProperty.call(this.filtro,'interna')) {
                params.interna = this.filtro.interna
            }

            //MSOA INI
            if(this.filtro.idlocalatendimento){
                params.idlocalatendimento = this.filtro.idlocalatendimento
            }

            if(this.filtro.idresponsavel){
                params.idresponsavel = this.filtro.idresponsavel
            }
            
            if (this.idusuariotipo == 4 && params.idhotlinesetores == 4) {
                params.idresponsavel = this.$store.getters.GetLoginInfo.idusuario
            }

            if(this.filtro.idprioridade && this.filtro.idprioridade > 0){
                params.idprioridade = this.filtro.idprioridade
            }            

            if(this.filtro.dataalteracao){
                params.dataalteracao = this.filtro.dataalteracao
            }            

            //MSOA FIM

            if(this.filtro.manutencaoexterna){
                params.manutencaoexterna = this.filtro.manutencaoexterna
            }

            if(this.filtro.fornecedor){
                params.fornecedor = this.filtro.fornecedor
            }

            if(this.filtro.garantiaemdias){
                params.garantiaemdias = this.filtro.garantiaemdias
            }

            params.page = this.page
            params.pagesize = this.pageSize
            console.error(JSON.stringify(params));
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.solicitacoes = response.data.dados
                    //console.log(JSON.stringify(this.solicitacoes))
                    this.recordCount = response.data.recordCount
                    this.pageCount =  response.data.pageCount?response.data.pageCount:1
                    if (this.solicitacoes.length == 0) {
                        this.loadingText = 'Nenhum registro encontrado. Procure limpar o filtro.'
                    }
                    // window.scrollTo(0, 800);
                    //const yOffset = -30; 
                    // const element = document.getElementById('div_sols');
                    //const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    // const y = element.getBoundingClientRect().top;
                    // window.scrollTo({top: y, behavior: 'smooth'});
                    //element.scrollIntoView();
                }
            })
        },
        programadoPara(item) {
            var result = ''
            var dias = parseInt(item.intervalo)
            if (dias > 0) {
                if (dias == 1) {
                    // singular
                    result += `${dias.toString()} ${this.$t('sols.diaaposa')} `
                } else {
                    // plural
                    result += `${dias.toString()} ${this.$t('sols.diasaposa')} `
                }
            } else {
                if (dias < 0) {
                    if (Math.abs(dias) == 1) {
                        // singular
                        result += `${Math.abs(dias).toString()} ${this.$t('sols.diaantesda')} `
                    } else {
                        // plural
                        result += `${Math.abs(dias).toString()} ${this.$t('sols.diasantesda')} `
                    }
                }
            }
            result += this.$t('sols.'+item.datareferida)
            return result
        },
        disable(elid) {
            var el = document.getElementById(elid)
            if (el) {
                el.disabled = true
            }
        }
    },
    computed: {
        selecionadasTotal(){
            let filtradas = this.solicitacoes.filter(x=>x.selecionada)
            return filtradas.length
        },
        selecionadas(){
            let filtradas = this.solicitacoes.filter(x=>x.selecionada)
            let somenteIds = filtradas.map(x=>x.idhotlinesolicitacoes)
            return somenteIds.join(',')
        },
        selecionadasCanceladas(){
            let filtradas = this.solicitacoes.filter(x=>x.selecionada&&x.idstatus==6)
            let somenteIds = filtradas.map(x=>x.idhotlinesolicitacoes)
            return somenteIds
        },
        selecionadasAtendidas(){
            let filtradas = this.solicitacoes.filter(x=>x.selecionada&&x.idstatus==3)
            let somenteIds = filtradas.map(x=>x.idhotlinesolicitacoes)
            return somenteIds
        },
        selecionadasNaoAtendidas(){
            let filtradas = this.solicitacoes.filter(x=>x.selecionada&&x.idstatus==4)
            let somenteIds = filtradas.map(x=>x.idhotlinesolicitacoes)
            return somenteIds
        }
    },
    beforeMount() {
        //this.loadSetores();
        this.itemCorrente = this.$store.getters.GetCurrentItem;
        this.setores = this.$store.getters.GetLoginInfo.setores;
    },
    mounted() {
        this.loadTiposSolicitacao()
        this.loadResponsaveis()
        this.carregarLocaisAtendimento()
        this.loadFornecedores();
        this.loadSolicitacoes();
        this.loadProdutos();
    }
}
</script>

<style>
.icone {
    /* display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px; */
    color: rgb(8, 205, 245);
    /*font: italic small-caps bold 14px/30px Georgia, serif;*/
}
.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1!important;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  opacity: 0.5;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.highlight:hover {
    background-color: azure;
}
.btn-filter {
    background-color: azure;
}
.btn-filter:hover {
    background-color: rgb(177, 204, 204);
}
.es-card {
    background-color: white;
    border-radius: 1.5rem;
    margin-bottom: 0.5rem;
}
.es-selected {
    box-shadow: 0px 5px 5px, 0px -5px 5px, -5px 0px 5px, 5px 0px 5px;
    margin-left: 10px;
}
.es-not-selected {
    opacity: 0.7;
    box-shadow: 0px 0px;
    margin-left: 10px;
}
.es-highlight {
    font-size: 1.4em;
}
.es-highlight:hover {
    background-color: rgb(229, 247, 253);
    cursor: pointer;
    color: rgb(0, 0, 0);
}
.es-grouped {
    background-color: rgb(229, 247, 253);
}
.es-valid {
    color: green;
}
.es-invalid {
    color: red;
}
.alternar-btn {
    cursor: pointer;
    color: rgb(16,179,237);
    padding: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 0.5rem;
}
.alternar-btn:hover {
    color: white;
    background-color: rgb(30, 144, 255);
}
/* .tooltip-arrow,
.black-tooltip + .tooltip > .tooltip-inner {background-color:black;} */
</style>